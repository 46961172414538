import React from 'react'

function Icon() {
  return (
    // <svg xmlns="http://www.w3.org/2000/svg" width="21.964" height="20" viewBox="0 0 21.964 21.964" fill="#1e91cf">
    <svg xmlns="http://www.w3.org/2000/svg" width="21.964" height="20" viewBox="0 0 21.964 21.964" fill="#ffffff">
      <path
        d="M10.983 0a10.982 10.982 0 1010.981 10.982A10.982 10.982 0 0010.983 0zm4.778 11.825h-3.935v3.933a.845.845 0 01-1.689 0v-3.933H6.205a.845.845 0 110-1.689h3.933V6.204a.845.845 0 011.689 0v3.933h3.933a.845.845 0 110 1.689z"
        className="a"></path>
    </svg>
  )
}

export default Icon

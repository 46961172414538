import React, { useState } from 'react'
import { CustomTable, Timestamp } from '@components'
import { formatter } from '@utils'
import Moment from 'react-moment'

export default function OrderList(props) {
  const [currentPage, setCurrentPage] = useState(0)
  const maxRowPerPage = 50

  const columns = [
    {
      key: '',
      title: 'No.',
      render: (row, ridx) => <div>{currentPage * maxRowPerPage + ridx + 1}.</div>
    },
    {
      key: 'orderDate',
      title: 'Order Date',
      render: (row) => (
        <div>
          <Moment format="DD/MM/YYYY">{row.orderDate}</Moment>
          <br />
          <Timestamp>{row.orderDate}</Timestamp>
        </div>
      )
    },
    {
      key: 'orderNumber',
      title: 'Order No.',
      render: (row) => (
        <div>
          <span style={{ cursor: 'pointer' }} onClick={() => window.open('/order/' + row.order.orderId, '_target')}>
            {row.orderNumber}
          </span>
        </div>
      )
    },
    {
      key: 'trackerNumber',
      title: 'Tracking Number',
      render: (row) => <div>{row.trackerNumber}</div>
    },
    {
      key: 'sender',
      title: 'Sender/Recipent',
      render: (row) => <div>{'Sender'}</div>
    },
    {
      key: 'grandTotal',
      title: 'Grand Total',
      render: (row) => <div>{formatter.toPrice(row.grandTotal)}</div>
    },
    {
      key: 'status',
      title: 'Status',
      render: (row) => <div>{row.status}</div>
    },
    // {
    //   title: '',
    //   render: (row) => (
    //     <div>
    //       {/* {userMatrix ? (
    //         userMatrix.canView == true ? (
    //           <Tooltip arrow placement="top" title="View">
    //             <Visibility className="mui-icon" onClick={() => handleEditItem(row)} />
    //           </Tooltip>
    //         ) : null
    //       ) : null} */}
    //       <Tooltip arrow placement="top" title="View">
    //         <Visibility className="mui-icon" onClick={() => handleView(row)} />
    //       </Tooltip>
    //     </div>
    //   )
    // }
  ]

  return (
    <div className="customer-details-order-list-container">
      <CustomTable
        total={props.total}
        maxRow={props.maxRowPerPage}
        rows={props.order}
        cols={columns}
        state={props}
        nextToken={props.nextToken}
        fetchData={props.fetchOrderData}
        // filter={filterOption}
        // onSearch={elasticSearch}
      />
    </div>
  )
}

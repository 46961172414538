import React from 'react'
import {
  Button as Btn,
  Drawer,
  IconButton,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Radio,
  Input,
  Checkbox,
  TextField,
  FormControl,
  MenuItem,
  Select
} from '@material-ui/core'
import { Close, ExpandMore } from '@material-ui/icons'
import './MultiFilterDrawer.scss'

export const RadioGroup = ({ className, keyValueList, radioState, onRadioChange }) => {
  return (
    <div className={className}>
      {(keyValueList || []).map((o) => (
        <label key={o.label}>
          <Radio onChange={() => onRadioChange(o.value)} checked={o.value === radioState} />
          <span className="radio-label">{o.label}</span>
        </label>
      ))}
    </div>
  )
}

export const CheckboxGroup = ({ className, keyValuePair, onCheckboxChange }) => {
  return (
    <div className={className}>
      {Object.entries(keyValuePair).map(([key, value], i) => (
        <div className="checkbox-container" key={i}>
          <Checkbox name={key} checked={!!value} onChange={(e) => onCheckboxChange(e)} />
          <span className="checkbox-label">{key}</span>
        </div>
      ))}
    </div>
  )
}

export const FilterAccordion = ({ label, clearState, onClearClick, children }) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <label className="sub-component-header">{label}</label>
      </AccordionSummary>
      <AccordionDetails>
        <div className="flex-row">
          {children}
          <label className={['btn-clear', clearState ? 'filled' : ''].join(' ').trim()} onClick={onClearClick}>
            Clear
          </label>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export const TextboxFilterAccordion = ({ label, placeholder, onTextFieldChange, textFieldValue, onClearClick }) => {
  return (
    <FilterAccordion label={label} clearState={!!textFieldValue} onClearClick={onClearClick}>
      <TextField
        className="txtbox-filter"
        placeholder={placeholder}
        onChange={(e) => onTextFieldChange(e.target.value)}
        value={textFieldValue}
        variant="outlined"
      />
    </FilterAccordion>
  )
}
export const ComboFilterAccordion = ({
  label,
  placeholder,
  onTextFieldChange,
  textFieldValue,
  onClearClick,
  dropdown,
  dropdowntype,
  setDropdownValue,
  dropdownValue
}) => {
  let dropdownlist = []

  let newdropdownlist = []

  console.log('this', dropdown)
  dropdown.map((item) => {
    if (!dropdownlist.includes(item.division) && item.division !== '' && dropdowntype == 'Division') {
      dropdownlist.push(item.division)
      newdropdownlist.push(item)
    } else if (!dropdownlist.includes(item.categories) && item.categories !== '' && dropdowntype == 'Category') {
      dropdownlist.push(item.categories)
      newdropdownlist.push(item)
    } else if (
      !dropdownlist.includes(item.productGroup) &&
      item.productGroup !== '' &&
      dropdowntype == 'ProductGroup'
    ) {
      dropdownlist.push(item.productGroup)
      newdropdownlist.push(item)
    }
  })
  return (
    <FilterAccordion label={label} clearState={!!textFieldValue} onClearClick={onClearClick}>
      <TextField
        className="txtbox-filter"
        placeholder={placeholder}
        onChange={(e) => onTextFieldChange(e.target.value)}
        value={textFieldValue}
        variant="outlined"
        disabled={dropdownValue !== ''}
      />
      <FormControl>
        <Select
          variant="outlined"
          className="dropdown-filter"
          onChange={(e) => setDropdownValue(e.target.value)}
          value={dropdownValue}
          disabled={textFieldValue !== ''}>
          {dropdowntype == 'Division'
            ? newdropdownlist
                .sort((a, b) => a.divisionCode.localeCompare(b.divisionCode))
                .map((item) => {
                  return <MenuItem value={item.division}>{`${item.divisionCode} ${item.division}`}</MenuItem>
                })
            : dropdowntype == 'Category'
            ? newdropdownlist
                .sort((a, b) => a.itemCategoryCode.localeCompare(b.itemCategoryCode))
                .map((item) => {
                  return <MenuItem value={item.categories}>{`${item.itemCategoryCode} ${item.categories}`}</MenuItem>
                })
            : dropdowntype == 'ProductGroup'
            ? newdropdownlist
                .sort((a, b) => a.productGroupCode.localeCompare(b.productGroupCode))
                .map((item) => {
                  return (
                    <MenuItem value={item.productGroup}>{`${item.productGroupCode} ${item.productGroup}`}</MenuItem>
                  )
                })
            : null}
          {/* {newdropdownlist.map((item) => {
            if(dropdowntype == 'Division'){
            return <MenuItem value={item.division}>{`${item.divisionCode} ${item.division}`}</MenuItem>
            }
            else if(dropdowntype == 'Category'){
              return <MenuItem value={item.categories}>{`${item.itemCategoryCode} ${item.categories}`}</MenuItem>
            }
            else if(dropdowntype == 'ProductGroup'){
              return <MenuItem value={item.productGroup}>{`${item.productGroupCode} ${item.productGroup}`}</MenuItem>
            }
          })} */}
        </Select>
      </FormControl>
    </FilterAccordion>
  )
}

export default function MultiFilterDrawer(props) {
  const { setPage, clearAllFilter, filterData, drawerData, setIsOpen, isOpen } = props

  const clearFilter = () => {
    clearAllFilter()
    setPage(0)
  }
  const doneFilter = () => {
    if (filterData) {
      filterData()
    }
    setPage(0)
  }

  const textFieldTypeDrawer = (label, placeholder, setValue, value, onClearClick) => {
    return (
      <TextboxFilterAccordion
        label={label}
        placeholder={placeholder}
        onTextFieldChange={setValue}
        textFieldValue={value}
        onClearClick={onClearClick}
      />
    )
  }
  const comboFieldTypeDrawer = (
    label,
    placeholder,
    setValue,
    value,
    onClearClick,
    dropdown,
    dropdowntype,
    setDropdownValue,
    dropdownValue
  ) => {
    return (
      <ComboFilterAccordion
        label={label}
        placeholder={placeholder}
        onTextFieldChange={setValue}
        textFieldValue={value}
        onClearClick={onClearClick}
        dropdown={dropdown}
        dropdowntype={dropdowntype}
        setDropdownValue={setDropdownValue}
        dropdownValue={dropdownValue}
      />
    )
  }

  const radioTypeDrawer = (label, setValue, value, list, onClearClick) => {
    return (
      <FilterAccordion label={label} clearState={value != null} onClearClick={onClearClick}>
        <RadioGroup className="radio-group-container" keyValueList={list} radioState={value} onRadioChange={setValue} />
      </FilterAccordion>
    )
  }

  const checkBoxTypeDrawer = (label, value, setValue, onClearClick) => {
    return (
      <FilterAccordion
        label={label}
        clearState={Object.values(value).some((o) => o === true)}
        onClearClick={onClearClick}>
        <CheckboxGroup className="checkbox-group-container" keyValuePair={value} onCheckboxChange={setValue} />
      </FilterAccordion>
    )
  }

  const dateTypeDrawer = (label, fromDate, setFromDate, toDate, setToDate, onClearClick, isActive) => {
    return (
      <FilterAccordion label={label} clearState={!!isActive} onClearClick={onClearClick}>
        <div className="flex-row datepicker-container">
          <label>
            <span className="datepicker-label-title">From :</span>
            <Input type="date" onChange={(e) => setFromDate(e.target.value)} value={fromDate} />
          </label>
          <label>
            <span className="datepicker-label-title">To :</span>
            <Input type="date" onChange={(e) => setToDate(e.target.value)} value={toDate} />
          </label>
        </div>
      </FilterAccordion>
    )
  }
  const timeTypeDrawer = (label, fromTime, setFromTime, toTime, setToTime, onClearClick, isActive) => {
    return (
      <FilterAccordion label={label} clearState={!!isActive} onClearClick={onClearClick}>
        <div className="flex-row datepicker-container">
          <label>
            <span className="datepicker-label-title">From :</span>
            <Input type="time" onChange={(e) => setFromTime(e.target.value)} value={fromTime} />
          </label>
          <label>
            <span className="datepicker-label-title">To :</span>
            <Input type="time" onChange={(e) => setToTime(e.target.value)} value={toTime} />
          </label>
        </div>
      </FilterAccordion>
    )
  }

  const handleCloseDrawer = (value) => {
    setIsOpen(value)
  }
  return (
    <Drawer className="custom-drawer" anchor="right" open={isOpen} onClose={() => handleCloseDrawer(false)}>
      <div className="drawer-content">
        <div className="drawer-header">
          <IconButton color="default" component="span" onClick={() => handleCloseDrawer(false)}>
            <Close />
          </IconButton>
          <div className="title">More Filters</div>
        </div>
        <div className="drawer-filters">
          {drawerData.map((item, idx) => (
            <div key={idx}>
              {item.type == 'Date'
                ? dateTypeDrawer(
                    item.label,
                    item.fromDate,
                    item.setFromDate,
                    item.toDate,
                    item.setToDate,
                    item.onClearClick,
                    item.isActive
                  )
                : item.type == 'Time'
                ? timeTypeDrawer(
                    item.label,
                    item.fromTime,
                    item.setFromTime,
                    item.toTime,
                    item.setToTime,
                    item.onClearClick,
                    item.isActive
                  )
                : item.type == 'Checkbox'
                ? checkBoxTypeDrawer(item.label, item.value, item.setValue, item.onClearClick)
                : item.type == 'Radio'
                ? radioTypeDrawer(item.label, item.setValue, item.value, item.list, item.onClearClick)
                : item.type == 'TextField'
                ? textFieldTypeDrawer(item.label, item.placeholder, item.setValue, item.value, item.onClearClick)
                : item.type == 'Combo'
                ? comboFieldTypeDrawer(
                    item.label,
                    item.placeholder,
                    item.setValue,
                    item.value,
                    item.onClearClick,
                    item.dropdown,
                    item.dropdownType,
                    item.setDropdownValue,
                    item.dropdownValue
                  )
                : null}
            </div>
          ))}
        </div>
        <div className="drawer-footer">
          <Grid container justify="center" alignItems="center" spacing={2}>
            <Grid item>
              <Btn variant="contained" onClick={() => clearFilter()} color="primary" className="right-button">
                Clear all filters
              </Btn>
            </Grid>
            <Grid item>
              <Btn className="left-button" onClick={() => doneFilter()} variant="contained" color="primary">
                Done
              </Btn>
            </Grid>
          </Grid>
        </div>
      </div>
    </Drawer>
  )
}

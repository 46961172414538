import React, { useEffect, useState } from 'react'
import { API, graphqlOperation, Auth, Storage } from 'aws-amplify'
import { searchCustomers } from '@graphql/queries'
// import { deleteCustomer, exportCustomerData, generateAdminPortalS3UploadLink } from '@graphql/mutations'
// import { onCreateUploadJob, onUpdateDownloadJob } from '@graphql/subscriptions'
import { CustomTable, Layout, SideNav, Notification , LoadingSpinner, Button, Timestamp } from '@components'
import { Visibility } from '@material-ui/icons'
import { Tooltip, Box, Button as Btn, Grid } from '@material-ui/core'
import { validator, formatter, Authentication } from '@utils'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import Moment from 'react-moment'
import uuid from 'uuid'
import CustomerTempList from "./tempCustomerList.json"
import './CustomerList.scss'

export default function CustomerList(props) {

  const [isLoading, setIsLoading] = useState(true)
  const [isTimeOut, setIsTimeOut] = useState(true)
  const [userMatrix, setUserMatrix] = useState()

  const [total, setTotal] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const maxRowPerPage = 50
  const [customer, setCustomer] = useState({})
  const [keyword, setKeyword] = useState('')
  const [searchFilter, setSearchFilter] = useState()
  const [nextToken, setNextToken] = useState(null)
  const defaultSort = { field: 'accountNo', direction: 'desc' }
  const [sortField, setSortField] = useState(defaultSort)

  // useEffect(() => {
  //   fetchData()
  //   setCustomer(CustomerTempList.customers)
  //   setIsTimeOut(false)
  // }, [])
  useEffect(() => {
    async function user() {
      let res = await Authentication.checkUserPermission('customer')
      if (res == null) {
        setUserMatrix({ canList: true, canView: true, canAdd: true, canDelete: true, canEdit: true })
      } else if (res) {
        setUserMatrix(res[0])
      }
    }

    let username = null
    async function getUsername() {
      let currentUser = await Auth.currentAuthenticatedUser({ bypassCache: false })
      username = currentUser.username
    }
    //hardcode permission
    async function user2(){
      let res = await Authentication.getUserGroup()
      console.log(res)
      if (res[0] === "Admin" || res[0] === "CustomerService"){
        setUserMatrix({ canList: true, canView: true, canAdd: true, canDelete: true, canEdit: true })
      }
    }
    user2()
    setIsLoading(true)
    fetchData()
    // setCustomer(CustomerTempList.customers)
    // setIsTimeOut(false)
    // getUsername()

  }, [searchFilter])

  async function fetchData(currentNextToken, limit = maxRowPerPage, page = 0, orderBy = null, orderDirection = null) {
    // setIsLoading(false)
    try {
      let skipRow = parseInt(page) * parseInt(limit)
      let res = null
      let sort = sortField

      if (orderBy && orderDirection) {
        sort = { field: orderBy, direction: orderDirection }
        setSortField(sort)
      }

      if (!validator.isEmpty(searchFilter)) {
        res = await API.graphql(
          graphqlOperation(searchCustomers, {
            filter: searchFilter,
            limit: limit,
            nextToken: skipRow,
            sort: sort
          })
        )
      } else {
        res = await API.graphql(
          graphqlOperation(searchCustomers, { limit: limit, nextToken: skipRow, sort: sort })
        )
      }
      console.log(res)

      if (res.data.searchCustomers) {
        setTotal(res.data.searchCustomers.total)
        let currentCustomerList = res.data.searchCustomers.items
        setCustomer(currentCustomerList)

        if (res.data.searchCustomers.total > skipRow && res.data.searchCustomers.items.length === limit) {
          setNextToken(res.data.searchCustomers.items.length)
        } else {
          setNextToken(null)
        }
      } else {
        setCustomer([])
        setNextToken(null)
      }
      setIsTimeOut(false)
      setIsLoading(false)
    } catch (err) {
      console.log(err)
      if (err.data.searchCustomers.items.length) {
        let skipRow = parseInt(page) * parseInt(limit)
        setTotal(err.data.searchCustomers.total)
        let currentCustomerList = err.data.searchCustomers.items
        setCustomer(currentCustomerList)

        if (err.data.searchCustomers.total > skipRow && err.data.searchCustomers.items.length === limit) {
          setNextToken(err.data.searchCustomers.items.length)
        } else {
          setNextToken(null)
        }
        setIsTimeOut(false)
      } else {
        setIsTimeOut(true)
      }
      setIsLoading(false)
    }
    setCurrentPage(page)
  }

  const handleEditItem = (row) => {
    props.history.push({
      pathname: `/customer/${row.customerId}`,
      state: { item: row }
    })
  }


  const columns = [
    {
      key: '',
      title: 'No.',
      render: (row, ridx) => <div>{currentPage * maxRowPerPage + ridx + 1}.</div>
    },
    {
      key: 'firstName',
      title: 'First Name',
      render: (row) => <div>{row.firstName}</div>
    },
    {
      key: 'lastName',
      title: 'Last Name',
      render: (row) => <div>{row.lastName}</div>
    },
    {
      key: 'gender',
      title: 'Gender',
      render: (row) => <div>{formatter.capitalize(row.gender)}</div>
    },
    {
      key: 'accountNo',
      title: 'Account No.',
      render: (row) => <div>{row.accountNo}</div>
    },
    {
      key: 'email',
      title: 'Email',
      render: (row) => <div>{row.email}</div>
    },
    {
      key: 'mobileNumber',
      title: 'Mobile Number',
      render: (row) => <div>{row.mobileNumber}</div>
    },
    {
      key: 'updatedAt',
      title: 'Last Edited',
      render: (row) =>
        row.updatedAt ? (
          <div>
            <Moment format="DD/MM/YYYY">{row.updatedAt}</Moment>
            <br />
            <Timestamp>{row.updatedAt}</Timestamp>
          </div>
        ) : null
    },
    {
      title: '',
      render: (row) => (
        <div>
          {userMatrix ? (
            userMatrix.canView === true ? (
              <Tooltip arrow placement="top" title="View">
                <Visibility className="mui-icon" onClick={() => handleEditItem(row)} />
              </Tooltip>
            ) : null
          ) : null}
          {/* <Tooltip arrow placement="top" title="View">
            <Visibility className="mui-icon" onClick={() => handleEditItem(row)} />
          </Tooltip> */}
        </div>
      )
    }
  ]

  const elasticSearch = async (item) => {
    let currentSearchFilter = {}
    let keywordToSearch = ''
    let emailDomainKeywordToSearch = ''

    if (item) {
      var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
      var symbolPattern = /@/
      if (emailPattern.test(item) || symbolPattern.test(item)) {
        var res = item.split('@')
        keywordToSearch = res[0]
        emailDomainKeywordToSearch = res[1]
      } else {
        keywordToSearch = item
      }
    }

    if (keywordToSearch && keywordToSearch.length > 0) {
      currentSearchFilter = { or: [] }
      let CustomerFirstNameFilter = { and: [] }
      let CustomerLastNameFilter = { and: [] }
      let GenderFilter = { and: [] }
      let CustomerAccountNoFilter = { and: [] }
      let CustomerEmailFilter = { and: [] }
      let CustomerEmailDomainFilter = { and: [] }
      let PhoneNumberFilter = { and: [] }
      let splitKeywords = keywordToSearch.toLowerCase().split(/[\s-]/)
      let splitEmailDomainKeywords = emailDomainKeywordToSearch.toLowerCase().split(' ')

      console.log(keyword)
      if (emailDomainKeywordToSearch && emailDomainKeywordToSearch.length > 0) {
        splitKeywords.map((keyword) => {
          CustomerFirstNameFilter['and'].push({ firstName: { regexp: '.*' + keyword + '.*' } })
          CustomerLastNameFilter['and'].push({ lastName: { regexp: '.*' + keyword + '.*' } })
          GenderFilter['and'].push({ gender: { match: keyword } })
          CustomerAccountNoFilter['and'].push({ accountNo: { regexp: '.*' + keyword + '.*' } })
          CustomerEmailFilter['and'].push({ email: { regexp: '.*' + keyword + '.*' } })
          PhoneNumberFilter['and'].push({ mobileNumber: { regexp: '.*' + keyword + '.*' } })
          splitEmailDomainKeywords.map((emailDomainKeyword) => {
            CustomerEmailDomainFilter['and'].push(
              { email: { regexp: '.*' + keyword + '.*' } },
              { email: { regexp: '.*' + emailDomainKeyword + '.*' } }
            )
          })
          currentSearchFilter['or'].push(CustomerFirstNameFilter)
          currentSearchFilter['or'].push(CustomerLastNameFilter)
          currentSearchFilter['or'].push(GenderFilter)
          currentSearchFilter['or'].push(CustomerAccountNoFilter)
          currentSearchFilter['or'].push(CustomerEmailDomainFilter)
          currentSearchFilter['or'].push(PhoneNumberFilter)
          console.log(currentSearchFilter)
        })
      } else {
        splitKeywords.map((keyword) => {
          CustomerFirstNameFilter['and'].push({ firstName: { regexp: '.*' + keyword + '.*' } })
          CustomerLastNameFilter['and'].push({ lastName: { regexp: '.*' + keyword + '.*' } })
          GenderFilter['and'].push({ gender: { match: keyword } })
          CustomerAccountNoFilter['and'].push({ accountNo: { regexp: '.*' + keyword + '.*' } })
          CustomerEmailFilter['and'].push({ email: { regexp: '.*' + keyword + '.*' } })
          PhoneNumberFilter['and'].push({ mobileNumber: { regexp: '.*' + keyword + '.*' } })
        })
        currentSearchFilter['or'].push(CustomerFirstNameFilter)
        currentSearchFilter['or'].push(CustomerLastNameFilter)
        currentSearchFilter['or'].push(GenderFilter)
        currentSearchFilter['or'].push(CustomerAccountNoFilter)
        currentSearchFilter['or'].push(CustomerEmailFilter)
        currentSearchFilter['or'].push(PhoneNumberFilter)
        console.log(currentSearchFilter)
      }
    }
    setSearchFilter(currentSearchFilter)
  }
  

  return (
    <>
      <Layout.Body>
        <Layout.LeftPanel>
          <SideNav {...props} />
        </Layout.LeftPanel>
        <Layout.Content title="Customers" description="Display and manage all customer" total={total} state={props}>
          <>
          {isLoading ? (
            <LoadingSpinner />
          ) : isTimeOut ? (
            <div className="button-container">
              <Button.PrimaryButton className="refresh-button" title="retry" handleClick={() => fetchData()} />
            </div>
          ) : null}
           {!isTimeOut ? (
              <div className="customer-container">
                <CustomTable
                  maxRow={maxRowPerPage}
                  rows={customer}
                  cols={columns}
                  state={props}
                  onSearch={elasticSearch}
                  total={total}
                  link="/customer/add"
                  nextToken={nextToken}
                  fetchData={fetchData}
                  disabledPagination={isLoading}
                />
              </div>
            ) : null}
          </>

        </Layout.Content>
      </Layout.Body>
    </>
  )
}

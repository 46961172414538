import React from 'react'
import CustomSection from '@components/CustomSection/CustomSection'
import { formatter } from '@utils'
// import moment from 'moment'

export default function ParcelDetails(props) {
  const { parcelOrderDetail } = props
 
  let senderColumns = [
    {
        title: 'Tracking No',
        value: parcelOrderDetail.trackerNumber,
        size: {
            m: 2,
            xs: 12
        },
    },
    {
        title: 'Parcel Size',
        value: parcelOrderDetail.parcelSize,
        size: {
          m: 2,
          xs: 12
      }
    },
    {
        title: 'Shipment Price',
        value: formatter.toPrice(parcelOrderDetail.deliveryPrice),
        size: {
            m: 2,
            xs: 12
        },
    },
    {
      title: 'Discount Type',
      value: parcelOrderDetail.discountType ? parcelOrderDetail.discountType : "-",
      size: {
        m: 2,
        xs: 12
      }
    },
    {
        title: 'Discount Amount',
        value: parcelOrderDetail.promoCodeDiscount ? formatter.toPrice(parcelOrderDetail.promoCodeDiscount) : "RM 0.00",
        size: {
            m: 2,
            xs: 12
        },
    },
    {
        title: 'Refund Status',
        value: parcelOrderDetail.refundStatus ? parcelOrderDetail.refundStatus : "-",
        size: {
          m: 2,
          xs: 12
      },
      inputStyle: { width: 'fit-content'},
    },
]

  return (
    <>
     <CustomSection columns={senderColumns} inlineTitle={false} />
    </>
  )
}

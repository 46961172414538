import React, { useState, useEffect } from 'react'
import { Layout, SideNav, Button, CustomTable, LoadingSpinner, Notification, Timestamp } from '@components'
import { CustomInputBase } from '@components/CustomInputBase/CustomInputBase'
import { ReactDatesWrapper } from '@components/CustomDateComponent/CustomDate'
import { API, graphqlOperation, Auth } from 'aws-amplify'
import { formatter, validator, Authentication } from '@utils'
import Moment from 'react-moment'
import moment from 'moment'
import './Refund.scss'
import { makeStyles, Select, MenuItem, FormControl, InputLabel, Grid } from '@material-ui/core'
// import { updateRefundTransaction, downloadRefundReport, updateOrder } from '@graphql/mutations'
import { searchRefundTransactions,adminGetDownloadJobStatus } from '@graphql/queries'
import { adminTriggerDownloadReport } from '@graphql/mutations'
import _ from 'lodash'
// import './react_dates_overrides.scss'

const useStyles = makeStyles({
  underline: {
    borderRadius: 24,
    border: '1px solid #EAEAEA',
    fontFamily: 'din',
    backgroundColor: 'white',
    position: 'relative',
    top: '16px',
    paddingLeft: '20px',
    '&&&:before': {
      borderBottom: 'none'
    },
    '&&:after': {
      borderBottom: 'none'
    }
  }
})

export default function RefundListing(props) {

  const classes = useStyles()

  const [total, setTotal] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isTimeOut, setIsTimeOut] = useState(true)
  const [userMatrix, setUserMatrix] = useState()

  //table
  const maxRowPerPage = 50
  const [currentPage, setCurrentPage] = useState(0)
  const [data, setData] = useState([])
  const [keyword, setKeyword] = useState(null)
  const [searchFilter, setSearchFilter] = useState('')
  const [nextToken, setNextToken] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState('All')
  const [selectedDate, setSelectedDate] = useState('orderDate')
  const [filterType, setFilterType] = useState('All')
  const [statusFilterType, setStatusFilterType] = useState('All')
  const [clear, setClear] = useState(false)
  const [orderDateFrom, setorderDateFrom] = useState(null)
  const [orderDateTo, setorderDateTo] = useState(null)
  const [focusedInput, setFocusedInput] = useState(null)
  const defaultSort = { field: 'updatedAt', direction: 'desc' }
  const [sortField, setSortField] = useState(defaultSort)
  const [isDownloading, setIsDownloading] = useState(false)
  const [downloadUrl, setDownloadUrl] = useState('')

  //notification
  const [modal, setModal] = useState({
    show: false,
    severity: ''
  })

  useEffect(() => {
    async function user() {
      let res = await Authentication.checkUserPermission('refund')
      if (res == null) {
        let adminAccess = { canList: true, canView: true, canAdd: true, canDelete: true, canEdit: true }
        setUserMatrix(adminAccess)
        // let List = []
        // if (adminAccess.canAdd != true) {
        //   List = columns.filter((item) => item.key != 'process')
        // }
        // setMenuList(List)
      } else if (res) {
        setUserMatrix(res[0])
        // let List = []
        // if (res[0].canAdd != true) {
        //   List = columns.filter((item) => item.key != 'process')
        // }
        // setMenuList(List)
      }
    }
    // user()
    fetchData()
    setIsTimeOut(false)
  }, [searchFilter])

  useEffect(() => {
    if (downloadUrl !== '') {
      window.open(downloadUrl, '_blank')
      setModal({
        ...modal,
        show: true,
        severity: 'success',
        autoHide: 1000,
        message: 'Order report downloaded successfully. '
      })
    }
  }, [downloadUrl])

  const rangeDatesChangeHandler = ({ startDate, endDate }) => {
    if (moment(startDate).isSameOrBefore(moment('2020-01-01').startOf('days'))) {
      setModal({
        ...modal,
        show: true,
        severity: 'error',
        message: 'Start Date cannot be set before year 2020',
        component: 'startDate'
      })
      return
    }
    if (moment(startDate).isSameOrAfter(moment('2100-01-01').startOf('days'))) {
      setModal({
        ...modal,
        show: true,
        severity: 'error',
        message: 'Start Date cannot be set after year 2100',
        component: 'startDate'
      })
      return
    }
    if (moment(endDate).isSameOrAfter(moment('2100-01-01').startOf('days'))) {
      setModal({
        ...modal,
        show: true,
        severity: 'error',
        message: 'End Date cannot be set after year 2100',
        component: 'endDate'
      })
      return
    }

    if (moment(endDate).isSameOrBefore(moment('2020-01-01').startOf('days'))) {
      setModal({
        ...modal,
        show: true,
        severity: 'error',
        message: 'End Date cannot be set before year 2020',
        component: 'endDate'
      })
      return
    }
    setorderDateFrom(startDate)
    setorderDateTo(endDate)
  }

  const onFocusChangeRangeHandler = (focusedInput) => {
    setFocusedInput(focusedInput)
  }

  async function fetchData(currentNextToken, limit = maxRowPerPage, page = 0, orderBy = null, orderDirection = null) {
    
    try {
      let skipRow = parseInt(page) * parseInt(limit)
      let res = null
      let sort = sortField

      if (orderBy && orderDirection) {
        sort = { field: orderBy, direction: orderDirection }
        setSortField(sort)
      }

      if (!validator.isEmpty(searchFilter)) {
        res = await API.graphql(
          graphqlOperation(searchRefundTransactions, {
            filter: searchFilter,
            limit: limit,
            nextToken: skipRow,
            sort: sort
          })
        )
      } else {
        res = await API.graphql(
          graphqlOperation(searchRefundTransactions, { limit: limit, nextToken: skipRow, sort: sort })
        )
      }
      if (res.data.searchRefundTransactions) {
        setTotal(res.data.searchRefundTransactions.total)
        let currentRefundList = res.data.searchRefundTransactions.items
        setData(currentRefundList)

        if (
          res.data.searchRefundTransactions.total > skipRow &&
          res.data.searchRefundTransactions.items.length === limit
        ) {
          setNextToken(res.data.searchRefundTransactions.items.length)
        } else {
          setNextToken(null)
        }
      } else {
        setData([])
        setNextToken(null)
      }
      setIsTimeOut(false)
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      setIsTimeOut(true)
    }
    setCurrentPage(page)
  }

  const elastic = async (item) => {
    if (orderDateFrom == null) {
      setModal({ ...modal, show: true, severity: 'error', message: 'Start Date is required to filter' })
    } else if (orderDateTo == null) {
      setModal({ ...modal, show: true, severity: 'error', message: 'End Date is required to filter' })
    } else if (orderDateFrom > orderDateTo) {
      setModal({ ...modal, show: true, severity: 'error', message: 'Start Date must later than End Date' })
    } else {
      if (selectedDate === "orderDate"){
        delete searchFilter['eghlRefundDateTime']
        setSearchFilter({
          ...searchFilter,
          orderDate: { gte: moment(orderDateFrom).startOf('days'), lte: moment(orderDateTo).endOf('days') }
        })
      } else {
        delete searchFilter['orderDate']
        setSearchFilter({
          ...searchFilter,
          eghlRefundDateTime: { gte: moment(orderDateFrom).startOf('days'), lte: moment(orderDateTo).endOf('days') }
        })
      }
     
    }
  }

  const handleDropDownSearchFilter = async (e) => {
    setClear(true)
    setTimeout(() => {
      setClear(false)
    }, 100)
    setSelectedCategory(e)
    elasticSearch(null)
  }

  const handleDropDownStatusFilter = async (e) => {
    setStatusFilterType(e)
    let currentSearchFilter = { and: [] }
    let refundStatusFilter = { or: [] }
    if (filterType === 'Auto') {
      if (e === 'Refunded') {
        refundStatusFilter['or'].push({ automatedRefundStatus: { eq: true } })
      } else if (e === 'Waiting') {
        refundStatusFilter['or'].push({ automatedRefundStatus: { eq: false } })
      } else {
        refundStatusFilter['or'].push({ automatedRefundStatus: { eq: true } })
        refundStatusFilter['or'].push({ automatedRefundStatus: { eq: false } })
      }
    } else if (filterType === 'Manual') {
      if (e === 'Refunded') {
        refundStatusFilter['or'].push({ manualRefundStatus: { eq: true } })
      } else if (e === 'Waiting') {
        refundStatusFilter['or'].push({ manualRefundStatus: { eq: false } })
      } else {
        refundStatusFilter['or'].push({ manualRefundStatus: { eq: true } })
        refundStatusFilter['or'].push({ manualRefundStatus: { eq: false } })
      }
    } else if (filterType === 'Email' || filterType === 'All') {
      if (e === 'Refunded') {
        refundStatusFilter['or'].push({ emailRefundStatus: { eq: true } })
      } else if (e === 'Waiting') {
        refundStatusFilter['or'].push({ emailRefundStatus: { eq: false } })
      } else {
        refundStatusFilter['or'].push({ emailRefundStatus: { eq: true } })
        refundStatusFilter['or'].push({ emailRefundStatus: { eq: false } })
      }
      if (filterType === 'All') {
        if (e === 'Refunded') {
          refundStatusFilter['or'].push({ automatedRefundStatus: { eq: true } })
          refundStatusFilter['or'].push({ manualRefundStatus: { eq: true } })
        } else if (e === 'Waiting') {
          refundStatusFilter['or'].push({ automatedRefundStatus: { eq: false } })
          refundStatusFilter['or'].push({ manualRefundStatus: { eq: false } })
        } else {
          refundStatusFilter['or'].push({ automatedRefundStatus: { eq: true } })
          refundStatusFilter['or'].push({ automatedRefundStatus: { eq: false } })
          refundStatusFilter['or'].push({ manualRefundStatus: { eq: false } })
          refundStatusFilter['or'].push({ manualRefundStatus: { eq: true } })
        }
      }
    }
    currentSearchFilter['and'].push(refundStatusFilter)
    setSearchFilter({ ...searchFilter, or: currentSearchFilter['and'] })
  }

  const handleDateDropDownFilter = async (e) => {
    setClear(true)
    setTimeout(() => {
      setClear(false)
    }, 100)
    setSelectedDate(e)
    //elasticSearch(keyword)
  }

  const handleClear = () => {
    setorderDateFrom(null)
    setorderDateTo(null)
    setFilterType('All')
    setSelectedCategory('All')
    setStatusFilterType('All')
    setSearchFilter([])
    setClear(true)
    setTimeout(() => {
      setClear(false)
    }, 100)
    setKeyword(null)
  }

  const columns = [
    {
      key: '',
      title: 'No.',
      render: (row, ridx) => <div>{currentPage * maxRowPerPage + ridx + 1}.</div>
    },
    {
      key: 'null',
      title: 'Order ID',
      render: (row) => (
        <div>
         {null}
        </div>
      )
    },
    {
      key: 'orderNumber',
      title: 'Order No.',
      render: (row) => (
        <div>
         {row.orderNumber}
        </div>
      )
    },
    {
      key: 'orderDate',
      title: 'Order Date',
      render: (row) => (
        <div>
          <Moment format="DD/MM/YYYY">{row.orderDate}</Moment>
          <br />
          <Timestamp>{row.orderDate}</Timestamp>
        </div>
      )
    },
    {
      key: 'eghlRefundDateTime',
      title: 'Refund Date',
      render: (row) => (
        <div>
          {row.eghlRefundDateTime!==null?(
          <>
          <Moment format="DD/MM/YYYY">{row.eghlRefundDateTime}</Moment>
          <br />
          <Timestamp>{row.eghlRefundDateTime}</Timestamp>
          </>
          ): (row.status==="Refunded" || row.status==="Refund Failed")?(
          <>
          <Moment format="DD/MM/YYYY">{row.eghlRefundDateTime}</Moment>
          <br />
          <Timestamp>{row.eghlRefundDateTime}</Timestamp>
          </>
          ) : null}
        </div>
      )
    },
    {
      key: 'null',
      title: 'Original Order No.',
      render: (row) => (
        <div>
         {null}
        </div>
      )
    },
    {
      key: 'customerName',
      title: 'Customer',
      render: (row) => <div>{row.customerName}</div>
    },
    {
      key: 'trackerNumber',
      title: 'Tracking Number',
      render: (row) => <div>{row.trackerNumber}</div>
    },
    {
      key: 'null',
      title: 'Discount Type',
      render: (row) => (
        <div>
         {null}
        </div>
      )
    },
    {
      key: 'null',
      title: 'Flyer Qty',
      render: (row) => (
        <div>
         {null}
        </div>
      )
    },
    {
      key: 'null',
      title: 'Parcel Amt',
      render: (row) => (
        <div>
         {null}
        </div>
      )
    },
    {
      key: 'null',
      title: 'Flyer Amt',
      render: (row) => (
        <div>
         {null}
        </div>
      )
    },
    {
      key: 'null',
      title: 'Discount',
      render: (row) => (
        <div>
         {null}
        </div>
      )
    },
    {
      key: 'null',
      title: 'Net total',
      render: (row) => (
        <div>
         {null}
        </div>
      )
    },
    {
      key: 'refundAmount',
      title: 'Refund Amt',
      render: (row) => <div>{formatter.toPrice(row.refundAmount)}</div>
    },
    {
      key: 'status',
      title: 'Status',
      render: (row) => {
        return (
          <div style={{ marginRight: '20px' }}>
            {row.status}
          </div>
        )
      }
    },
    {
      key: 'null',
      title: 'EGHL No',
      render: (row) => (
        <div>
         {null}
        </div>
      )
    }
      
  ]
  const download = async () => {
    setIsDownloading(true)
    let numberOfMonths = moment(orderDateTo).diff(moment(orderDateFrom), 'months', false) + 1
    console.log(numberOfMonths)

    if (numberOfMonths > 1) {
      setModal({
        ...modal,
        show: true,
        severity: 'error',
        message: 'Please try again with the start date and end date within ONE month duration'
      })
      setIsDownloading(false)
      return
    }
    setSearchFilter({
      ...searchFilter,
      orderDate: { gte: moment(orderDateFrom).startOf('days'), lte: moment(orderDateTo).endOf('days') }
    })

    try {
      let triggerDownloadReportRes = await API.graphql(
        graphqlOperation(adminTriggerDownloadReport, {
          searchField: selectedCategory,
          searchKeyword: keyword && keyword.length > 0 ? keyword : '',
          dateField: selectedDate,
          dateFrom: moment(orderDateFrom).startOf('days'),
          dateTo: moment(orderDateTo).endOf('days'),
          sort: sortField,
          module: 'refund'
        })
      )
      if (triggerDownloadReportRes) {
        const interval = setInterval(async () => {
          let getDownloadJobStatusRes = await API.graphql(
            graphqlOperation(adminGetDownloadJobStatus, {
              fileDownloadStatusId: triggerDownloadReportRes.data.adminTriggerDownloadReport.fileDownloadStatusId
            })
          )
          if (getDownloadJobStatusRes.data.adminGetDownloadJobStatus.status === 'FAILED') {
            clearInterval(interval)
            setModal({
              ...modal,
              show: true,
              severity: 'error',
              autoHide: 1000,
              message: getDownloadJobStatusRes.data.adminGetDownloadJobStatus.message
            })
            setIsDownloading(false)
          }
          if (getDownloadJobStatusRes.data.adminGetDownloadJobStatus.status === 'COMPLETED') {
            clearInterval(interval)
            setDownloadUrl(getDownloadJobStatusRes.data.adminGetDownloadJobStatus.objectPresignedUrl)
            setIsDownloading(false)
          }
        }, 2000)
      }
    } catch (error) {
      console.log(error)
      setModal({
        ...modal,
        show: true,
        severity: 'error',
        message: 'Please try again with the filters.'
      })
      setIsDownloading(false)
    }
    
  }

  const filterOption = () => {
    return (
      <div className="search-container_filter">
      <FormControl className="timeInput-box-select">
        Search Category
        <Select
          input={<CustomInputBase />}
          value={selectedCategory}
          onChange={(e) => handleDropDownSearchFilter(e.target.value)}
          displayEmpty>
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="orderNumber">Order Number</MenuItem>
          {/* <MenuItem value="trackerNumber">Tracking Number</MenuItem> */}
          <MenuItem value="customer">Customer</MenuItem>
        </Select>
      </FormControl>
      <FormControl className="timeInput-box-select">
          Search Date
          <Select
            input={<CustomInputBase />}
            value={selectedDate}
            onChange={(e) => handleDateDropDownFilter(e.target.value)}
            displayEmpty>
            <MenuItem value="orderDate">Order Date</MenuItem>
            <MenuItem value="eghlRefundDateTime">Refund Date</MenuItem>
          </Select>
        </FormControl>
      <Grid container justify="center" spacing={2}>
        <Grid item>
          <Grid container>
            <Grid item>
              <div className="date-picker">
                <ReactDatesWrapper
                  startDateId="start-date"
                  endDateId="end-date"
                  onDatesChange={rangeDatesChangeHandler}
                  onFocusChange={onFocusChangeRangeHandler}
                  focusedInput={focusedInput}
                  startDate={orderDateFrom}
                  endDate={orderDateTo}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <div className="search-button-container">
            <div style={{ marginRight: '5px' }}>
              <Button.PrimaryButton title="FILTER" handleClick={elastic} />
            </div>
            <div style={{ marginRight: '5px' }}>
              <Button.SecondaryButton title="CLEAR" handleClick={handleClear} />
            </div>
            {/* {userMatrix ? (
                userMatrix.canAdd == true ? ( */}
                  <>
                    {/* <div>
                      <FormControl className="filter-type">
                        <InputLabel id="demo-simple-select-helper-label">Download Mode</InputLabel>
                        <Select
                          input={<BootstrapInput />}
                          value={downloadMode}
                          onChange={(e) => setDownloadMode(e.target.value)}>
                          <MenuItem value="refund">Refund Report</MenuItem>
                          <MenuItem value="unsuccessful">Unsuccessful Refund Request</MenuItem>
                        </Select>
                      </FormControl>
                    </div> */}
                    <div >
                      <Button.DownloadButton
                        title="DOWNLOAD"
                        handleClick={download}
                        inProgress={isDownloading}
                        disabled={
                          isDownloading 
                        }
                      />
                    </div>
                  </>
                 {/* ) : null
               ) : null} */}
          </div>
        </Grid>
      </Grid>
    </div>
    )
  }

  const elasticSearch = async (item, category) => {
    let currentSearchFilter = {}
    let keywordToSearch = ''

    if (item) {
      keywordToSearch = item
      setKeyword(item)
    } else {
      setKeyword(null)
    }

    if (keywordToSearch && keywordToSearch.length > 0) {
      currentSearchFilter = { or: [] }
      // let productCodeFilter = { and: [] }
      let orderNumberFilter = { and: [] }
      let customerNameFilter = { and: [] }
      // let trackerNumberFilter = { and: [] }
      let splitKeywords = keywordToSearch.toLowerCase().split(' ')
      splitKeywords.map((keyword) => {
        // productCodeFilter['and'].push({ refundTxnId: { eq: _.parseInt(keyword) } })
        orderNumberFilter['and'].push({ orderNumber: { regexp: '.*' + keyword + '.*' } })
        // trackerNumberFilter['and'].push({ trackerNumber: { regexp: '.*' + keyword + '.*' } })
        customerNameFilter['and'].push({ customerName: { regexp: '.*' + keyword.toLowerCase() + '.*' } })
      })

      if (category === 'All') {
        // if (validator.isDigit(keywordToSearch) && _.parseInt(keywordToSearch) < Math.pow(2, 31)) {
        //   currentSearchFilter['or'].push(productCodeFilter)
        // }
        currentSearchFilter['or'].push(orderNumberFilter)
        // currentSearchFilter['or'].push(trackerNumberFilter)
        currentSearchFilter['or'].push(customerNameFilter)
      } 
      // else if (category === 'refundId') {
      //   if (validator.isDigit(keywordToSearch) && _.parseInt(keywordToSearch) < Math.pow(2, 31)) {
      //     currentSearchFilter['or'].push(productCodeFilter)
      //   }
      // } 
      else if (category === 'orderNumber') {
        currentSearchFilter['or'].push(orderNumberFilter)
      }  else if (category === 'customer') {
        currentSearchFilter['or'].push(customerNameFilter)
      } 
      // else if (category === 'trackerNumber'){
      //   currentSearchFilter['or'].push(trackerNumberFilter)
      // }
    }

    // if (keywordToSearch) {
    //   setSearchFilter({ ...searchFilter, and: [currentSearchFilter] })
    // } else {
    //   delete searchFilter.and
    //   setSearchFilter({ ...searchFilter })
    // }
    if (orderDateTo || orderDateFrom) {
      setSearchFilter({ ...searchFilter, or: currentSearchFilter })
    } else {
      setSearchFilter(currentSearchFilter)
    }
  }  

  return (
    <Layout.Body>
      <Layout.LeftPanel>
        <SideNav {...props} />
      </Layout.LeftPanel>
      <Layout.Content title="Refund" description="Display all Refund" total={total} state={props}>
        {isLoading ? (
          <LoadingSpinner />
        ) : isTimeOut ? (
          <div className="button-container">
            <Button.PrimaryButton className="refresh-button" title="retry" handleClick={() => fetchData()} />
          </div>
        ) : null}

        {!isTimeOut ? (
          <div className="refund-container">
            <CustomTable
              maxRow={maxRowPerPage}
              rows={data}
              cols={columns}
              total={total}
              state={props}
              onSearch={elasticSearch}
              searchCategory={selectedCategory}
              filter={filterOption}
              fetchData={fetchData}
              nextToken={nextToken}
              clear={clear}
              disabledPagination={isLoading}
            />
          </div>
        ) : null}
      </Layout.Content>
      <Notification
        message={modal.message}
        onClose={() => {
          setModal({ ...modal, show: false, severity: modal.severity })
          if (modal.component) setFocusedInput(modal.component)
        }}
        show={modal.show}
        severity={modal.severity}
      />
    </Layout.Body>
  )
}

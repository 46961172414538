import { createTheme  } from '@material-ui/core/styles'

let theme = createTheme ({
  typography: {
    fontFamily: ['din'].join(', '),
    fontSize: 16,
    h1: {
      fontWeight: 600,
      fontSize: '1.25rem',
      lineHeight: 'normal'
    },
    h2: {
      fontSize: '1.25rem',
      fontWeight: 600,
      lineHeight: 'normal'
    },
    h3: {
      textTransform: 'capitalize',
      fontSize: '1rem',
      lineHeight: 'normal'
    },
    h4: {
      fontSize: '0.75rem',
      fontWeight: 600,
      lineHeight: 'normal'
    },
    subtitle1: {
      fontSize: '0.75rem'
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 'normal'
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 'normal'
    }
  },
  spacing: (factor) => `${0.5 * factor}rem`,
  palette: {
    common: {
      white: '#fff',
      black: '#000'
    },
    primary: {
      main: '#1E91CF',
      light: '#F4F9FC'
    },
    secondary: {
      main: '#1E91CF',
      light: '#f1f1f2'
    },
    warning: {
      main: '#F96232'
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)'
    },
    grey: {
      100: '#6D6E71',
      200: '#999'
    }
  }
})

export default theme

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:0d3e560e-37de-4bb1-a914-ed766e90c4ec",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_ShzohgpwB",
    "aws_user_pools_web_client_id": "7veucbptqnt2vl4eenm4l3ddst",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://ozu7mplstrbgnapuuqxnkqwf2u.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-j464l5wfzbgojo643hlphoxvaa",
    "aws_user_files_s3_bucket": "pudoparcel-webassets-prod",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};


export default awsmobile;

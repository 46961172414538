import React from 'react'
import { Typography, InputBase, Button, Paper, CircularProgress } from '@material-ui/core'
import { Email, Lock, AccessTime } from '@material-ui/icons'

export default function SignIn(props) {
  const {
    email,
    password,
    confirmPassword,
    setConfirmPassword,
    setPassword,
    setEmail,
    isForgot,
    setForgot,
    handleForgot,
    loginValidation,
    setLoginValidation,
    mustResetPassword,
    code,
    setCode,
    isSend,
    setSend,
    errors,
    message,
    handleKeyPress,
    handleLogin,
    isLoading
  } = props

  const messageList = [
    'Must be minimum EIGHT characters',
    'Must contain combination of uppercase and lowercase',
    'Must contain at least ONE number',
    'Must contain at least ONE symbol'
  ]

  return (
    <>
      <Typography color="textSecondary" gutterBottom>
        <small>{mustResetPassword ? 'Enter email to begin resetting password' : 'Sign in with credentials'}</small>
      </Typography>
      <div className="login-form-content">
        {loginValidation === true ? (
          <div className="login-alert-body">
            <div className={errors.blankfield == true ? 'login-success-box' : 'login-alert-box'}>{message}</div>
          </div>
        ) : (
          ''
        )}
        {isForgot == true ? (
          <div className="password-requirement">
            <h4>Password Requirements</h4>
            <ul>
              {messageList.map((message, idx) => (
                <li key={idx}>{message}</li>
              ))}
            </ul>
          </div>
        ) : null}

        <Paper className="login_paper">
          <Email />
          <InputBase
            className="login_input"
            placeholder="Email"
            value={email}
            disabled={isForgot}
            onChange={(e) => setEmail(e.target.value)}
            onKeyPress={handleKeyPress}
            autoComplete="one-time-code"
          />
        </Paper>
        {isForgot == true ? (
          <Paper className="login_paper">
            <AccessTime />
            <InputBase
              className="login_input"
              placeholder="Verification Code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              autoComplete="one-time-code"
            />
          </Paper>
        ) : null}
        {isSend == true || mustResetPassword ? null : (
          <Paper className="login_paper">
            <input type="text" style={{ display: 'none' }} />
            <input type="text" style={{ display: 'none' }} />
            <Lock />
            <InputBase
              className={password ? 'login_input_password' : 'login_input'}
              placeholder={isForgot == false ? 'Password' : 'New Password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={handleKeyPress}
              type="text"
              autoComplete="new-password"
            />
          </Paper>
        )}
        {isForgot == false ? null : (
          <Paper className="login_paper">
            <Lock />
            <InputBase
              className={confirmPassword ? 'login_input_password' : 'login_input'}
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              onKeyPress={handleKeyPress}
              type="text"
              autoComplete="new-password"
            />
          </Paper>
        )}
        {isForgot == true ? (
          <a onClick={handleForgot}>
            <p className="login_forget_password">Resend</p>
          </a>
        ) : isSend == false && !mustResetPassword ? (
          <a
            onClick={() => {
              setSend(true)
              setLoginValidation(false)
              setEmail('')
            }}>
            <p className="login_forget_password">Forgot password</p>
          </a>
        ) : null}
      </div>
      <div className="login_button_container">
        {isSend == true || isForgot == true ? (
          <Button
            style={{ marginLeft: '5px', color: 'grey', backgroundColor: 'white' }}
            className="login_button"
            onClick={() => {
              setSend(false)
              setEmail('')
              setPassword('')
              setCode('')
              setLoginValidation(false)
              setForgot(false)
            }}>
            {isForgot == false ? 'Back to Login' : 'Cancel'}
          </Button>
        ) : null}
        <Button
          variant="contained"
          className="login_button"
          disabled={isLoading}
          onClick={isSend == false ? handleLogin : handleForgot}>
          {!isLoading ? isSend == true || isForgot == true ? 'Submit' : 'Sign in' : <CircularProgress />}
        </Button>
      </div>
    </>
  )
}

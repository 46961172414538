import { ThemeProvider } from '@material-ui/core'
import './App.css';
import theme from './themes/theme'
import OrderList from '@pages/Order/OrderList'
import Routes from './infrastructure/routes'

function App(props) {
  return (
    <ThemeProvider theme={theme}>
      {/* <ToastContainer position="top-right" autoClose={3000} closeButton pauseOnFocusLoss={false} pauseOnHover={false} /> */}
      {/* <Routes {...props} /> */}
      <Routes {...props}/>
    </ThemeProvider>
  );
}

export default App;

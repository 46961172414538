import React, { useEffect, useState } from 'react'
import { Paper, Divider, Button } from '@material-ui/core'
// import { AddCircleOutline } from '@material-ui/icons'
import Moment from 'moment'
import './Panel.scss'
import AddCircle from './Addcircle.svg'
import AddIcon from '@assets/svgs/AddIcon.svg'
import { API, graphqlOperation } from 'aws-amplify'
// import { getCognitoUserEmail } from '@graphql/queries'
const Panel = (props) => {
  const {
    elevation = 3,
    title,
    modified,
    updatedAt,
    children,
    buttonTitle,
    saveButton,
    uploadTitle,
    onClick,
    isDisabled,
    marginTop = '0px',
    width = 'auto',
    disabled = false,
    popUp,
    onClickAdd,
    onClickCancel
    // lastDeactivatedBy = false,
    // lastDeactivatedUpdatedAt
  } = props
  const [email, setEmail] = useState(null)

  const getEmail = async () => {
    setEmail(modified)
    // try {
    //   const res = await API.graphql(
    //     graphqlOperation(getCognitoUserEmail, {
    //       cognitoId: modified.toString()
    //     })
    //   )

    //   if (res && res.data) {
    //     setEmail(res.data.getCognitoUserEmail)
    //   }
    // } catch (err) {
    //   console.log(err)
    // }
  }

  useEffect(() => {
    if (modified !== undefined) {
      getEmail()
    }
  }, [modified])

  return (
    <Paper
      elevation={elevation}
      className={popUp ? 'paper-container-popUp' : 'paper-container'}
      style={{ marginTop: marginTop, width: width }}>
      <div className="paper-container_box">
        <div className="paper-container_box-title">{title}</div>
        {isDisabled ? null : (
          <>
            {buttonTitle ? (
              <div
                className={!disabled ? 'paper-container_box-button' : 'paper-container_box-button_disabled'}
                onClick={!disabled ? onClick : null}>
                <img src={AddCircle} alt="addButton" /> {buttonTitle}
              </div>
            ) : null}
          </>
        )}
        {saveButton ? (
          <div className="button-container">
            <Button variant="contained" className="primary-btn" onClick={onClick} disabled={disabled}>
              {saveButton}
            </Button>
          </div>
        ) : null}
        {popUp ? (
          <div className="button-container">
            <Button className="primary-btn" onClick={onClickAdd} style={{ marginRight: 10 }}>
              <img src={AddIcon} alt="addIcon" style={{ paddingRight: '5px', paddingBottom: '0px' }} />
              Add
            </Button>
            <Button className="cancel-btn" onClick={onClickCancel}>
              Cancel
            </Button>
          </div>
        ) : null}
        {email ? (
          <div className="paper-container_box-edit">
            {`Last Edited by ${email} ${Moment(updatedAt).format('(DD/MM/YYYY)')}`}
            <br />
            {/* {lastDeactivatedBy ? (
              `Last Deactivated by ${lastDeactivatedBy} ${Moment(lastDeactivatedUpdatedAt).format('(DD/MM/YYYY hh:mma)')}`
          ) : null} */}
          </div>
        ) : null}
      </div>
      <Divider />
      <div style={{ marginTop: '7px' }}>{children}</div>
    </Paper>
  )
}

export default Panel

import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { Authentication } from '@utils'

//parcel
import ParcelOrderList from '../pages/Parcel/listing/ParcelList'
import ParcelDetail from '../pages/Parcel/detail/ParcelDetail'

//order
import OrderList from '@pages/Order/OrderList'
// import OrderDetail from '@pages/Section3_Customer/Order/Page/OrderDetail'

//refund
import Refund from '@pages/Refund/Refund'

//customer
import CustomerList from '@pages/Customer/listing/CustomerList'
import CustomerDetails from '@pages/Customer/detail/CustomerDetails'

//flyer bag history
import FlyerBagHistory from '@pages/FlyerBagHistory/FlyerBagHistory'

import LoginPage from '@pages/Common/Authentication/LoginPage/LoginPage'
import AuthenticationPage from '@pages/Common/Authentication/LoginPage/AuthenticationPage'

// Landing Redirector
import LandingRedirector from '@pages/Common/LandingRedirector/LandingRedirector'

//error
import Error from '@pages/Common/Error/Error'

export default function AppRouting() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/login" />} />
        <Route exact path="/login" component={AuthenticateRoutes} />
        <Route exact path="/authentication" component={ProtectedRoutes} />
        <Route exact path="/redirecting" component={ProtectedRoutes} />
        <Route exact path="/parcel-order" component={ProtectedRoutes} />
        <Route exact path="/parcel-order/:id" component={ProtectedRoutes} />
        <Route exact path="/order" component={ProtectedRoutes} />
        <Route exact path="/refund" component={ProtectedRoutes} />
        <Route exact path="/customer" component={ProtectedRoutes} />
        <Route exact path="/customer/:id" component={ProtectedRoutes} />
        <Route exact path="/flyer-bag-history" component={ProtectedRoutes} />
        <Route render={() => <Redirect to="/login" />} />
      </Switch>
    </Router>
  )
}

function ProtectedRoutes(props) {
  const [isAuthenticated, setIsAuthenticated] = useState(null)
  const [userMatrix, setUserMatrix] = useState(null)
  useEffect(() => {
    async function checkCredentials() {
      // console.log(await Auth.currentCredentials())
      console.log((await Auth.currentSession()).isValid())
    }
    checkCredentials()
  }, [props])
  useEffect(() => {
    async function ValidateUserSession() {
      try {
        await Auth.currentSession()
        let user = await Auth.currentUserPoolUser()
        let preferredMFA = await Auth.getPreferredMFA(user)
        console.log(preferredMFA)
        if (preferredMFA !== 'NOMFA' || user.username === '9d8592c5-0fd4-4a56-b129-f12378a173e9') {
          setTimeout(async () => {
            let matrix = await Authentication.checkUserPermission()
            setUserMatrix(matrix)
            setIsAuthenticated(true)
          }, 1000)
        } else {
          await Auth.signOut()
          setIsAuthenticated(false)
          window.location = '/'
        }
      } catch (err) {
        setIsAuthenticated(false)
        console.log(err)
      }
    }

    ValidateUserSession()
    setIsAuthenticated(props.isAuthenticated)
  }, [])

  return isAuthenticated != null ? (
    isAuthenticated == false ? (
      <Redirect to="/login" />
    ) : (
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            return <Redirect to="/redirecting" />
          }}
        />
        <Route
          exact
          path="/redirecting"
          render={(props) => {
            return <LandingRedirector {...props}> </LandingRedirector>
          }}
        />
        <Route
          exact
          path="/authentication"
          render={() => {
            return <AuthenticationPage {...props}> </AuthenticationPage>
          }}
        />
        <Route
          exact
          path="/parcel-order"
          render={(props) => {
            return userMatrix && userMatrix.findIndex((p) => p.module === 'order' && p.canList === true) >= 0 ? (
              <ParcelOrderList {...props}></ParcelOrderList>
            ) : userMatrix == null ? (
              <ParcelOrderList {...props}> </ParcelOrderList>
            ) : (
              <Redirect to="/error" />
            )
          }}
        />
         <Route
          exact
          path="/parcel-order/:id"
          render={(props) => {
            return userMatrix && userMatrix.findIndex((p) => p.module === 'order' && p.canView === true) >= 0 ? (
              <ParcelDetail {...props}></ParcelDetail>
            ) : userMatrix == null ? (
              <ParcelDetail {...props}> </ParcelDetail>
            ) : (
              <Redirect to="/error" />
            )
          }}
        />
        <Route
          exact
          path="/order"
          render={(props) => {
            return userMatrix && userMatrix.findIndex((p) => p.module === 'order' && p.canList === true) >= 0 ? (
              <OrderList {...props}></OrderList>
            ) : userMatrix == null ? (
              <OrderList {...props}> </OrderList>
            ) : (
              <Redirect to="/error" />
            )
          }}
        />
         <Route
          exact
          path="/refund"
          render={(props) => {
            return userMatrix && userMatrix.findIndex((p) => p.module === 'refund' && p.canList === true) >= 0 ? (
              <Refund {...props}></Refund>
            ) : userMatrix == null ? (
              <Refund {...props}> </Refund>
            ) : (
              <Redirect to="/error" />
            )
          }}
        />
        <Route
          exact
          path="/customer"
          render={(props) => {
            return userMatrix && userMatrix.findIndex((p) => p.module === 'order' && p.canView === true) >= 0 ? (
              <CustomerList {...props}></CustomerList>
            ) : userMatrix == null ? (
              <CustomerList {...props}> </CustomerList>
            ) : (
              <Redirect to="/error" />
            )
          }}
        />
        <Route
          exact
          path="/customer/:id"
          render={(props) => {
            return userMatrix && userMatrix.findIndex((p) => p.module === 'refund' && p.canList === true) >= 0 ? (
              <CustomerDetails {...props}></CustomerDetails>
            ) : userMatrix == null ? (
              <CustomerDetails {...props}> </CustomerDetails>
            ) : (
              <Redirect to="/error" />
            )
          }}
        />
        <Route
          exact
          path="/flyer-bag-history"
          render={(props) => {
            return userMatrix && userMatrix.findIndex((p) => p.module === 'refund' && p.canView === true) >= 0 ? (
              <FlyerBagHistory {...props}></FlyerBagHistory>
            ) : userMatrix == null ? (
              <FlyerBagHistory {...props}> </FlyerBagHistory>
            ) : (
              <Redirect to="/error" />
            )
          }}
        />
        
        {/* <Route
          exact
          path="/error"
          render={(props) => {
            return <Error {...props}></Error>
          }}
        /> */}
      </Switch>
    )
  ) : null
}

function AuthenticateRoutes() {
  const [isAuthenticated, setIsAuthenticated] = useState(null)
  useEffect(() => {
    async function ValidateUserSession() {
      try {
        await Auth.currentSession()
        let user = await Auth.currentUserPoolUser()
        let preferredMFA = await Auth.getPreferredMFA(user)
        if (preferredMFA !== 'NOMFA' || user.username === '9d8592c5-0fd4-4a56-b129-f12378a173e9') {
          setIsAuthenticated(true)
        } else {
          await Auth.signOut()
          setIsAuthenticated(false)
          window.location = '/'
        }
      } catch (err) {
        setIsAuthenticated(Authentication.getAuth())
        console.log(err)
      }
    }

    ValidateUserSession()
  }, [])

  return isAuthenticated != null ? (
    isAuthenticated == true ? (
      <Redirect to="/redirecting" />
    ) : (
      <Switch>
        <Route
          exact
          path="/login"
          render={(props) => {
            return <LoginPage {...props}></LoginPage>
          }}
        />
      </Switch>
    )
  ) : null
}

import numeral from 'numeral'

const domain = process.env.REACT_APP_CLOUDFRONT
export default {
  removeWhiteSpace(str) {
    return str.replace(/\s/g, '')
  },
  toS3Link(str) {
    if (str) {
      //Need to change to another env
      return encodeURI(`https://${domain}.cloudfront.net/public/${str}`)
    } else {
      return null
    }
  },
  toS3LinkThumnail(str) {
    if (str) {
      let url_arr = str.split('/')
      url_arr.pop()
      let folderUrl = url_arr.join('/')
      let imgIndex = str.lastIndexOf('/')
      let extensionIndex = str.lastIndexOf('.')
      let imgUrl = str.substring(imgIndex, extensionIndex)
      let thumnailUrl = folderUrl + '/t' + imgUrl + '_t.jpg'
      // console.log(thumnailUrl)
      //Need to change to another env
      return encodeURI(`https://${domain}.cloudfront.net/public/${thumnailUrl}`)
    } else {
      return null
    }
  },
  capitalize(str, toLowerCase = true) {
    if (typeof str === 'string') {
      const words = str.trim().split(' ')
      return words
        .map(
          (word) => word.charAt(0).toUpperCase() + (toLowerCase ? word.substring(1).toLowerCase() : word.substring(1))
        )
        .join(' ')
    }
    return ''
  },
  toPrice(number, isPoint = false, currency = 'RM') {
    if (typeof number === 'number') {
      return `${currency} ${numeral(number).format(isPoint ? '0,0' : '0,0.00')}`
    } else if (typeof number === 'string') {
      return `${currency} ${numeral(parseFloat(number)).format(isPoint ? '0,0' : '0,0.00')}`
    }
    return 0
  },
  roundTo(n, digits) {
    if (digits === undefined) {
      digits = 0
    }

    var multiplicator = Math.pow(10, digits)
    n = parseFloat((n * multiplicator).toFixed(11))
    var test = Math.round(n) / multiplicator
    return +test.toFixed(digits)
  }
}

import React from 'react'
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import './Notification.scss'

const Notification = (props) => {
  const { show, severity, message, onClose, autoHide } = props
  return (
    <Snackbar open={show} autoHideDuration={autoHide ? autoHide : 3000} onClose={onClose} className="snackbar-center">
      {severity ? (
        <Alert severity={severity} elevation={6} variant="filled">
          {message}
        </Alert>
      ) : null}
    </Snackbar>
  )
}

export default Notification

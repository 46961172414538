import React, { useState, useEffect } from 'react'
import { formatter } from '@utils'
import { CustomTable, Timestamp } from '@components'
import moment from 'moment'
import _ from 'lodash'

export default function ParcelStatusTransitionLogs(props) {
  const [data, setData] = useState([])
  const [total, setTotal] = useState(null)

  const columns = [
    {
      key: 'createdAt',
      title: 'Date',
      render: (row) => (
        <div>
          {moment(row.createdAt).format('DD/MM/YYYY')}
          <br />
          <Timestamp>{row.createdAt}</Timestamp>
        </div>
      )
    },
    {
      key: 'status',
      title: 'Status',
      render: (row) => <div>{row.status}</div>
    },
    {
      key: 'refundAmount',
      title: 'Refund Amount',
      render: (row) => <div>{row.refundAmount ? formatter.toPrice(row.refundAmount) : ''}</div>
    },
    {
      key: 'reasonCode',
      title: 'Reason Code',
      render: (row) => <div>{row.reasonCode}</div>
    },
    {
      key: 'cancelReason',
      title: 'Refund/Cancel Reason',
      render: (row) => <div>{row.cancelReason ? row.cancelReason : row.refundReason}</div>
    },
    {
      key: 'cancelBy',
      title: 'Complete/Refund/Cancel By',
      render: (row) => <div>{row.modifiedBy ? row.modifiedBy : row.cancelBy ? row.cancelBy : row.refundBy}</div>
    }
  ]

  useEffect(() => {
    fetchData()
  }, [props.data])

  async function fetchData(currentNextToken, limit = 50, page = 0, orderBy = null, orderDirection = null) {
    const chunks = _.chunk(props.data, 50)
    const chunk = chunks[page]
    setData(chunk)
    setTotal(props.data.length)
  }

  return (
    <div>
      {props.data.length > 0 ? (
        <CustomTable
          rows={data}
          cols={columns}
          state={props}
          maxRow={100}
          total={total}
          fetchData={fetchData}
          nextToken={'next'}
          isTransitionLog={true}
        />
      ) : (
        <div>Empty</div>
      )}
    </div>
  )
}

import { withStyles, InputBase } from '@material-ui/core'

const BootstrapInput = withStyles((theme) => ({
    root: {
      fontSize: '16px',
      fontFamily: 'din',
      color: '#000000'
    },
    input: {
      borderRadius: 23,
      backgroundColor: '#FFFFFF',
      border: '1px solid #EAEAEA',
      paddingLeft: '10px',
      '&:focus': {
        borderRadius: 23
      }
    }
}))(InputBase)


export const CustomInputBase = (props) => {
    return (
        <BootstrapInput {...props} />
    )
}

import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import { Lock } from '@material-ui/icons'
import { Typography, InputBase, Button, Paper } from '@material-ui/core'

export default function ForceChangePassword(props) {
  const { user, newPassword, setNewPassword, setQrMFA } = props
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState(null)
  const messageList = [
    'Must be minimum EIGHT characters',
    'Must contain combination of uppercase and lowercase',
    'Must contain at least ONE number',
    'Must contain at least ONE symbol'
  ]

  const confirmChangePassword = async () => {
    try {
      const loggedUser = await Auth.completeNewPassword(user, newPassword)
      const code = await Auth.setupTOTP(loggedUser)
      const str = 'otpauth://totp/AWSCognito:' + user.username + '?secret=' + code
      setQrMFA(str)
      // window.location = `/`
    } catch (e) {
      setError(e)
      console.log(e)
    }
  }
  const clearError = () => {
    setError(null)
  }
  return (
    <>
      <Typography color="textSecondary" gutterBottom>
        <small>Please Enter New Password For First Time Login</small>
      </Typography>
      <div className="login-form-content">
        {newPassword.length > 7 && confirmPassword.length > 7 && newPassword !== confirmPassword ? (
          <div className="login-alert-body">
            <div className="login-alert-box">Password does not match</div>
          </div>
        ) : null}
        <div className="password-requirement">
          <h4>Password Requirements</h4>
          <ul>
            {messageList.map((message, idx) => (
              <li key={idx}>{message}</li>
            ))}
          </ul>
        </div>
        <Paper component="form" className="login_paper">
          <Lock />
          <InputBase
            className={newPassword ? 'login_input_password' : 'login_input'}
            placeholder="New Password"
            type="text"
            value={newPassword}
            onChange={(e) => {
              setNewPassword(e.target.value)
              if (error) {
                clearError()
              }
            }}
          />
        </Paper>
        <Paper component="form" className="login_paper">
          <Lock />
          <InputBase
            className={confirmPassword ? 'login_input_password' : 'login_input'}
            placeholder="Confirm New Password"
            type="text"
            error={error}
            helpertext="true"
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value)
              if (error) {
                clearError()
              }
            }}
          />
        </Paper>
        <p style={{ color: 'red', fontSize: '12px' }}>{!!error && error.message}</p>
      </div>
      <div className="login_button_container">
        <Button variant="contained" className="login_button" onClick={confirmChangePassword}>
          Confirm
        </Button>
      </div>
    </>
  )
}

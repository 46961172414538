import { Cache as AmplifyCache } from 'aws-amplify'

const config = {
  keyPrefix: 'tempCache',
  itemMaxSize: 1000000, // 3000 bytes
  storage: window.sessionStorage // switch to sessionStorage
}

export const ClearCache = () => Cache.clear()

export const Cache = AmplifyCache.createInstance(config)

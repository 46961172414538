import React, { useState, useEffect } from "react";
import { API, graphqlOperation, Auth } from "aws-amplify";
import { Grid } from "@material-ui/core";
import {
  Layout,
  SideNav,
  Panel,
  LoadingSpinner,
  Popup,
  Notification,
} from "@components";
import OrderDetails from "../components/OrderDetails";
import SenderDetails from "../components/SenderDetails";
import ReceiverDetails from "../components/ReceiverDetails";
import ParcelDetails from "../components/ParcelDetails";
import FlyerPackDetails from "../components/FlyerPackDetails";
import ParcelStatusTransitionLogs from "../components/ParcelStatusTransitionLog";
import { adminGetParcelOrderDetail } from "@graphql/queries";
import { cancelOrderParcelList } from "@graphql/mutations";
import "./ParcelDetail.scss";

export default function ParcelDetail(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [parcelOrder, setParcelOrder] = useState(null);
  const [parcelOrderDetail, setParcelOrderDetail] = useState(null);
  const [otherParcelDetail, setOtherParcelDetail] = useState([]);
  const [parcelStatusLog, setParcelStatusLog] = useState([]);
  const [refundCofirmation, setRefundConfirmation] = useState({
    show: false,
    item: {},
  });
  const [cancelAllFlyerBag, setCancelAllFlyerBag] = useState(false);
  const [modal, setModal] = useState({
    show: false,
    severity: "",
    message: "",
  });

  const getParcelOrderDetail = async () => {
    // setIsLoading(true)
    try {
      const res = await API.graphql(
        graphqlOperation(adminGetParcelOrderDetail, {
          parcelOrderDetailId: props.match.params.id,
        })
      );
      if (res && res.data.adminGetParcelOrderDetail.status) {
        let result = res.data.adminGetParcelOrderDetail;
        setParcelOrder(result.parcel);
        setParcelOrderDetail(result.parcelDetail);
        setParcelStatusLog(result.parcelStatus);
        setOtherParcelDetail(result.otherParcelDetails);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getParcelOrderDetail();
  }, [props.match.params.id]);

  const handleRefund = async () => {
    setRefundConfirmation({ ...refundCofirmation, show: false });
    const res = await API.graphql(
      graphqlOperation(cancelOrderParcelList, {
        cancelFlyerBag: cancelAllFlyerBag,
        parcelOrderDetailIdList: [parcelOrderDetail.parcelOrderDetailId],
        parcelOrderId: parcelOrderDetail.parcelOrderId,
        refundReason: "Cancelled by customer service",
      })
    );
    if (res && res.data.cancelOrderParcelList.status === true) {
      setModal({
        ...modal,
        show: true,
        severity: "success",
        message: "Parcel Cancelled successfully",
      });
      getParcelOrderDetail();
    } else {
      setModal({
        ...modal,
        show: true,
        severity: "error",
        message: res.data.cancelOrderParcelList.message,
      });
    }
  };

  return (
    <>
      <Layout.Body>
        <Layout.LeftPanel>
          <SideNav {...props} />
        </Layout.LeftPanel>
        <Layout.Content
          title={`View Parcel Status`}
          description={`Viewing Parcel Status`}
          state={props}
          goBack={() => props.history.goBack()}
          canRefund={
            parcelOrderDetail?.status === null ? true : false
          }
          onRefund={() =>
            setRefundConfirmation({ ...refundCofirmation, show: true })
          }
        >
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <Grid container className="store-parcelOrderDetail-container">
              <Grid
                item
                xs={12}
                className="store-parcelOrderDetail-container_paper"
              >
                <Panel
                  elevation={3}
                  title="Order Details"
                  modified={parcelOrder.updatedBy}
                  updatedAt={parcelOrder.updatedAt}
                >
                  <OrderDetails
                    parcelOrder={parcelOrder}
                    parcelOrderDetail={parcelOrderDetail}
                  />
                </Panel>
                <Panel elevation={3} title="Sender Details" marginTop="10px">
                  <SenderDetails
                    parcelOrder={parcelOrder}
                    parcelOrderDetail={parcelOrderDetail}
                  />
                </Panel>
                <Panel elevation={3} title="Receiver Details" marginTop="10px">
                  <ReceiverDetails
                    parcelOrder={parcelOrder}
                    parcelOrderDetail={parcelOrderDetail}
                  />
                </Panel>
                <Panel elevation={3} title="Parcel Details" marginTop="10px">
                  <ParcelDetails
                    parcelOrder={parcelOrder}
                    parcelOrderDetail={parcelOrderDetail}
                  />
                  {otherParcelDetail.map((item, idx) => (
                    <ParcelDetails key={idx} parcelOrderDetail={item} />
                  ))}
                </Panel>
                <Panel
                  elevation={3}
                  title="Flyer Pack Details"
                  marginTop="10px"
                >
                  <FlyerPackDetails
                    parcelOrder={parcelOrder}
                    parcelOrderDetail={parcelOrderDetail}
                  />
                </Panel>
                <Panel elevation={3} title="Order Status" marginTop="10px">
                  <ParcelStatusTransitionLogs data={parcelStatusLog} />
                </Panel>
              </Grid>
            </Grid>
          )}
        </Layout.Content>
      </Layout.Body>
      <Notification
        message={modal.severity === "success" ? modal.message : modal.message}
        onClose={() =>
          setModal({ ...modal, show: false, severity: modal.severity })
        }
        show={modal.show}
        severity={modal.severity}
      />
      <Popup.Confirmation
        open={refundCofirmation.show}
        onClose={() =>
          setRefundConfirmation({ ...refundCofirmation, show: false })
        }
        onClick={() => handleRefund(refundCofirmation.item)}
        title="Cancel Confirmation"
        message={<div>Are you sure you want to cancel this parcel?</div>}
        canCancelFlyerBag={true}
        cancelAllFlyerBag={cancelAllFlyerBag}
        setCancelAllFlyerBag={setCancelAllFlyerBag}
      />
    </>
  );
}

import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import { formatter } from '@utils'

export default function MFAGuide(props) {
  const { setShowGuide } = props
  const [guide, setGuide] = useState([
    {
      text: 'Enter your <strong>new password</strong> when prompted.(For existing users, you will not see this page)',
      image: 'new-password'
    },
    {
      text:
        'Use <strong>Google Authenticator</strong> to scan the QR Code or you can <strong>copy</strong> and enter the secret code. Once you have scanned or entered the code, click <strong>Continue</strong>',
      image: 'qr-code'
    },
    {
      text:
        'Enter the <strong>6 digit code</strong> provided by the Google Authenticator. The code will be resetted every minute',
      image: 'verification-code'
    },
    {
      text:
        'Once you have entered the verification code, you will be logged into the Admin Portal. You can find more about MFA setup in this documentation <a href="https://docs.google.com/document/d/1wCPDvhpm8cnWK2-jKOm0vXTP73cUrG719tANBpXp25w" target="_blank">here</a>.<br/> Click continue to begin setting up MFA',
      image: ''
    }
  ])
  const [currentPage, setCurrentPage] = useState(-1)
  return (
    <>
      <div className="login-form-content">
        {currentPage < 0 ? (
          <>
            <p>
              Welcome to Multi-Factor Authentication(MFA) setup. You will be shown step by step on how to setup MFA
              first.
            </p>
            <div style={{ textAlign: 'left' }}>
              <p>
                <strong>Prerequisite:</strong>
              </p>
              <ul>
                <li>Download Google Authenticator from Play Store(Android) or App Store(iOS).</li>
              </ul>
            </div>
          </>
        ) : (
          <>
            <p dangerouslySetInnerHTML={{ __html: currentPage + 1 + '. ' + guide[currentPage].text }}></p>
            {guide[currentPage].image ? (
              <img
                style={{ width: 'auto', height: '250px' }}
                src={formatter.toS3Link('mfa-guide/' + guide[currentPage].image) + '.png'}
              />
            ) : null}
          </>
        )}
      </div>
      <div
        className="login_button_container"
        style={currentPage > 0 ? { display: 'flex', justifyContent: 'space-between' } : null}>
        {currentPage > 0 ? (
          <Button
            style={{ marginLeft: '5px' }}
            className="login_button"
            onClick={() => {
              setCurrentPage(currentPage - 1)
            }}>
            Prev
          </Button>
        ) : null}
        {currentPage < 4 ? (
          <Button
            style={{ marginLeft: '5px' }}
            className="login_button"
            onClick={() => {
              if (currentPage < 3) {
                setCurrentPage(currentPage + 1)
              } else {
                setShowGuide(false)
              }
            }}>
            {currentPage < 3 ? 'Next' : 'Continue'}
          </Button>
        ) : null}
      </div>
    </>
  )
}

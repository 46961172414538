import React from 'react'
import { Typography, InputBase, Button, Tooltip } from '@material-ui/core'
import { validator } from '@utils'

export default function VerifyMFA(props) {
  const { MFACode, handleMFACode, setMFASetup, verifyMFAError } = props
  return (
    <>
      <Typography color="textSecondary" gutterBottom>
        <small>
          Please enter the code in the Google Authenticator app to verify
          <br />
          <Tooltip
            title={
              <>
                Possible solutions:
                <ul>
                  <li>Make sure you scanned the QR code</li>
                  <li>Make sure you entered the correct code</li>
                  <li>
                    In Google Autheticator, go to Top Right Menu(Three Dot) &gt; Settings &gt; Time Correction for Code
                    &gt; Sync now
                  </li>
                </ul>
              </>
            }>
            <a href="#">Can't verify code?</a>
          </Tooltip>
        </small>
      </Typography>
      <div className="login-form-content">
        {!validator.isEmpty(verifyMFAError) && MFACode.length < 1 ? (
          <div className="login-alert-body">
            <div className="login-alert-box">{verifyMFAError.message}</div>
          </div>
        ) : null}
        <InputBase
          className="login_input"
          placeholder={'Verification Code'}
          value={MFACode}
          disabled={MFACode.length >= 6}
          onChange={(e) => handleMFACode(e.target.value)}
          // onKeyPress={handleKeyPress}
        />
      </div>
      <div className="login_button_container">
        <Button variant="contained" className="login_button" onClick={setMFASetup}>
          Back
        </Button>
      </div>
    </>
  )
}

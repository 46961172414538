/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const adminTriggerDownloadReport = /* GraphQL */ `
  mutation AdminTriggerDownloadReport(
    $dateField: String
    $dateFrom: String
    $dateTo: String
    $module: String
    $searchField: String
    $searchKeyword: String
    $sort: ElasticSearchSortDirection
  ) {
    adminTriggerDownloadReport(
      dateField: $dateField
      dateFrom: $dateFrom
      dateTo: $dateTo
      module: $module
      searchField: $searchField
      searchKeyword: $searchKeyword
      sort: $sort
    ) {
      fileDownloadStatusId
      message
      statusCode
    }
  }
`;
export const adminTriggerFlyerDownloadReport = /* GraphQL */ `
  mutation AdminTriggerFlyerDownloadReport(
    $field: ModelFlyerHistoryFilterInput
    $sort: ElasticSearchSortDirection
  ) {
    adminTriggerFlyerDownloadReport(field: $field, sort: $sort) {
      fileDownloadStatusId
      message
      statusCode
    }
  }
`;
export const cancelOrderParcelList = /* GraphQL */ `
  mutation CancelOrderParcelList(
    $cancelFlyerBag: Boolean
    $parcelOrderDetailIdList: [String]
    $parcelOrderId: String
    $refundReason: String
  ) {
    cancelOrderParcelList(
      cancelFlyerBag: $cancelFlyerBag
      parcelOrderDetailIdList: $parcelOrderDetailIdList
      parcelOrderId: $parcelOrderId
      refundReason: $refundReason
    ) {
      message
      status
    }
  }
`;
export const checkCart = /* GraphQL */ `
  mutation CheckCart(
    $flyerBagInput: [FlyerBagInput]
    $orderDetail: [String]
    $paymentMethod: String
    $paymentType: String
    $promoCode: String
    $sessionId: String
  ) {
    checkCart(
      flyerBagInput: $flyerBagInput
      orderDetail: $orderDetail
      paymentMethod: $paymentMethod
      paymentType: $paymentType
      promoCode: $promoCode
      sessionId: $sessionId
    ) {
      message
      promoCodeDiscount
      promoCodeTitle
      status
    }
  }
`;
export const checkUserSSOToken = /* GraphQL */ `
  mutation CheckUserSSOToken(
    $email: String
    $firstName: String
    $lastName: String
    $loginRedirect: Boolean
    $platform: String
    $token: String
  ) {
    checkUserSSOToken(
      email: $email
      firstName: $firstName
      lastName: $lastName
      loginRedirect: $loginRedirect
      platform: $platform
      token: $token
    ) {
      email
      isFMMember
      message
      mobileNumber
      name
      sessionId
      status
      token
    }
  }
`;
export const createParcelCart = /* GraphQL */ `
  mutation CreateParcelCart(
    $collectionStore: String
    $dropOffStore: String
    $parcelValue: Float
    $receiverEmail: String
    $receiverMobileNumber: String
    $receiverName: String
    $sessionId: String
  ) {
    createParcelCart(
      collectionStore: $collectionStore
      dropOffStore: $dropOffStore
      parcelValue: $parcelValue
      receiverEmail: $receiverEmail
      receiverMobileNumber: $receiverMobileNumber
      receiverName: $receiverName
      sessionId: $sessionId
    ) {
      message
      status
    }
  }
`;
export const deleteParcelCart = /* GraphQL */ `
  mutation DeleteParcelCart($parcelCartIdList: [String], $sessionId: String) {
    deleteParcelCart(
      parcelCartIdList: $parcelCartIdList
      sessionId: $sessionId
    ) {
      message
      status
    }
  }
`;
export const duplicateParcelCart = /* GraphQL */ `
  mutation DuplicateParcelCart(
    $parcelCartInput: [DuplicateParcelCartInput]
    $sessionId: String
  ) {
    duplicateParcelCart(
      parcelCartInput: $parcelCartInput
      sessionId: $sessionId
    ) {
      message
      status
    }
  }
`;
export const generateCsvS3UploadLink = /* GraphQL */ `
  mutation GenerateCsvS3UploadLink($fileName: String) {
    generateCsvS3UploadLink(fileName: $fileName)
  }
`;
export const generateSampleFileS3Link = /* GraphQL */ `
  mutation GenerateSampleFileS3Link {
    generateSampleFileS3Link
  }
`;
export const kdsUpdateFlyerStatus = /* GraphQL */ `
  mutation KdsUpdateFlyerStatus($orderNumber: String, $storeId: String) {
    kdsUpdateFlyerStatus(orderNumber: $orderNumber, storeId: $storeId) {
      message
      status
    }
  }
`;
export const placeCustomerOrderToSQS = /* GraphQL */ `
  mutation PlaceCustomerOrderToSQS(
    $dropOffStore: String
    $flyerBagInput: [FlyerBagInput]
    $orderDetail: [String]
    $paymentMethod: String
    $paymentType: String
    $promoCode: String
    $senderEmail: String
    $senderMobileNumber: String
    $senderName: String
    $sessionId: String
  ) {
    placeCustomerOrderToSQS(
      dropOffStore: $dropOffStore
      flyerBagInput: $flyerBagInput
      orderDetail: $orderDetail
      paymentMethod: $paymentMethod
      paymentType: $paymentType
      promoCode: $promoCode
      senderEmail: $senderEmail
      senderMobileNumber: $senderMobileNumber
      senderName: $senderName
      sessionId: $sessionId
    ) {
      message
      messageId
      status
    }
  }
`;
export const placeParcelReturnOrderToSQS = /* GraphQL */ `
  mutation PlaceParcelReturnOrderToSQS(
    $paymentMethod: String
    $paymentType: String
    $returnLocation: String
    $sessionId: String
    $trackerNumber: String
  ) {
    placeParcelReturnOrderToSQS(
      paymentMethod: $paymentMethod
      paymentType: $paymentType
      returnLocation: $returnLocation
      sessionId: $sessionId
      trackerNumber: $trackerNumber
    ) {
      message
      messageId
      status
    }
  }
`;
export const sendResetPasswordEmail = /* GraphQL */ `
  mutation SendResetPasswordEmail($email: String) {
    sendResetPasswordEmail(email: $email) {
      message
      status
    }
  }
`;
export const triggerDownloadReport = /* GraphQL */ `
  mutation TriggerDownloadReport($module: String, $parcelIdList: [String]) {
    triggerDownloadReport(module: $module, parcelIdList: $parcelIdList) {
      fileDownloadStatusId
      message
      statusCode
    }
  }
`;
export const updateBulkUploadParcel = /* GraphQL */ `
  mutation UpdateBulkUploadParcel(
    $parcelList: [BulkUploadParcelListInput]
    $sessionId: String
  ) {
    updateBulkUploadParcel(parcelList: $parcelList, sessionId: $sessionId) {
      message
      status
    }
  }
`;
export const updateParcelCart = /* GraphQL */ `
  mutation UpdateParcelCart(
    $collectionStore: String
    $dropOffStore: String
    $parcelCartId: String
    $parcelValue: Float
    $receiverEmail: String
    $receiverMobileNumber: String
    $receiverName: String
    $sessionId: String
  ) {
    updateParcelCart(
      collectionStore: $collectionStore
      dropOffStore: $dropOffStore
      parcelCartId: $parcelCartId
      parcelValue: $parcelValue
      receiverEmail: $receiverEmail
      receiverMobileNumber: $receiverMobileNumber
      receiverName: $receiverName
      sessionId: $sessionId
    ) {
      message
      status
    }
  }
`;
export const updateParcelStatus = /* GraphQL */ `
  mutation UpdateParcelStatus($status: String, $trackerNumber: String) {
    updateParcelStatus(status: $status, trackerNumber: $trackerNumber) {
      message
      status
    }
  }
`;
export const updateUserProfile = /* GraphQL */ `
  mutation UpdateUserProfile(
    $birthday: String
    $confirmPassword: String
    $email: String
    $firstName: String
    $gender: String
    $lastName: String
    $mobileNumber: String
    $nationality: String
    $password: String
    $race: String
    $token: String
  ) {
    updateUserProfile(
      birthday: $birthday
      confirmPassword: $confirmPassword
      email: $email
      firstName: $firstName
      gender: $gender
      lastName: $lastName
      mobileNumber: $mobileNumber
      nationality: $nationality
      password: $password
      race: $race
      token: $token
    ) {
      message
      status
    }
  }
`;
export const userForgotPassword = /* GraphQL */ `
  mutation UserForgotPassword(
    $confirmPassword: String
    $password: String
    $token: String
  ) {
    userForgotPassword(
      confirmPassword: $confirmPassword
      password: $password
      token: $token
    ) {
      message
      status
    }
  }
`;
export const userSignIn = /* GraphQL */ `
  mutation UserSignIn($email: String, $password: String) {
    userSignIn(email: $email, password: $password) {
      email
      isFMMember
      message
      mobileNumber
      name
      sessionId
      status
      token
    }
  }
`;
export const userSignUp = /* GraphQL */ `
  mutation UserSignUp(
    $birthday: String
    $confirmPassword: String
    $email: String
    $firstName: String
    $gender: String
    $lastName: String
    $mobileNumber: String
    $nationality: String
    $password: String
    $race: String
  ) {
    userSignUp(
      birthday: $birthday
      confirmPassword: $confirmPassword
      email: $email
      firstName: $firstName
      gender: $gender
      lastName: $lastName
      mobileNumber: $mobileNumber
      nationality: $nationality
      password: $password
      race: $race
    ) {
      message
      status
    }
  }
`;

import React, { useState, useEffect, useLayoutEffect, useCallback } from 'react'
import _ from 'lodash'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  Paper,
  TextField,
  InputAdornment,
  MenuItem,
  FormControl,
  Select,
  Chip,
  Grid,
  Button as Btn
} from '@material-ui/core'
import { Search } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core'
import { Button } from '../index'
import { formatter } from '@utils'
import './CustomTable.scss'
import { Notification, LoadingSpinner } from '@components'
import { MultiFilterDrawer } from '../index'

function useWindowSize() {
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}

function ShowWindowDimensions(onSearch) {
  const [width, height] = useWindowSize()
  if (onSearch == false) {
    return height - 270 + 60 + 'px'
  }
  return height - 235 + 'px'
}

const useStyles = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none'
    },
    '&&:after': {
      borderBottom: 'none'
    }
  }
})

const TableHeader = (props) => {
  const { order, orderBy, onRequestSort, headCells } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }
  return (
    <TableHead className="custom-table_head">
      <TableRow>
        {headCells.map((cols, idx) => (
          <TableCell key={idx} align="left" padding="default" sortDirection={orderBy === cols.key ? order : false}>
            {cols.key !== null ? (
              <TableSortLabel active={orderBy === cols.key} direction={order} onClick={createSortHandler(cols.key)}>
                {cols.title}
                {orderBy === cols.key ? <span></span> : null}
              </TableSortLabel>
            ) : (
              cols.title
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const CustomTable = (props) => {
  const classes = useStyles()
  const {
    rows,
    cols,
    multiFilter = false,
    clearSearch,
    searchText,
    drawerData,
    clearAllFilter,
    filterData,
    isOpen,
    setIsOpen,
    highlightId,
    highlightIdKey,
    onSearch = false,
    filter = false,
    filterBtn = false,
    state,
    link,
    total = false,
    add = false,
    maxRow = 50,
    linkQuery,
    download = false,
    fileName,
    back = false,
    goBack,
    upload,
    handleAddClick,
    handleUpload,
    refresh = false,
    onRefresh,
    selected,
    currentPage,
    disabledPagination = false,
    searchCategory,
    customStyle,
    onLink,
    data,
    isStampPromotion,
    isStampingPromotion,
    handleSelected,
    handlePopUp,
    point,
    isVoucher,
    isEdit,
    isView,
    isDisabled,
    onHandleVoucherCondition,
    handleAddCancel,
    cancel,
    checkedCounter,
    handleOnClick,
    popup,
    HidePagination,
    isSKU,
    onHandleSkuFilter,
    showAll = false,
    spinner = false,
    isTransitionLog = false
  } = props
  const [order, setOrder] = useState('asc')
  const [search, setSearch] = useState('')
  const [table, setTable] = useState([])
  const [prevToken, setPrevToken] = useState(null)
  const [orderBy, setOrderBy] = useState('serialno')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(maxRow)
  const [typingTimeout, setTypingTimeout] = useState(0)
  const [isRepeated, setIsRepeated] = useState(false)
  // const [clear, setClear] = useState(props.clear)
  useEffect(() => {
    setTable(rows)
  }, [props])

  useEffect(() => {
    if (props.currentPage == 0) {
      setPage(0)
    }
  }, [props.currentPage])

  useEffect(() => {
    if (props.clear == true) {
      setSearch('')
    }
  }, [props.clear])

  useEffect(() => {
    getCurrentCount()
  }, [total])

  const listTableCell = (row, ridx, customStyle = null) => {
    function checkCustomStyle(idx) {
      if (!customStyle) return ''
      if (customStyle['hasStyle']) {
        if (customStyle['styleChildIndex'].includes(idx)) {
          return customStyle['customStyleName']
        }
      }
      return ''
    }
    return cols.map((col, idx) => {
      return (
        <TableCell align={col.key == 'points' ? 'right' : 'left'} key={idx} className={checkCustomStyle(idx)}>
          {col.render(row, ridx)}
        </TableCell>
      )
    })
  }

  const handleRequestSort = (event, property) => {
    if (property == 'CheckBox') return
    const isDesc = orderBy === property && order === 'desc'
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
    if (props.fetchData) {
      props.fetchData(props.nextToken, rowsPerPage, page, property, isDesc ? 'asc' : 'desc')
    }
  }

  const handleChangePage = (event, newPage) => {
    if (!prevToken) {
      setPrevToken(props.nextToken)
    }
    if (props.fetchData) {
      props.fetchData(props.nextToken, rowsPerPage, newPage)
    }
    if (prevToken !== props.nextToken || typeof props.nextToken === 'number') {
      setPage(newPage)
      setPrevToken(props.nextToken)
    }
  }

  const handleChangeRowsPerPage = (event) => {
    if (!prevToken) {
      setPrevToken(props.nextToken)
    }
    if (props.fetchData) {
      props.fetchData(props.nextToken, rowsPerPage, 0)
    }
    if (prevToken !== props.nextToken) {
      setPage(0)
      setRowsPerPage(parseInt(event.target.value))
      setPrevToken(props.nextToken)
    }
  }

  const [modal, setModal] = useState({
    show: false,
    severity: ''
  })

  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }

  function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy)
  }

  function handleSearch(searchTxt, category) {
    setPage(0)
    onSearch(searchTxt, category, rowsPerPage ? rowsPerPage : rowsPerPage, 0)
  }

  const delayedHandleSearch = useCallback(
    _.debounce((searchTxt, category) => handleSearch(searchTxt, category), 750),
    []
  )
  const handleDelayedSearch = (event) => {
    let searchTxt = event.target.value
    if (searchTxt.length > 50) {
      setModal({
        ...modal,
        show: true,
        severity: 'error',
        message: 'Characters limit has been reached!'
      })
      return
    }
    setSearch(searchTxt)
    delayedHandleSearch(searchTxt, searchCategory)
  }

  const handleClearSearch = () => {
    clearSearch()
    setSearch('')
  }

  const handleClearSearchAndFilter = () => {
    clearSearch()
    setSearch('')
    clearAllFilter()
  }

  const checkHighlightedRow = (row) => {
    if (!highlightId || !highlightIdKey) {
      return null
    } else {
      return {
        backgroundColor: highlightId === row[highlightIdKey] ? '#bbdaed' : 'white'
      }
    }
  }
  function getCurrentCount() {
    if (props.hasNext != null) {
      if (props.hasNext) return -1
      else return page * rowsPerPage + rows.length
    } else if (props.nextToken) {
      if (total) return total
      else return -1
    } else {
      if (page > 0) return page * rowsPerPage + rows.length
      else return rows && rows.length > 0 ? rows.length : 0
    }
  }

  return (
    <div className="customtable-container" style={isStampingPromotion ? { paddingTop: '10px' } : { paddingTop: '0' }}>
      <div
        className={popup ? 'customtable-container_popup-search' : 'customtable-container_search'}
        // style={{ display: 'flex', marginBottom: 10, position: 'sticky', top: '8px', zIndex: 1000 }}
      >
        {isStampPromotion ? (
          <div style={{ width: '200px', marginLeft: '20px', marginRight: '20px' }}>
            <div>Filter Type</div>
            <FormControl style={{ width: '100%', marginTop: '10px' }}>
              <Select style={{ height: '30px' }} variant="outlined" onChange={(e) => handleSelected(e.target.value)}>
                <MenuItem value={'Item'}>Item</MenuItem>
                <MenuItem value={'Item Category'}>Item Category</MenuItem>
                <MenuItem value={'Product Group'}>Product Group</MenuItem>
                <MenuItem value={'All Items'}>All Items</MenuItem>
                <MenuItem value={'Product Tagging'}>Product Tagging</MenuItem>
              </Select>
            </FormControl>
          </div>
        ) : isVoucher ? (
          <div style={{ width: '250px', marginLeft: '20px', marginRight: '20px' }}>
            Product Condition
            <FormControl style={{ width: '100%', marginTop: '10px' }}>
              <Select
                variant="outlined"
                style={{ height: '30px' }}
                className="input__select"
                onChange={(e) => onHandleVoucherCondition(e.target.value)}
                displayEmpty
                disabled={isView && isEdit ? false : isDisabled}>
                <MenuItem value={'Department'}>Department</MenuItem>
                <MenuItem value={'Sub-department'}>Sub-department</MenuItem>
                <MenuItem value={'Product Tag'}>Product Tag</MenuItem>
                <MenuItem value={'SKU'}>SKU</MenuItem>
                <MenuItem value={'Division'}>Division</MenuItem>
                <MenuItem value={'Item Category'}>Item Category</MenuItem>
                <MenuItem value={'Product Group'}>Product Group</MenuItem>
                <MenuItem value={'All'}>All</MenuItem>
              </Select>
            </FormControl>
          </div>
        ) : null}
        {isSKU ? (
          <div style={{ width: '200px', marginLeft: '20px', marginRight: '20px' }}>
            SKU Filter
            <FormControl style={{ width: '100%', marginTop: '10px' }}>
              <Select
                variant="outlined"
                style={{ height: '30px' }}
                className="input__select"
                onChange={(e) => onHandleSkuFilter(e.target.value)}
                displayEmpty
                disabled={isView && isEdit ? false : isDisabled}>
                <MenuItem value={'Department'}>Department</MenuItem>
                <MenuItem value={'Sub-department'}>Sub-department</MenuItem>
                <MenuItem value={'Product Tag'}>Product Tag</MenuItem>
                <MenuItem value={'SKU'}>SKU</MenuItem>
                <MenuItem value={'Division'}>Division</MenuItem>
                <MenuItem value={'Item Category'}>Item Category</MenuItem>
                <MenuItem value={'Product Group'}>Product Group</MenuItem>
              </Select>
            </FormControl>
          </div>
        ) : null}
        <div className="search-container">
          <div className="search-container_search-box">
            {onSearch ? (
              <TextField
                // margin="normal"
                onChange={handleDelayedSearch}
                value={search}
                placeholder="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" style={{ paddingLeft: '20px' }}>
                      <Search />
                    </InputAdornment>
                  ),
                  style: {
                    borderRadius: 24,
                    border: '1px solid #EAEAEA',
                    backgroundColor: 'white',
                    marginTop: '15px'
                  },
                  classes
                }}
              />
            ) : null}
          </div>
          <Grid container justify="flex-end">
            {/* <div className="search-container_filter"> */}
            {filterBtn ? filterBtn() : null}

            {filter ? filter() : null}

            {/* </div> */}
          </Grid>
        </div>

        {/* {multiFilter ? (
          <div className="search-container-more-filter-button">
            <Button.PrimaryButton handleClick={() => handleCloseDrawer(true)} title="More Filter" />
          </div>
        ) : null} */}

        {
          /*dont delete this*/
          add && link && (
            <Button.PrimaryButton
              title="Add"
              style={{ marginTop: 30 }}
              handleClick={() => {
                if (linkQuery) {
                  state.history.push({ pathname: link, search: linkQuery })
                } else {
                  state.history.push({ pathname: link })
                }
              }}
            />
          )
        }

        {download && (
          <Button.PrimaryButton
            title="Download"
            handleClick={() => window.open(formatter.toS3Link(fileName), '_blank')}
          />
        )}
        {add && handleAddClick && (
          <Button.PrimaryButton
            title={selected > 0 ? 'Edit' : 'Add'}
            style={!checkedCounter ? { backgroundColor: 'rgb(0,0,0,0.1)', marginTop: 30 } : { marginTop: 30 }}
            disabled={!checkedCounter}
            handleClick={() => handleAddClick()}
          />
        )}
        {add && handleOnClick && (
          <Button.PrimaryButton2
            title={selected > 0 ? 'Edit' : 'Add'}
            style={!checkedCounter ? { backgroundColor: 'rgb(0,0,0,0.1)', marginTop: 30 } : { marginTop: 30 }}
            disabled={!checkedCounter}
            handleClick={() => handleOnClick()}
          />
        )}
        {back && <Button.SecondaryButton title="Cancel" handleClick={() => goBack()} />}
        {cancel && (
          <Button.CancelButton title="Cancel" style={{ marginTop: 30 }} handleClick={() => handleAddCancel()} />
        )}
        {upload && <Button.PrimaryButton title="Upload" handleClick={() => handleUpload()} />}
      </div>
      {multiFilter ? (
        <Grid style={{ paddingLeft: '20px', paddingBottom: '10px' }} container direction="row" spacing={1}>
          <>
            {drawerData.map((data, idx) => (
              <>
                {data.filterChip != '' && data.filterChip != null ? (
                  <Grid item key={idx}>
                    <Chip label={data.filterChip} onDelete={data.onClearClick} />
                  </Grid>
                ) : null}
              </>
            ))}
            {searchText != '' && searchText != null ? (
              <Grid item>
                <Chip label={searchText} onDelete={handleClearSearch} />
              </Grid>
            ) : null}
          </>
        </Grid>
      ) : null}
      {multiFilter ? (
        <div style={{ paddingLeft: '20px', paddingBottom: '10px' }}>
          {(searchText != '' && searchText != null) ||
          drawerData.filter((item) => {
            return item.filterChip != ''
          }).length > 0 ? (
            <Btn variant="contained" color="primary" size="small" onClick={() => handleClearSearchAndFilter()}>
              Clear all filter
            </Btn>
          ) : null}
        </div>
      ) : null}
      {multiFilter ? (
        <MultiFilterDrawer
          setPage={setPage}
          isOpen={isOpen}
          clearAllFilter={handleClearSearchAndFilter}
          filterData={filterData}
          drawerData={drawerData}
          setIsOpen={setIsOpen}
        />
      ) : null}

      <Paper elevation={3}>
        {/* <TableContainer className="custom-table"> */}
        <TableContainer
          style={{ maxHeight: ShowWindowDimensions(onSearch, add), overflow: isTransitionLog ? 'scroll' : null }}>
          {spinner ? <LoadingSpinner /> : null}
          <Table stickyHeader>
            <TableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={10}
              headCells={cols}
            />
            {table && table.length > 0 ? (
              <TableBody>
                {table.map((row, index) => {
                  return (
                    <TableRow
                      style={(checkHighlightedRow(row), { whiteSpace: isTransitionLog ? 'nowrap' : null })}
                      hover
                      key={index}>
                      {listTableCell(row, index, customStyle)}
                    </TableRow>
                  )
                })}
              </TableBody>
            ) : null}
          </Table>
        </TableContainer>
        {/* {props.nextToken ? <Button.PrimaryButton title='Load More' handleClick={handleLoadMore} className="customtable-load-more-btn"/> : null} */}
        {HidePagination ? null : (
          <TablePagination
            className={disabledPagination ? 'pagination' : null}
            rowsPerPageOptions={[]}
            component="div"
            count={total ? total : getCurrentCount()}
            rowsPerPage={showAll == true ? maxRow : rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </Paper>

      <Notification
        message={modal.message}
        onClose={() => setModal({ ...modal, show: false, severity: modal.severity })}
        show={modal.show}
        severity={modal.severity}
      />
    </div>
  )
}

export default CustomTable

import React, { useState, useEffect } from 'react'
import { Layout, SideNav, Button, Notification, CustomTable, LoadingSpinner, Timestamp } from '@components'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { Visibility } from '@material-ui/icons'
import { Tooltip, Grid, Button as Btn } from '@material-ui/core'
import Moment from 'react-moment'
import { validator } from '@utils'
import DateFnsUtils from '@date-io/date-fns'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import uuid from 'uuid'
import { API, graphqlOperation, Auth, Storage } from 'aws-amplify'
import { customSearchFlyerBagHistory,adminGetDownloadJobStatus } from '@graphql/queries'
import { adminTriggerFlyerDownloadReport } from '@graphql/mutations'
// import { adminTriggerFlyerDownloadReport, generateAdminPortalS3UploadLink } from '@graphql/mutations'
// import { onCreateUploadJob } from '@graphql/subscriptions'
import moment from 'moment'
import "./FlyerBagHistory.scss"

export default function FlyerBagHistory(props) {
  const [nextToken, setNextToken] = useState(null)
  const [focusedInput, setFocusedInput] = useState(null)
  const [total, setTotal] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isTimeOut, setIsTimeOut] = useState(true)
  const [voucher, setVoucher] = useState([])
  const [isDownloading, setIsDownloading] = useState(false)
  const [voucherDateFrom, setvoucherDateFrom] = useState(null)
  const [voucherDateTo, setvoucherDateTo] = useState(null)
  const [searchFilter, setSearchFilter] = useState(null)
  const [downloadUrl, setDownloadUrl] = useState('')
  const [keyword, setKeyword] = useState({})
  const [success, setSuccess] = useState(true)
  const [modal, setModal] = useState({
    show: false,
    severity: ''
  })

  const [currentPage, setCurrentPage] = useState(0)
  const maxRowPerPage = 50
  const defaultSort = { field: 'actionDate', direction: 'desc' }
  const [sortField, setSortField] = useState(defaultSort)

  const [tempData, setTempData] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [isactiveDate, setIsActiveDate] = useState(false)
  const [dateTextFilter, setDateTextFilter] = useState('')

  const [expiryFromDate, setExpiryFromDate] = useState(null)
  const [expiryToDate, setValidToDate] = useState(null)
  const [isExpiryActiveDate, setIsExpiryActiveDate] = useState(false)
  const [expirationDateTextFilter, setExpirationDateTextFilter] = useState('')

  const [refreshFilter, setRefreshFilter] = useState('')

  const [accountNoKeyword, setAccountNoKeyword] = useState('')
  const [entryNoKeyword, setEntryNoKeyword] = useState('')
  const [memberLedgerKeyword, setMemberLedgerKeyword] = useState('')
  const [receiptNoKeyword, setReceiptNoKeyword] = useState('')

  const [statusKeyword, setStatusKeyword] = useState('')
  const [voucherIdKeyword, setVoucherIdKeyword] = useState('')
  const [storeCodeKeyword, setStoreCodeKeyword] = useState('')
  const [posTerminalKeyword, setPosTerminalKeyword] = useState('')
  const [transactionNoKeyword, setTransactionNoKeyword] = useState('')
  const [netVoucherValueKeyword, setNetVoucherValueKeyword] = useState('')
  const [benefitTriggerTypeKeyword, setBenefitTriggerTypeKeyword] = useState('')
  const [navKeyword, setNavKeyword] = useState('')
  const [benefitTriggerUuidKeyword, setBenefitTriggerUuidKeyword] = useState('')
  const [flyerbagQtyKeyword, setFlyerbagQtyKeyword] = useState('')

  const [entryNoTextFilter, setEntryNoTextFilter] = useState('')
  const [accountNoTextFilter, setAccountNoTextFilter] = useState('')
  const [memberLedgerTextFilter, setMemberLedgerTextFilter] = useState('')
  const [receiptNoTextFilter, setReceiptNoTextFilter] = useState('')

  const [statusTextFilter, setStatusTextFilter] = useState('')
  const [voucherIdTextFilter, setVoucherIdTextFilter] = useState('')
  const [storeCodeTextFilter, setStoreCodeTextFilter] = useState('')
  const [posTerminalTextFilter, setPosTerminalTextFilter] = useState('')
  const [transactionNoTextFilter, setTransactionNoTextFilter] = useState('')
  const [netVoucherValueTextFilter, setNetVoucherValueTextFilter] = useState('')
  const [benefitTriggerTypeTextFilter, setBenefitTriggerTypeTextFilter] = useState('')
  const [navTextFilter, setNavTextFilter] = useState('')
  const [benefitTriggerUuidTextFilter, setBenefitTriggerUuidTextFilter] = useState('')
  const [flyerbagQtyTextFilter, setFlyerbagQtyTextFilter] = useState('')

  useEffect(() => {
    let username = null
    async function getUsername() {
      let currentUser = await Auth.currentAuthenticatedUser({ bypassCache: false })
      username = currentUser.username
    }

    fetchData()
    getUsername()

    // Subscribe to get update on Upload Job
    // const subscription = API.graphql(graphqlOperation(onCreateUploadJob)).subscribe({
    //   next: ({ provider, value }) => {
    //     const data = value.data.onCreateUploadJob
    //     console.log(data)
    //     console.log(username)
    //     if (data.requestor == username && data.module == 'cancelvoucher') {
    //       if (data.status == 'COMPLETED') {
    //         setModal({
    //           ...modal,
    //           show: true,
    //           severity: 'success',
    //           autoHide: 10000,
    //           message: `Upload job completed. ${data.recordsProcessed} records processed.`
    //         })
    //       } else if (data.status === 'FAILED') {
    //         setModal({
    //           ...modal,
    //           show: true,
    //           severity: 'error',
    //           autoHide: 10000,
    //           message: `Upload job failed. ${data.errorMessage}`
    //         })
    //       }
    //       setTimeout(() => {
    //         window.location.reload(false)
    //       }, 2000)
    //     }
    //   }
    // })

    // return () => {
    //   subscription.unsubscribe()
    // }
  }, [searchFilter, props])

  useEffect(() => {
    filterData()
  }, [refreshFilter])

  useEffect(() => {
    if (downloadUrl !== '') {
      window.open(downloadUrl, '_blank')
      setModal({
        ...modal,
        show: true,
        severity: 'success',
        autoHide: 1000,
        message: 'Voucher History report downloaded successfully. '
      })
    }
  }, [downloadUrl])

  async function fetchData(currentNextToken, limit = maxRowPerPage, page = 0, orderBy = null, orderDirection = null) {
    setIsLoading(true)

    try {
      let skipRow = parseInt(page) * parseInt(limit)
      let res = null
      let sort = sortField
      setCurrentPage(page)

      if (orderBy && orderDirection) {
        sort = { field: orderBy, direction: orderDirection }
        setSortField(sort)
      }

      if (!validator.isEmpty(searchFilter)) {
        res = await API.graphql(
          graphqlOperation(customSearchFlyerBagHistory, {
            filter: searchFilter,
            limit: limit,
            sort: sort,
            nextToken: skipRow
          })
        )
      } else {
        res = await API.graphql(
          graphqlOperation(customSearchFlyerBagHistory, {
            limit: limit,
            sort: sort,
            nextToken: skipRow
          })
        )
      }

      console.log(res)
      if (res.data.customSearchFlyerBagHistory) {
        let currentVoucherList = res.data.customSearchFlyerBagHistory.items
        setVoucher(currentVoucherList)
        setTotal(res.data.customSearchFlyerBagHistory.total)
        setNextToken(res.data.customSearchFlyerBagHistory.nextToken)
      } else {
        setVoucher([])
        setNextToken(null)
      }
      setIsTimeOut(false)
      setIsLoading(false)
    } catch (err) {
      console.log(err)
      setIsLoading(false)
      setIsTimeOut(true)
    }
  }

  const columns = [
    {
      key: null,
      title: 'No.',
      render: (row, ridx) => <div>{currentPage * maxRowPerPage + ridx + 1}.</div>
    },
    {
      key: 'actionDate',
      title: 'Date of Action',
      render: (row) => (
        <div>
          <Moment format="DD/MM/YYYY HH:mm:ss">{row.actionDate}</Moment>
        </div>
      )
    },
    {
      key: 'accountNo',
      title: 'Member ID',
      render: (row) => <div>{row.accountNo}</div>
    },
    {
      key: 'voucherCode',
      title: 'Voucher ID',
      render: (row) => <div>{row.voucherCode}</div>
    },
    {
      key: 'benefitTriggerType',
      title: 'Benefit Trigger Type',
      render: (row) => <div>{row.benefitTriggerType}</div>
    },
    {
      key: 'voucherStatus',
      title: 'Status',
      render: (row) => <div>{row.voucherStatus}</div>
    },
    {
      key: 'netVoucherValue',
      title: 'Net Voucher Value',
      render: (row) => <div>{row.netVoucherValue.toFixed(2)}</div>
    },
    {
      key: 'flyerBagQuantity',
      title: 'Quantity Flyer Bag',
      render: (row) => <div>{row.flyerBagQuantity}</div>
    },
    {
      key: 'orderNumber',
      title: 'Receipt Number',
      render: (row) => <div>{row.orderNumber}</div>
    },
    {
      key: 'txnId',
      title: 'Transaction Number',
      render: (row) => <div>{row.txnId}</div>
    },
    {
      key: 'memberLedgerEntryNo',
      title: 'Entry Number',
      render: (row) => <div>{row.detailedMemberLedgerEntryNo}</div>
    },
    {
      key: 'detailedMemberLedgerEntryNo',
      title: 'Member Ledger Entry Number',
      render: (row) => <div>{row.memberLedgerEntryNo}</div>
    },
    {
      key: 'expiryDate',
      title: 'Expiration Date',
      render: (row) => (
        <div>
          <Moment format="DD/MM/YYYY">{row.expiryDate}</Moment>
        </div>
      )
    },
    {
      key: 'storeCode',
      title: 'Store Code',
      render: (row) => <div>{row.storeCode}</div>
    },
    {
      key: 'posTerminal',
      title: 'POS Terminal',
      render: (row) => <div>{row.posTerminal}</div>
    },
    {
      key: 'isNav',
      title: 'NAV',
      render: (row) => <div>{row.isNav===true? "true": "false"}</div>
    },
    {
      key: 'benefitTriggerId',
      title: 'Benefit Trigger UUID',
      render: (row) => <div>{row.benefitTriggerId}</div>
    }
    // {
    //   key: 'schemeGroup',
    //   title: 'Scheme Group',
    //   render: (row) => <div>{row.schemeGroup}</div>
    // },
  ]

  const handleView = (item) => {
    props.history.push({
      pathname: `/order/${item.voucherCode}`,
      state: { item }
    })
  }

  const elasticSearch = async (item) => {
    let currentSearchFilter = {}
    let keywordToSearch = ''

    if (item) {
      keywordToSearch = item
    }

    if (keywordToSearch && keywordToSearch.length > 0) {
      currentSearchFilter = { and: [] }

      let splitKeywords = keywordToSearch.toLowerCase().split(' ')
      splitKeywords.map((keyword) => {
        currentSearchFilter['and'].push({
          voucherCode: { regexp: '.*' + keyword.toLowerCase() + '.*' }
        })
      })
    }
    setSearchFilter(currentSearchFilter)
  }

  const handleDateFromChange = (date) => {
    setvoucherDateFrom(date)
  }

  const handleDateToChange = (date) => {
    setvoucherDateTo(date)
  }

  const handleClear = () => {
    setvoucherDateFrom(null)
    setvoucherDateTo(null)
    if (searchFilter !== '') {
      if (searchFilter.or) {
        setSearchFilter({ or: searchFilter.or })
      } else {
        setSearchFilter()
      }
    }
  }

  const download = async () => {
    setIsDownloading(true)
    let numberOfMonths = moment(toDate).diff(moment(fromDate), 'months', false) + 1
    console.log(numberOfMonths)

    if (searchFilter == null) {
      setModal({
        ...modal,
        show: true,
        severity: 'error',
        message: 'Please try again with the start date and end date within ONE month duration'
      })
      setIsDownloading(false)
    } else if (
      numberOfMonths <= 1 ||
      accountNoTextFilter !== '' ||
      receiptNoTextFilter !== '' ||
      entryNoTextFilter !== '' ||
      memberLedgerTextFilter !== '' ||
      transactionNoTextFilter !== '' ||
      voucherIdTextFilter !== ''
    ) {
      try {
        let res = await API.graphql(
          graphqlOperation(adminTriggerFlyerDownloadReport, {
            field: searchFilter,
            sort: sortField
          })
        )

        if (res) {
          const interval = setInterval(async () => {
            let res2 = await API.graphql(
              graphqlOperation(adminGetDownloadJobStatus, {
                fileDownloadStatusId: res.data.adminTriggerFlyerDownloadReport.fileDownloadStatusId
              })
            )
            // console.log(res2)
            // console.log('lopping')
            if (res2.data.adminGetDownloadJobStatus.status == 'FAILED') {
              clearInterval(interval)
              // console.log('exitloop')
              setModal({
                ...modal,
                show: true,
                severity: 'error',
                autoHide: 1000,
                message: res2.data.adminGetDownloadJobStatus.message
              })
              setIsDownloading(false)
            }
            if (res2.data.adminGetDownloadJobStatus.status == 'COMPLETED') {
              clearInterval(interval)
              // console.log('exitloop')
              setDownloadUrl(res2.data.adminGetDownloadJobStatus.objectPresignedUrl)
              setIsDownloading(false)
            }
          }, 2000)
        }
      } catch (error) {
        setIsDownloading(false)
        console.log(error)
      }
    } else {
      setModal({
        ...modal,
        show: true,
        severity: 'error',
        message: 'Please try again with the start date and end date within ONE month duration'
      })
      setIsDownloading(false)
    }
  }

  const openFileSelect = () => {
    document.getElementById('fileSelect').click()
  }

  const filterOption = () => {
    return (
      <div className="search-container_filter" style={{ marginTop: 10 }}>
        <Grid container justify="flex-end" style={{ width: '95%' }}>
          {/* <Grid item>
            <div className="search-button-container">
              <div style={{ marginRight: '5px' }}>
                <Button.IconDownloadButton
                  title="DOWNLOAD CSV"
                  handleClick={download}
                  inProgress={isDownloading}
                  disabled={total == 0 || total == null}
                />
              </div>
            </div>
          </Grid> */}
          <Grid item>
            <div className="search-button-container">
              <div style={{ marginRight: '10px', marginTop: '1px' }}>
                {!isLoading ? (
                  <Btn
                    variant="contained"
                    color="primary"
                    size="small"
                    className="more-filter-btn"
                    onClick={() => setIsOpen(true)}>
                    MORE FILTER
                  </Btn>
                ) : (
                  <Btn
                    variant="contained"
                    color="primary"
                    size="small"
                    className="more-filter-btn"
                    disabled
                    onClick={() => setIsOpen(true)}>
                    MORE FILTER
                  </Btn>
                )}
              </div>
              <div style={{ marginRight: '10px' }}>
                <Button.DownloadCsvButton
                  title="DOWNLOAD CSV"
                  handleClick={download}
                  inProgress={isDownloading}
                  disabled={total == 0 || total == null}
                  // disabled={true}
                />
              </div>
              {/* <div style={{ marginRight: '10px' }}>
                <Button.UploadButtonWithIcon
                  title="UPLOAD CANCEL VOUCHER"
                  handleClick={openFileSelect}
                  disabled={isDownloading}
                />
                <input
                  id="fileSelect"
                  type="file"
                  accept=".csv"
                  onChange={(e) => {
                    performImportCSV(e.target.files[0])
                  }}
                  hidden
                />
              </div> */}
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
  const uploadCsvToS3 = (signedUrl, file) => {
    let form = new FormData()
    const newData = JSON.parse(signedUrl)
    Object.keys(newData.fields).forEach((key) => form.append(key, newData.fields[key]))
    form.append('file', file)
    return fetch(newData.url, { method: 'POST', body: form })
  }

  const performImportCSV = async (file) => {
    // console.log('file', file)
    // if (!file.name.endsWith('.csv')) {
    //   setModal({
    //     ...modal,
    //     show: true,
    //     severity: 'error',
    //     message: 'Invalid File Format. Only CSV File Format Is Accepted.'
    //   })
    //   return
    // }
    // let currentUser = await Auth.currentAuthenticatedUser({ bypassCache: false })
    // setModal({
    //   ...modal,
    //   show: true,
    //   severity: 'info',
    //   message: 'File is uploading...'
    // })
    // try {
    //   const res = await API.graphql(
    //     graphqlOperation(generateAdminPortalS3UploadLink, {
    //       fileName: `public/csvUpload/cancelvoucher/${currentUser.username}/${uuid.v4()}.csv`
    //     })
    //   )
    //   console.log(res)
    //   let upload

    //   upload = await uploadCsvToS3(res.data.generateAdminPortalS3UploadLink, file)
    //   // await Storage.put(`csvUpload/cancelvoucher/${currentUser.username}/${uuid.v4()}.csv`, file, {
    //   //   contentType: 'text/csv',
    //   //   metadata: {
    //   //     modifiedby: currentUser.username
    //   //   }
    //   // })
    //   if (upload.status == 204) {
    //     setModal({
    //       ...modal,
    //       show: true,
    //       severity: 'success',
    //       autoHide: 30000,
    //       message: 'File uploaded successfully.'
    //     })
    //     // window.location.reload(false)
    //     setSuccess(true)
    //   }
    // } catch (e) {
    //   console.log('error', e)
    //   setModal({
    //     ...modal,
    //     show: true,
    //     severity: 'error',
    //     message: 'Failed to upload file.'
    //   })
    // }
  }
  // insert end date value
  const handleFromDateValue = (value) => {
    setIsActiveDate(true)
    setFromDate(value)
    // setIsFilter(true)
    if (toDate != null) {
      let textFilter = `Date of Action range between ${moment(value).format('DD/MM/YYYY')} and ${moment(toDate).format(
        'DD/MM/YYYY'
      )}`
      setDateTextFilter(textFilter)
    }
  }

  // insert start Date value
  const handleToDateValue = (value) => {
    setIsActiveDate(true)
    setToDate(value)
    // setIsFilter(true)
    if (fromDate == null) {
      let textFilter = `Date of Action range between ${moment(value).format('DD/MM/YYYY')} and ${moment(value).format(
        'DD/MM/YYYY'
      )}`
      setDateTextFilter(textFilter)
    } else {
      let textFilter = `Date of Action range between ${moment(fromDate).format('DD/MM/YYYY')} and ${moment(
        value
      ).format('DD/MM/YYYY')}`
      setDateTextFilter(textFilter)
    }
  }

  // insert end date value
  const handleExpiryFromDateValue = (value) => {
    setIsExpiryActiveDate(true)
    setExpiryFromDate(value)
    // setIsFilter(true)
    if (expiryToDate != null) {
      let textFilter = `Expiration Date range between ${moment(value).format('DD/MM/YYYY')} and ${moment(
        expiryToDate
      ).format('DD/MM/YYYY')}`
      setExpirationDateTextFilter(textFilter)
    }
  }

  // insert start Date value
  const handleExpiryToDateValue = (value) => {
    setIsExpiryActiveDate(true)
    setValidToDate(value)
    // setIsFilter(true)
    if (expiryFromDate == null) {
      let textFilter = `Expiration Date range between ${moment(value).format('DD/MM/YYYY')} and ${moment(value).format(
        'DD/MM/YYYY'
      )}`
      setExpirationDateTextFilter(textFilter)
    } else {
      let textFilter = `Expiration Date range between ${moment(expiryFromDate).format('DD/MM/YYYY')} and ${moment(
        value
      ).format('DD/MM/YYYY')}`
      setExpirationDateTextFilter(textFilter)
    }
  }

  const handleEntryNoValue = (value) => {
    setEntryNoKeyword(value)
    if (value != '' && value != null) {
      setEntryNoTextFilter(`Entry Number includes ${value}`)
      // setIsFilter(true)
    } else {
      setEntryNoTextFilter('')
    }
  }
  const handleAccountNoValue = (value) => {
    setAccountNoKeyword(value)
    if (value != '' && value != null) {
      setAccountNoTextFilter(`Member ID includes ${value}`)
      // setIsFilter(true)
    } else {
      setAccountNoTextFilter('')
    }
  }
  const handleMemberLedgerValue = (value) => {
    setMemberLedgerKeyword(value)
    if (value != '' && value != null) {
      setMemberLedgerTextFilter(`Member Ledger Entry No includes ${value}`)
      // setIsFilter(true)
    } else {
      setMemberLedgerTextFilter('')
    }
  }
  const handleReceiptNoValue = (value) => {
    setReceiptNoKeyword(value)
    if (value != '' && value != null) {
      setReceiptNoTextFilter(`Receipt No includes ${value}`)
      // setIsFilter(true)
    } else {
      setReceiptNoTextFilter('')
    }
  }
  const handleStatusValue = (value) => {
    setStatusKeyword(value)
    if (value != '' && value != null) {
      setStatusTextFilter(`Status includes ${value}`)
      // setIsFilter(true)
    } else {
      setStatusTextFilter('')
    }
  }
  const handleNetVoucherValue = (value) => {
    setNetVoucherValueKeyword(value)
    if (value != '' && value != null) {
      setNetVoucherValueTextFilter(`Net Voucher Value equal to ${value}`)
      // setIsFilter(true)
    } else {
      setNetVoucherValueTextFilter('')
    }
  }
  const handleVoucherIdValue = (value) => {
    setVoucherIdKeyword(value)
    if (value != '' && value != null) {
      setVoucherIdTextFilter(`Voucher ID includes ${value}`)
      // setIsFilter(true)
    } else {
      setVoucherIdTextFilter('')
    }
  }
  const handleBenefitTriggerTypeValue = (value) => {
    setBenefitTriggerTypeKeyword(value)
    if (value != '' && value != null) {
      setBenefitTriggerTypeTextFilter(`Benefit Trigger Type includes ${value}`)
      // setIsFilter(true)
    } else {
      setBenefitTriggerTypeTextFilter('')
    }
  }

  const handleStoreCodeValue = (value) => {
    setStoreCodeKeyword(value)
    if (value != '' && value != null) {
      setStoreCodeTextFilter(`Store Code includes ${value}`)
      // setIsFilter(true)
    } else {
      setStoreCodeTextFilter('')
    }
  }
  const handlePosTerminalValue = (value) => {
    setPosTerminalKeyword(value)
    if (value != '' && value != null) {
      setPosTerminalTextFilter(`POS Terminal includes ${value}`)
      // setIsFilter(true)
    } else {
      setPosTerminalTextFilter('')
    }
  }
  const handleNavValue = (value) => {
    setNavKeyword(value)
    if (value != '' && value != null) {
      setNavTextFilter(`NAV includes ${value}`)
      // setIsFilter(true)
    } else {
      setNavTextFilter('')
    }
  }
  const handleBenefitTriggerUuid = (value) => {
    setBenefitTriggerUuidKeyword(value)
    if (value != '' && value != null) {
      setBenefitTriggerUuidTextFilter(`Benefit Trigger includes UUID to ${value}`)
      // setIsFilter(true)
    } else {
      setBenefitTriggerUuidTextFilter('')
    }
  }
  const handleTransactionNoValue = (value) => {
    setTransactionNoKeyword(value)
    if (value != '' && value != null) {
      setTransactionNoTextFilter(`Transaction No includes ${value}`)
      // setIsFilter(true)
    } else {
      setTransactionNoTextFilter('')
    }
  }
  const handleFlyerQuantityValue = (value) => {
    setFlyerbagQtyKeyword(value)
    if (value != '' && value != null) {
      setFlyerbagQtyTextFilter(`Flyer Bag Qty equal to ${value}`)
      // setIsFilter(true)
    } else {
      setFlyerbagQtyTextFilter('')
    }
  }

  // Clear Date input
  const handleClearDate = () => {
    setToDate(null)
    setFromDate(null)
    setIsActiveDate(false)
    //  checkIsDataFiltered()
    setDateTextFilter('')
    // setIsClear(true)
    setRefreshFilter('updatedAt')
  }
  // Clear Date input
  const handleExpiryClearDate = () => {
    setValidToDate(null)
    setExpiryFromDate(null)
    setIsExpiryActiveDate(false)
    //  checkIsDataFiltered()
    setExpirationDateTextFilter('')
    // setIsClear(true)
    setRefreshFilter('expiryDate')
  }
  // const handleEndClearDate = () => {
  //   setEndToDate(null)
  //   setEndFromDate(null)
  //   setIsEndActiveDate(false)
  //   //  checkIsDataFiltered()
  //   setEndDateTextFilter('')
  //   // setIsClear(true)
  //   setRefreshFilter('endDate')
  // }
  const clearAllFilter = () => {
    setIsOpen(false)
    //setStoreProduct(tempData)
    setTotal(tempData.length)
    // // setIsFilter(false)

    setMemberLedgerKeyword('')
    setEntryNoKeyword('')
    setAccountNoKeyword('')

    setReceiptNoKeyword('')
    setStatusKeyword('')
    setVoucherIdKeyword('')
    setStoreCodeKeyword('')
    setPosTerminalKeyword('')
    setTransactionNoKeyword('')
    setNetVoucherValueKeyword('')
    setBenefitTriggerTypeKeyword('')
    setNavKeyword('')
    setBenefitTriggerUuidKeyword('')
    setFlyerbagQtyKeyword('')

    // handleEndClearDate()
    handleExpiryClearDate()
    handleClearDate()
    setMemberLedgerTextFilter('')
    setEntryNoTextFilter('')
    setAccountNoTextFilter('')
    setStatusTextFilter('')
    setPosTerminalTextFilter('')

    setReceiptNoTextFilter('')
    setVoucherIdTextFilter('')
    setStoreCodeTextFilter('')
    setTransactionNoTextFilter('')
    setNetVoucherValueTextFilter('')
    setBenefitTriggerTypeTextFilter('')
    setNavTextFilter('')
    setBenefitTriggerUuidTextFilter('')
    setFlyerbagQtyTextFilter('')

    setRefreshFilter('')
  }
  const clearSearchFilter = () => {
    // setSearchTextFilter('')
    setSearchFilter('')
  }

  // Filter all the data when click done
  const filterData = () => {
    // setIsLoading(true)
    setIsOpen(false)
    // setIsFilter(false)

    let filteredData = [...tempData]

    let currentSearchFilter = { and: [] }

    let filterCount = 0

    if (fromDate == null) {
    } else if (toDate == null) {
      setModal({ ...modal, show: true, severity: 'error', message: 'End Date is required to filter' })
    } else if (fromDate > toDate) {
      setModal({ ...modal, show: true, severity: 'error', message: 'Start Date must be later than End Date' })
    } else {
      // Filter Date
      let gte = moment(fromDate).startOf('days')
      let lte = moment(toDate).endOf('days')
      currentSearchFilter['and'].push({ actionDate: { gte: gte } })
      currentSearchFilter['and'].push({ actionDate: { lte: lte } })
      // setIsFilter(true)
      filterCount = filterCount + 1
    }

    if (expiryFromDate == null) {
    } else if (expiryToDate == null) {
      setModal({ ...modal, show: true, severity: 'error', message: 'End Date is required to filter' })
    } else if (expiryFromDate > expiryToDate) {
      setModal({ ...modal, show: true, severity: 'error', message: 'Start Date must be later than End Date' })
    } else {
      // Filter Date
      let gte = moment(expiryFromDate).startOf('days')
      let lte = moment(expiryToDate).endOf('days')
      currentSearchFilter['and'].push({ expiryDate: { gte: gte } })
      currentSearchFilter['and'].push({ expiryDate: { lte: lte } })
      // setIsFilter(true)
      filterCount = filterCount + 1
    }

    if (accountNoKeyword != '') {
      // setIsFilter(true)
      filterCount = filterCount + 1
      currentSearchFilter['and'].push({ accountNo: { regexp: '.*' + accountNoKeyword.toLowerCase() + '.*' } })
    }
    if (entryNoKeyword != '') {
      // setIsFilter(true)
      filterCount = filterCount + 1
      currentSearchFilter['and'].push({ detailedMemberLedgerEntryNo: { regexp: '.*' + entryNoKeyword.toLowerCase() + '.*' } })
    }

    if (memberLedgerKeyword != '') {
      // setIsFilter(true)
      filterCount = filterCount + 1
      currentSearchFilter['and'].push({
        memberLedgerEntryNo: { regexp: '.*' + memberLedgerKeyword.toLowerCase() + '.*' }
      })
    }
    if (receiptNoKeyword != '') {
      // setIsFilter(true)
      filterCount = filterCount + 1
      currentSearchFilter['and'].push({
        orderNumber: { regexp: '.*' + receiptNoKeyword.toLowerCase() + '.*' }
      })
    }
    if (statusKeyword != '') {
      // setIsFilter(true)
      filterCount = filterCount + 1
      // currentSearchFilter['and'].push({
      //   voucherStatus: { regexp: '.*' + statusKeyword.toLowerCase()  + '.*'}
      // })
      let splitKeywords = statusKeyword.toLowerCase().split(' ')
      splitKeywords.map((keyword) => {
        currentSearchFilter['and'].push({
          voucherStatus: { regexp: '.*' + keyword.toLowerCase() + '.*' }
        })
      })
    }
    if (netVoucherValueKeyword != '') {
      // setIsFilter(true)
      filterCount = filterCount + 1
      currentSearchFilter['and'].push({
        netVoucherValue: { eq: netVoucherValueKeyword }
      })
    }
    if (voucherIdKeyword != '') {
      // setIsFilter(true)
      filterCount = filterCount + 1
      currentSearchFilter['and'].push({
        voucherCode: { regexp: '.*' + voucherIdKeyword.toLowerCase() + '.*' }
      })
    }
    if (benefitTriggerTypeKeyword != '') {
      // setIsFilter(true)
      filterCount = filterCount + 1
      currentSearchFilter['and'].push({
        benefitTriggerType: { regexp: '.*' + benefitTriggerTypeKeyword.toLowerCase() + '.*' }
      })
    }
    if (storeCodeKeyword != '') {
      // setIsFilter(true)
      filterCount = filterCount + 1
      currentSearchFilter['and'].push({
        storeCode: { regexp: '.*' + storeCodeKeyword.toLowerCase() + '.*' }
      })
    }
    if (posTerminalKeyword != '') {
      // setIsFilter(true)
      filterCount = filterCount + 1
      currentSearchFilter['and'].push({
        posTerminal: { regexp: '.*' + posTerminalKeyword.toLowerCase() + '.*' }
      })
    }
    if (navKeyword != '') {
      // setIsFilter(true)
      filterCount = filterCount + 1
      currentSearchFilter['and'].push({
        isNav: { eq: navKeyword.toLowerCase()}
      })
    }
    if (benefitTriggerUuidKeyword != '') {
      // setIsFilter(true)
      filterCount = filterCount + 1
      currentSearchFilter['and'].push({
        benefitTriggerId: { regexp: '.*' + benefitTriggerUuidKeyword.toLowerCase() + '.*' }
      })
    }
    if (transactionNoKeyword != '') {
      // setIsFilter(true)
      filterCount = filterCount + 1
      currentSearchFilter['and'].push({
        txnId: { regexp: '.*' + transactionNoKeyword.toLowerCase() + '.*' }
      })
    }
    if (flyerbagQtyKeyword != '') {
      // setIsFilter(true)
      filterCount = filterCount + 1
      currentSearchFilter['and'].push({
        flyerBagQuantity: { eq: flyerbagQtyKeyword.toLowerCase() }
      })
    }

    if (filterCount > 0) {
      setSearchFilter(currentSearchFilter)
    } else {
      setSearchFilter(null)
    }

    setTotal(filteredData.length)
  }

  const drawerData = [
    {
      type: 'Date',
      label: 'Date of Action',
      fromDate: fromDate,
      setFromDate: handleFromDateValue,
      toDate: toDate,
      setToDate: handleToDateValue,
      onClearClick: handleClearDate,
      isActive: isactiveDate,
      filterChip: dateTextFilter
    },
    {
      type: 'TextField',
      label: 'Member ID',
      placeholder: 'please insert member id',
      setValue: handleAccountNoValue,
      value: accountNoKeyword,
      onClearClick: () => {
        setAccountNoKeyword('')
        //checkIsDataFiltered()
        setAccountNoTextFilter('')
        // setIsClear(true)
        setRefreshFilter('accountNo')
        //filterData()
      },
      filterChip: accountNoTextFilter
    },
    {
      type: 'TextField',
      label: 'Voucher ID',
      placeholder: 'please insert voucher id',
      setValue: handleVoucherIdValue,
      value: voucherIdKeyword,
      onClearClick: () => {
        setVoucherIdKeyword('')
        // checkIsDataFiltered()
        setVoucherIdTextFilter('')
        // setIsClear(true)
        setRefreshFilter('voucherCode')
      },
      filterChip: voucherIdTextFilter
    },
    {
      type: 'TextField',
      label: 'Benefit Trigger Type',
      placeholder: 'please insert benefit trigger type',
      setValue: handleBenefitTriggerTypeValue,
      value: benefitTriggerTypeKeyword,
      onClearClick: () => {
        setBenefitTriggerTypeKeyword('')
        // checkIsDataFiltered()
        setBenefitTriggerTypeTextFilter('')
        // setIsClear(true)
        setRefreshFilter('benefitTriggerType')
      },
      filterChip: benefitTriggerTypeTextFilter
    },
    {
      type: 'TextField',
      label: 'Status',
      placeholder: 'please insert status',
      setValue: handleStatusValue,
      value: statusKeyword,
      onClearClick: () => {
        setStatusKeyword('')
        // checkIsDataFiltered()
        setStatusTextFilter('')
        // setIsClear(true)
        setRefreshFilter('voucherStatus')
      },
      filterChip: statusTextFilter
    },
    {
      type: 'TextField',
      label: 'Net Voucher Value',
      placeholder: 'please insert net voucher value',
      setValue: handleNetVoucherValue,
      value: netVoucherValueKeyword,
      onClearClick: () => {
        setNetVoucherValueKeyword('')
        // checkIsDataFiltered()
        setNetVoucherValueTextFilter('')
        // setIsClear(true)
        setRefreshFilter('netVoucherValue')
      },
      filterChip: netVoucherValueTextFilter
    },
    {
      type: 'TextField',
      label: 'Flyer Bag Quantity',
      placeholder: 'please insert flyer bag qty',
      setValue: handleFlyerQuantityValue,
      value: flyerbagQtyKeyword,
      onClearClick: () => {
        setFlyerbagQtyKeyword('')
        // checkIsDataFiltered()
        setFlyerbagQtyTextFilter('')
        // setIsClear(true)
        setRefreshFilter('flyerBagQuantity')
      },
      filterChip: flyerbagQtyTextFilter
    },
    {
      type: 'TextField',
      label: 'Receipt Number',
      placeholder: 'please insert receipt no',
      setValue: handleReceiptNoValue,
      value: receiptNoKeyword,
      onClearClick: () => {
        setReceiptNoKeyword('')
        //   checkIsDataFiltered()
        setReceiptNoTextFilter('')
        // setIsClear(true)
        setRefreshFilter('orderNumber')
      },
      filterChip: receiptNoTextFilter
    },
    {
      type: 'TextField',
      label: 'Transaction Number',
      placeholder: 'please insert transaction no',
      setValue: handleTransactionNoValue,
      value: transactionNoKeyword,
      onClearClick: () => {
        setTransactionNoKeyword('')
        // checkIsDataFiltered()
        setTransactionNoTextFilter('')
        // setIsClear(true)
        setRefreshFilter('txnId')
      },
      filterChip: transactionNoTextFilter
    },
    {
      type: 'TextField',
      label: 'Entry Number',
      placeholder: 'please insert entry no',
      setValue: handleEntryNoValue,
      value: entryNoKeyword,
      onClearClick: () => {
        setEntryNoKeyword('')
        //checkIsDataFiltered()
        setEntryNoTextFilter('')
        // setIsClear(true)
        setRefreshFilter('memberLedgerEntryNo')
      },
      filterChip: entryNoTextFilter
    },
    {
      type: 'TextField',
      label: 'Member Ledger Entry Number',
      placeholder: 'please insert member ledger entry no',
      setValue: handleMemberLedgerValue,
      value: memberLedgerKeyword,
      onClearClick: () => {
        setMemberLedgerKeyword('')
        //checkIsDataFiltered()
        setMemberLedgerTextFilter('')
        // setIsClear(true)
        setRefreshFilter('detailedMemberLedgerEntryNo')
      },
      filterChip: memberLedgerTextFilter
    },
    {
      type: 'Date',
      label: 'Expiration Date',
      fromDate: expiryFromDate,
      setFromDate: handleExpiryFromDateValue,
      toDate: expiryToDate,
      setToDate: handleExpiryToDateValue,
      onClearClick: handleExpiryClearDate,
      isActive: isExpiryActiveDate,
      filterChip: expirationDateTextFilter
    },
    {
      type: 'TextField',
      label: 'Store Code',
      placeholder: 'please insert store code',
      setValue: handleStoreCodeValue,
      value: storeCodeKeyword,
      onClearClick: () => {
        setStoreCodeKeyword('')
        //  checkIsDataFiltered()
        setStoreCodeTextFilter('')
        // setIsClear(true)
        setRefreshFilter('storeCode')
      },
      filterChip: storeCodeTextFilter
    },
    {
      type: 'TextField',
      label: 'POS Terminal',
      placeholder: 'please insert pos terminal',
      setValue: handlePosTerminalValue,
      value: posTerminalKeyword,
      onClearClick: () => {
        setPosTerminalKeyword('')
        // checkIsDataFiltered()
        setPosTerminalTextFilter('')
        // setIsClear(true)
        setRefreshFilter('posTerminal')
      },
      filterChip: posTerminalTextFilter
    },
    {
      type: 'TextField',
      label: 'NAV',
      placeholder: 'please insert nav',
      setValue: handleNavValue,
      value: navKeyword,
      onClearClick: () => {
        setNavKeyword('')
        // checkIsDataFiltered()
        setNavTextFilter('')
        // setIsClear(true)
        setRefreshFilter('isNav')
      },
      filterChip: navTextFilter
    },
    {
      type: 'TextField',
      label: 'Benefit Trigger UUID',
      placeholder: 'please insert benefit trigger uuid',
      setValue: handleBenefitTriggerUuid,
      value: benefitTriggerUuidKeyword,
      onClearClick: () => {
        setBenefitTriggerUuidKeyword('')
        // checkIsDataFiltered()
        setBenefitTriggerUuidTextFilter('')
        // setIsClear(true)
        setRefreshFilter('benefitTriggerUuid')
      },
      filterChip: benefitTriggerUuidTextFilter
    }
  ]
  console.log(searchFilter)

  return (
    <Layout.Body>
      <Layout.LeftPanel>
        <SideNav {...props} />
      </Layout.LeftPanel>
      <Layout.Content
        title="Flyer Bag History"
        description="Display and manage all flyer bag history."
        total={total}
        state={props}>
        {isLoading ? (
          <LoadingSpinner />
        ) : isTimeOut ? (
          <div className="button-container">
            <Button.PrimaryButton className="refresh-button" title="retry" handleClick={() => fetchData()} />
          </div>
        ) : null}
        {!isTimeOut ? (
          <div className="flyer-bag-history-container">
            <CustomTable
              multiFilter={true}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              drawerData={drawerData}
              clearAllFilter={clearAllFilter}
              clearSearch={clearSearchFilter}
              filterData={filterData}
              total={total}
              maxRow={maxRowPerPage}
              rows={voucher}
              cols={columns}
              onSearch={elasticSearch}
              state={props}
              nextToken={nextToken}
              fetchData={fetchData}
              filter={filterOption}
              disabledPagination={isLoading}
              spinner={isLoading}
            />
          </div>
        ) : null}
      </Layout.Content>
      <Notification
        message={modal.message}
        onClose={() => {
          setModal({ ...modal, show: false, severity: modal.severity })
          if (modal.component) setFocusedInput(modal.component)
        }}
        show={modal.show}
        severity={modal.severity}
      />
    </Layout.Body>
  )
}

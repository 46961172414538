import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import { Typography, InputBase, Paper, Button, Tooltip } from '@material-ui/core'
import { validator } from '@utils'

export default function MFAFields(props) {
  const { user, mfaType, handleCancel } = props
  const [code, setCode] = useState('')
  const [error, setError] = useState({})

  const handleMFACode = async (value) => {
    setCode(value)
    setError({})
    if (value.length >= 6) {
      try {
        const loggedUser = await Auth.confirmSignIn(user, value, mfaType)
        window.location = `/`
      } catch (e) {
        setCode('')
        console.log(e)
        setError(e)
      }
    }
  }
  // const handleCancel = async () => {
  //   await Auth.signOut()
  //   window.location = '/'
  // }
  return (
    <>
      <Typography color="textSecondary" gutterBottom>
        <small>
          Enter verification code from Google Autheticator.
          <br />
          <Tooltip
            title={
              <>
                Possible solutions:
                <ul>
                  <li>Make sure you entered the correct code</li>
                  <li>
                    In Google Autheticator, go to Top Right Menu(Three Dot) &gt; Settings &gt; Time Correction for Code
                    &gt; Sync now
                  </li>
                </ul>
              </>
            }>
            <a href="#">Can't verify code?</a>
          </Tooltip>
        </small>
      </Typography>
      <div className="login-form-content">
        {!validator.isEmpty(error) && code.length < 1 ? (
          <div className="login-alert-body">
            <div className="login-alert-box">{error.message}</div>
          </div>
        ) : null}
        <Paper component="form" className="login_paper">
          <InputBase
            className="login_input"
            placeholder="Verification Code"
            value={code}
            disabled={code.length >= 6}
            onChange={(e) => handleMFACode(e.target.value)}
            autoFocus
          />
        </Paper>
        <div className="login_button_container">
          <Button variant="contained" className="login_button" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </div>
    </>
  )
}

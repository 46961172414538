import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Button, Tooltip } from '@material-ui/core'
import './Button.scss'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/Clear'
import GetAppIcon from '@material-ui/icons/GetApp'
import AddIcon from '../../assets/svgs/addIcon-white'
import FileCopyIcon from '@material-ui/icons/FileCopy'

function PrimaryButton(props) {
  const { handleClick, title, className = false, disabled = false, userAccountManagement, style } = props
  return (
    <div className="button-container">
      <Button
        variant="contained"
        className={userAccountManagement ? 'user-account-primary-btn' : 'primary-btn'}
        onClick={handleClick}
        disabled={disabled}
        style={style}>
        {title}
      </Button>
      {disabled && title != 'Cancel' && <CircularProgress size={20} thickness={5} className="button-progress" />}
    </div>
  )
}

function PrimaryButton2(props) {
  const { handleClick, title, className = false, disabled = false, userAccountManagement, style } = props
  return (
    <div className="button-container">
      <Button
        variant="contained"
        className={userAccountManagement ? 'user-account-primary-btn' : 'primary-btn'}
        onClick={handleClick}
        disabled={disabled}
        style={style}>
        {title}
      </Button>
      {/* {disabled && title != 'Cancel' && <CircularProgress size={20} thickness={5} className="button-progress" />} */}
    </div>
  )
}

function SecondaryButton(props) {
  const { handleClick, title, disabled = false, driveThrough } = props
  return (
    <div className="button-container">
      <Button
        variant="contained"
        className={driveThrough ? 'driveThrough-secondary-btn' : 'secondary-btn'}
        onClick={handleClick}
        disabled={disabled}>
        {title}
      </Button>
      {disabled && <CircularProgress size={20} thickness={5} className="button-progress" />}
    </div>
  )
}

function CancelButton(props) {
  const { handleClick, title, disabled = false, style } = props
  return (
    <div className="button-container">
      <Button variant="contained" className={'cancel-btn'} onClick={handleClick} disabled={disabled} style={style}>
        {title}
      </Button>
      {/* {disabled && <CircularProgress size={20} thickness={5} className="button-progress" />} */}
    </div>
  )
}

function ProceedButton(props) {
  const { handleClick, title, disabled } = props
  return (
    <div className="button-container">
      <Button className="proceed-btn" onClick={handleClick} disabled={disabled}>
        {title}{' '}
      </Button>
    </div>
  )
}

function WarnButton(props) {
  const { handleClick, title, disabled = false } = props
  return (
    <div className="button-container">
      <Button variant="contained" className="warn-btn" onClick={handleClick} disabled={disabled}>
        {title}
      </Button>
      {/* {disabled && <CircularProgress size={18} className='button-progress' />} */}
    </div>
  )
}

function DeleteButton(props) {
  const { handleClick, title, disabled = false } = props
  return (
    <div className="button-container">
      <Button variant="contained" className="delete-btn" onClick={handleClick} disabled={disabled}>
        {title}
      </Button>
    </div>
  )
}

function DisableButton(props) {
  const { handleClick, title, disabled = false } = props
  return (
    <div className="button-container">
      <Button variant="contained" className="disable-btn" onClick={handleClick} disabled={disabled}>
        {title}
      </Button>
    </div>
  )
}
function EnableButton(props) {
  const { handleClick, title, disabled = false } = props
  return (
    <div className="button-container">
      <Button variant="contained" className="enable-btn" onClick={handleClick} disabled={disabled}>
        {title}
      </Button>
    </div>
  )
}

function CloneButton(props) {
  const { handleClick, title, disabled = false } = props
  return (
    <div className="button-container">
      <Button variant="contained" className="primary-btn" onClick={handleClick} disabled={disabled}>
        {title}
      </Button>
    </div>
  )
}

function DownloadButton(props) {
  const { handleClick, title, disabled = false, inProgress = false } = props
  return (
    <div className="button-container">
      {inProgress ? (
        <CircularProgress size={20} thickness={6} />
      ) : (
        <Button variant="contained" className="download-btn" onClick={handleClick} disabled={disabled}>
          {title}
        </Button>
      )}
    </div>
  )
}

function IconDownloadButton(props) {
  const { handleClick, title, disabled = false, inProgress = false } = props
  return (
    <div className="button-container">
      {inProgress ? (
        <CircularProgress size={20} thickness={6} />
      ) : (
        <>
          <Button variant="contained" className="download-icon-btn" onClick={handleClick} disabled={disabled}>
            <GetAppIcon style={{ width: '15%', marginRight: '10px' }} />
            {title}
          </Button>
        </>
      )}
    </div>
  )
}

function UploadButtonWithIcon(props) {
  const { handleClick, title, className = false, disabled = false, style } = props
  return (
    <div className="button-container">
      <Button variant="contained" className="import-btn" onClick={handleClick} disabled={disabled} style={style}>
        <FileCopyIcon style={{ width: '15%', marginRight: '10px' }} />
        {title}
      </Button>
      {disabled && title != 'Cancel' && <CircularProgress size={20} thickness={5} className="button-progress" />}
    </div>
  )
}

function DownloadButtonWithIcon(props) {
  const { handleClick, title, className = false, disabled = false, style } = props
  return (
    <div className="button-container">
      <Button variant="contained" className="download-icon-btn" onClick={handleClick} disabled={disabled} style={style}>
        <GetAppIcon style={{ width: '15%', marginRight: '10px' }} />
        {title}
      </Button>
      {disabled && title != 'Cancel' && <CircularProgress size={20} thickness={5} className="button-progress" />}
    </div>
  )
}
function DownloadCsvButton(props) {
  const { handleClick, title, className = false, disabled = false, style, inProgress } = props
  return (
    <div className="button-container">
      <Button
        variant="contained"
        className="download-icon-btn"
        onClick={handleClick}
        disabled={disabled || inProgress}
        style={style}>
        <GetAppIcon style={{ width: '15%', marginRight: '10px' }} />
        {title}
      </Button>
      {inProgress && <CircularProgress size={20} thickness={5} className="button-progress" />}
    </div>
  )
}

function DownloadIconButton(props) {
  const { handleClick, disabled = false, inProgress = false, tooltip } = props
  return (
    <div className="button-container">
      {inProgress ? (
        <span className="spinner">
          <CircularProgress size={20} thickness={6} />
        </span>
      ) : (
        <Tooltip disableHoverListener={tooltip ? false : true} title={tooltip}>
          <IconButton className="downloadButton" onClick={handleClick} disabled={disabled}>
            <GetAppIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  )
}

function ClearIconButton(props) {
  const { handleClick, disabled = false, tooltip } = props
  return (
    <div className="button-container">
      <Tooltip disableHoverListener={tooltip ? false : true} title={tooltip}>
        <IconButton className="clearButton" onClick={handleClick} disabled={disabled}>
          <ClearIcon />
        </IconButton>
      </Tooltip>
    </div>
  )
}

function AddButtonWithIcon(props) {
  const { handleClick, title, className = false, disabled = false, showIcon } = props
  return (
    <div className="button-container">
      <Button
        startIcon={showIcon ? <AddIcon /> : null}
        variant="contained"
        className="primary-btn"
        onClick={handleClick}
        disabled={disabled}>
        {title}
      </Button>
      {disabled && title != 'Cancel' && <CircularProgress size={20} thickness={5} className="button-progress" />}
    </div>
  )
}

function EnableWEBButton(props) {
  const { handleClick, title, disabled = false, inProgress = false } = props
  return (
    <div className="button-container">
      {inProgress ? (
        <CircularProgress size={20} thickness={6} />
      ) : (
        <Button variant="contained" className="enableWEB-btn" onClick={handleClick} disabled={disabled}>
          {title}
        </Button>
      )}
    </div>
  )
}

function ResetWEBButton(props) {
  const { handleClick, title, disabled = false, inProgress = false } = props
  return (
    <div className="button-container">
      {inProgress ? (
        <CircularProgress size={20} thickness={6} />
      ) : (
        <Button variant="contained" className="resetWEB-btn" onClick={handleClick} disabled={disabled}>
          {title}
        </Button>
      )}
    </div>
  )
}

function ResetKDSButton(props) {
  const { handleClick, title, disabled = false, inProgress = false } = props
  return (
    <div className="button-container">
      {inProgress ? (
        <CircularProgress size={20} thickness={6} />
      ) : (
        <Button variant="contained" className="resetKDS-btn" onClick={handleClick} disabled={disabled}>
          {title}
        </Button>
      )}
    </div>
  )
}
function PanelButton(props) {
  const { handleClick, title, className = false, disabled = false, userAccountManagement, style } = props
  return (
    <div className="button-container">
      <Button
        variant="contained"
        className={userAccountManagement ? 'user-account-primary-btn' : 'primary-btn'}
        onClick={handleClick}
        disabled={disabled}
        style={style}>
        {title}
      </Button>
      {disabled && title != 'Cancel' && <CircularProgress size={20} thickness={5} className="button-panel-progress" />}
    </div>
  )
}

export default {
  PrimaryButton,
  PrimaryButton2,
  SecondaryButton,
  WarnButton,
  ProceedButton,
  CloneButton,
  DownloadButton,
  DownloadIconButton,
  ClearIconButton,
  DeleteButton,
  AddButtonWithIcon,
  EnableWEBButton,
  ResetWEBButton,
  ResetKDSButton,
  DisableButton,
  IconDownloadButton,
  CancelButton,
  EnableButton,
  UploadButtonWithIcon,
  DownloadButtonWithIcon,
  DownloadCsvButton,
  PanelButton
}

import React, { useState, useEffect } from 'react'
import Amplify, { API, graphqlOperation, Auth } from 'aws-amplify'

import { validator } from '@utils'
import { Card, CardContent, Button } from '@material-ui/core'
import { Email, Lock, AccessTime } from '@material-ui/icons'
import SignIn from './LoginPage/SignIn'
import ForceChangePassword from './LoginPage/ForceChangePassword'
import MFAFields from './LoginPage/MFAFields'
import VerifyMFA from './LoginPage/VerifyMFA'
import MFAGuide from './LoginPage/MFAGuide'
import './LoginPage.scss'
import QRCodeMFA from './LoginPage/QRCodeMFA'

export default function Login(props) {
  const [email, setEmail] = useState('')
  const [isForgot, setForgot] = useState(false)
  const [user, setUser] = useState({})
  const [mustChangePassword, setMustChangePassword] = useState(false)
  const [mustResetPassword, setMustResetPassword] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isMFARequired, setMFARequired] = useState(false)
  const [isMFASetup, setIsMFASetup] = useState(false)
  const [showGuide, setShowGuide] = useState(false)
  const [MFAType, setMFAType] = useState(null)
  const [MFACode, setMFACode] = useState('')
  const [verifyMFAError, setVerifyMFAError] = useState({})
  const [qrMFA, setQrMFA] = useState('')
  const [secretCode, setSecretCode] = useState('')
  const [isSend, setSend] = useState(false)
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [code, setCode] = useState('')
  const [loginValidation, setLoginValidation] = useState(false)
  const [message, setMessage] = useState('')
  const [errors, setErrors] = useState({ cognito: null, blankfield: false })
  useEffect(() => {
    // const aws_exports_override = {...aws_exports, aws_appsync_authenticationType: "AWS_IAM",}
    // Amplify.configure(aws_exports_override);
    let url = new URL(window.location.href)
    let setupParam = url.searchParams.get('setup')
    let resetParam = url.searchParams.get('reset')
    setShowGuide(setupParam == 'true' || setupParam == true ? true : false)
    setMustResetPassword(resetParam == 'true' || resetParam == true ? true : false)
    // setTimeout(()=>{
    //   setPassword('')
    // }, 5000)
    const additionalConfig = {
      aws_appsync_authenticationType: 'API_KEY'
    }
    Amplify.configure({ ...additionalConfig })
  }, [])
  useEffect(() => {
    localStorage.clear()
  }, [])
  useEffect(() => {
    if (mustResetPassword) {
      setPassword('123')
    }
  }, [mustResetPassword])
  function handleEmailChange(value) {
    setEmail(value)
  }

  function handlePasswordChange(value) {
    setPassword(value)
  }

  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      if (isSend == false) {
        handleLogin()
      } else {
        handleForgetPassword()
      }
    }
  }

  const obscureEmail = (email) => {
    const [user, domain] = email.split('@')
    const item = domain.split('.')
    const domain_name = item[0]
    var top_level_domain
    if (item.length > 2) {
      top_level_domain = item[1] + '.' + item[2]
    } else {
      top_level_domain = item[1]
    }
    return `${user[0]}***@${domain_name[0]}***.${top_level_domain}`
  }

  async function handleLogin() {
    // let attemptCount = localStorage.getItem(email) ? parseInt(localStorage.getItem(email)) : 1
    // console.log(attemptCount)
    // localStorage.setItem(email, attemptCount)
    setLoginValidation(false)
    setMessage(false)

    if (!validator.isEmptyString(email) && !validator.isEmpty(password)) {
      try {
        if (!isForgot) {
          setIsLoading(true)
          // await Auth.signIn(email.toLowerCase(), password)
          // await Auth.signOut({ global: true })
          const user = await Auth.signIn(email.toLowerCase(), password)
          setUser(user)

          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            // setShowGuide(true)
            // setUser(user)
            setIsLoading(false)
            setMustChangePassword(true)
          } else if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
            // setUser(user)
            setMFAType(user.challengeName)
            setIsLoading(false)
            setMFARequired(true)
          } else if (
            (user.preferredMFA === 'NOMFA' || !user.hasOwnProperty('challengeName')) &&
            user.username !== '9d8592c5-0fd4-4a56-b129-f12378a173e9'
          ) {
            // setShowGuide(true)
            // setUser(user)
            setIsLoading(false)
            let code = await Auth.setupTOTP(user)
            // console.log(code)
            const str = 'otpauth://totp/AWSCognito:' + user.username + '?secret=' + code
            setQrMFA(str)
            setSecretCode(code)
          } else {
            // setShowGuide(true);
            setIsLoading(false)
            window.location = '/'
          }
          // localStorage.removeItem(email)
        } else {
          if (confirmPassword == password) {
            if (password.length > 7) {
              let username = email.toLowerCase()
              let new_password = password
              let verification_code = code
              // Collect confirmation code and new password, then
              Auth.forgotPasswordSubmit(username, verification_code, new_password)
                .then((data) => {
                  console.log(data)
                  setForgot(false)
                  setPassword('')
                  setConfirmPassword('')
                  setTimeout(() => {
                    setLoginValidation(true)
                    setErrors({ ...errors, blankfield: true })
                    setMessage('Success! Reset password successful.')
                  }, 500)
                })
                .catch((err) => {
                  console.log(err)
                  setErrors({ ...errors, blankfield: false })
                  setLoginValidation(true)
                  if (err.code === 'UserNotFoundException') {
                    setMessage('Invalid verification code.')
                  } else {
                    setMessage(err.message)
                  }
                })
            } else {
              setErrors({ ...errors, blankfield: false })
              setLoginValidation(true)
              setMessage('Password must at least 8 characters.')
            }
          } else {
            setErrors({ ...errors, blankfield: false })
            setLoginValidation(true)
            setMessage(`Confirmation password didn't match. Try again`)
          }
        }
      } catch (error) {
        console.log(error)
        console.log(user)
        // setLoginValidation(true)
        let err = null
        if (error.code === 'PasswordResetRequiredException') {
          setLoginValidation(true)
          setMustResetPassword(false)
          if (
            email &&
            email.substring(0, 3) == 'mfm' &&
            validator.isDigit(email.substring(3, 7)) &&
            email.substring(7, 8) == '@'
          ) {
            setErrors({ ...errors, blankfield: false })
            setMessage('Store Manager please request Operation Manager to help reset password.')
          } else if (
            email &&
            email.substring(0, 2) == 'sm' &&
            validator.isDigit(email.substring(2, 6)) &&
            email.substring(6, 7) == '@'
          ) {
            setErrors({ ...errors, blankfield: false })
            setMessage('Store Manager please request Operation Manager to help reset password.')
          } else {
            setMessage(error.message)
            setForgot(true)
            // setCode(password)
            setCode('')
            setPassword('')
            setConfirmPassword('')
          }
          setIsLoading(false)
        } else if (error.message === 'Password attempts exceeded') {
          // let res = await API.graphql(
          //   graphqlOperation(disableUserAccount, {
          //     username: email
          //   })
          // )
          // console.log(res)
          setLoginValidation(true)
          setMessage(
            'You have exceeded the number of password attempts. The account has been locked. Please contact your administrator to reset your account.'
          )
          setErrors({ ...errors, blankfield: false })
          setIsLoading(false)
          // localStorage.removeItem(email)
        } else if (error.message === 'User is disabled.') {
          // localStorage.removeItem(email)
          setLoginValidation(true)
          setMessage(
            'You have exceeded the number of password attempts. The account has been locked. Please contact your administrator to reset your account.'
          )
          setIsLoading(false)
        } else if (error.code === 'UserLambdaValidationException') {
          setLoginValidation(true)
          setMessage('You cannot have more than one active session.')
          setIsLoading(false)
        } else if (error.code === 'UserNotFoundException') {
          setLoginValidation(true)
          setMessage('Incorrect username or password.')
          setIsLoading(false)
        } else {
          // await API.graphql(graphqlOperation(preAuthCheck, {
          //   username: email
          // }))
          // attemptCount++
          // localStorage.setItem(email, attemptCount)
          // console.log('Attempt Count', attemptCount)
          setLoginValidation(true)
          setMessage(error.message)
          setIsLoading(false)
        }
      }
    } else {
      setLoginValidation(true)
      setErrors({ ...errors, blankfield: false })
      setMessage('All fields are required.')
    }
  }
  async function handleForgetPassword() {
    setLoginValidation(false)
    setMessage(false)
    setPassword('')
    setConfirmPassword('')
    setCode('')
    if (!validator.isEmptyString(email)) {
      try {
        const username = email.toLowerCase()
        await Auth.forgotPassword(username)
          .then((data) => {
            setLoginValidation(true)

            if (
              email &&
              email.substring(0, 3) == 'mfm' &&
              validator.isDigit(email.substring(3, 7)) &&
              email.substring(7, 8) == '@'
            ) {
              setErrors({ ...errors, blankfield: false })
              setMessage('Store Manager please request Operation Manager to help reset password')
            } else if (
              email &&
              email.substring(0, 2) == 'sm' &&
              validator.isDigit(email.substring(2, 6)) &&
              email.substring(6, 7) == '@'
            ) {
              setErrors({ ...errors, blankfield: false })
              setMessage('Store Manager please request Operation Manager to help reset password')
            } else {
              setErrors({ ...errors, blankfield: true })
              setMessage(
                `Validation Code has been sent to your email (${data.CodeDeliveryDetails.Destination}). Should you not received the email in your inbox, please check your spam/junk mail`
              )
              setTimeout(() => {
                setForgot(true)
                setSend(false)
                setLoginValidation(false)
              }, 1500)
            }
          })
          .catch((err) => {
            console.log(err)
            setLoginValidation(true)
            if (err.code === 'UserNotFoundException') {
              setErrors({ ...errors, blankfield: true })
              setMessage(
                `Validation Code has been sent to your email (${obscureEmail(
                  email
                )}). Should you not received the email in your inbox, please check your spam/junk mail`
              )
              setTimeout(() => {
                setForgot(true)
                setSend(false)
                setLoginValidation(false)
              }, 1500)
            } else {
              setErrors({ ...errors, blankfield: false })
              setMessage(err.message)
            }
          })
      } catch (err) {
        console.log(err)
        setLoginValidation(true)
        setErrors({ ...errors, blankfield: false })
        setMessage(err.message)
      }
    } else {
      setLoginValidation(true)
      setErrors({ ...errors, blankfield: false })
      setMessage('Email fields is required to forgot password.')
    }
  }

  const handleSignOut = async () => {
    await Auth.signOut({ global: true })
    window.location = '/'
  }
  const handleMFACode = async (value) => {
    setMFACode(value)
    if (value.length == 6) {
      try {
        await Auth.verifyTotpToken(user, value)
        await Auth.setPreferredMFA(user, 'TOTP')
        setIsMFASetup(false)
        window.location = `/`
      } catch (e) {
        setMFACode('')
        console.log(e)
        setVerifyMFAError(e)
      }
    }
  }
  return (
    <React.Fragment>
      <div className="login_container">
        <div className="login_title_container">
          <div className="login_title">
            <h1 className="login_text">{isForgot ? `Reset Password` : `Login Page`}</h1>
          </div>
        </div>
        <div className="login_container login_form">
          {showGuide ? (
            <Card style={{ width: '50%' }}>
              <CardContent>
                <MFAGuide setShowGuide={setShowGuide} />
              </CardContent>
            </Card>
          ) : !qrMFA.length ? (
            <Card className="login_form_card">
              <CardContent>
                {!mustChangePassword ? (
                  <>
                    {!isMFARequired ? (
                      <SignIn
                        email={email}
                        password={password}
                        confirmPassword={confirmPassword}
                        mustResetPassword={mustResetPassword}
                        isForgot={isForgot}
                        setForgot={setForgot}
                        isLoading={isLoading}
                        handleForgot={handleForgetPassword}
                        setConfirmPassword={setConfirmPassword}
                        setPassword={handlePasswordChange}
                        setEmail={handleEmailChange}
                        loginValidation={loginValidation}
                        setLoginValidation={setLoginValidation}
                        isSend={isSend}
                        setSend={setSend}
                        errors={errors}
                        message={message}
                        handleKeyPress={handleKeyPress}
                        handleLogin={handleLogin}
                        code={code}
                        setCode={setCode}
                      />
                    ) : (
                      <MFAFields user={user} mfaType={MFAType} handleCancel={handleSignOut} />
                    )}
                  </>
                ) : (
                  <ForceChangePassword
                    user={user}
                    newPassword={newPassword}
                    setNewPassword={setNewPassword}
                    qrMFA={qrMFA}
                    setQrMFA={setQrMFA}
                  />
                )}
              </CardContent>
            </Card>
          ) : (
            // <MFAFields user={user} mfaType={MFAType} />
            <Card className="login_form_card mfa">
              <CardContent>
                {!isMFASetup ? (
                  <QRCodeMFA
                    qrMFA={qrMFA}
                    secretCode={secretCode}
                    setMFASetup={() => setIsMFASetup(true)}
                    setShowGuide={setShowGuide}
                    handleCancel={handleSignOut}
                  />
                ) : (
                  <VerifyMFA
                    MFACode={MFACode}
                    handleMFACode={handleMFACode}
                    setMFASetup={() => setIsMFASetup(false)}
                    verifyMFAError={verifyMFAError}
                  />
                )}
              </CardContent>
            </Card>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

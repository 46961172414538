import React, { useEffect, useRef } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import "./Popup.scss";

const Confirmation = (props) => {
  const { open, onClose, message, onClick, title, canCancelFlyerBag, cancelAllFlyerBag, setCancelAllFlyerBag } = props;
  return (
    <Dialog open={open} onClose={onClose} className="popup-confirmation">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        {canCancelFlyerBag===true && (
        <FormGroup>
          <FormControlLabel
            control={<Checkbox onClick={()=>setCancelAllFlyerBag(!cancelAllFlyerBag)} checked={cancelAllFlyerBag} />}
            label="Cancel all flyer bag"
          />
        </FormGroup>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          No
        </Button>
        <Button onClick={onClick} color="secondary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Scrollable = (props) => {
  const { onClose, open, children, title, onClick } = props;
  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll={"paper"}
      className="popup-scrollable"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText ref={descriptionElementRef} tabIndex={-1}>
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onClick} color="secondary" autoFocus>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default { Confirmation, Scrollable };


import 'react-dates/initialize'
import { DateRangePicker } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'
// import './react_dates_overrides.scss'

export const ReactDatesWrapper = (props) => {
    return (
      <DateRangePicker
        displayFormat={() => 'DD/MM/YYYY'}
        startDateId={props.startDateId}
        endDateId={props.endDateId}
        startDate={props.startDate}
        endDate={props.endDate}
        onDatesChange={props.onDatesChange}
        focusedInput={props.focusedInput}
        onFocusChange={props.onFocusChange}
        isOutsideRange={() => false}
        // showDefaultInputIcon={true}
        minimumNights={0}
      />
    )
  }
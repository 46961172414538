import React from 'react'
import CustomSection from '@components/CustomSection/CustomSection'
import { formatter } from '@utils'
// import moment from 'moment'

export default function FlyerPackDetails(props) {
  const { parcelOrder } = props
 
  let senderColumns = [
    {
        title: 'Flyer Pack Size',
        value: parcelOrder.flyerBagSize,
        size: {
          m: 2,
          xs: 12
        }
    },
    {
        title: 'Flyer Pack Price',
        value: formatter.toPrice(parcelOrder.flyerBagPrice),
        size: {
          m: 2,
          xs: 12
        }
    },
    {
        title: 'Flyer Pack Quantity',
        value: parcelOrder.flyerBagQuantity,
        size: {
          m: 2,
          xs: 12
        }
    },
    {
        title: 'Flyer Pack Total',
        value: formatter.toPrice(parcelOrder.flyerBagTotalPrice),
        size: {
          m: 2,
          xs: 12
        }
    },
    {
        title: 'Status',
        value: parcelOrder.flyerBagStatus,
        size: {
          m: 3,
          xs: 12
        }
    },
]

  return (
    <>
     <CustomSection columns={senderColumns} />
    </>
  )
}

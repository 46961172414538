import React, { useEffect, useState } from 'react'
// import { getMenuList } from '@graphql/queries'
// import { API, graphqlOperation, Auth } from 'aws-amplify'
// import { updateUserIpAndDeviceKey } from '@graphql/mutations'
import { LoadingSpinner } from '@components'
import { Authentication } from '@utils'
import validator from '@utils/Validator'

export default function LandingRedirector(props) {
  const [isLoading, setIsLoading] = useState(false)

  // const getCognitoGroup = async () => {
  //   let res = await Authentication.getUserGroupList()
  //   if (res !== null) {
  //     localStorage.setItem('cognitoList', JSON.stringify(res))
  //   }
  // }

  useEffect(() => {
    // temporary put to redirect to order page
    props.history.push('/parcel-order')
    // async function retrieveMenuList() {
    //   setIsLoading(true)
    //   try {
    //     let currentUser = await Auth.currentUserInfo()
    //     if (!validator.isEmpty(currentUser)) {
    //       let accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken()
    //       await API.graphql(
    //         graphqlOperation(updateUserIpAndDeviceKey, {
    //           username: currentUser.attributes.email,
    //           accessToken: accessToken
    //         })
    //       )
    //     }
    //     let menuList = await API.graphql(graphqlOperation(getMenuList))
    //     localStorage.setItem('menuList', JSON.stringify(menuList.data.getMenuList))
    //     if (menuList.data.getMenuList != null && menuList.data.getMenuList.length > 0) {
    //       props.history.push(menuList.data.getMenuList[0].path)
    //     } else {
    //       props.history.push('/error')
    //     }
    //   } catch (e) {
    //     setIsLoading(false)
    //   }
    // }
    // retrieveMenuList()
    // getCognitoGroup()
  }, [])

  return isLoading ? (
    <>
      <LoadingSpinner />
    </>
  ) : (
    <>
      <p>An error has occured. Please try to refresh</p>
    </>
  )
}

import React, { useState, useLayoutEffect, useEffect } from 'react'
import { Layout, SideNav, Button, Notification, CustomTable, LoadingSpinner, Timestamp } from '@components'
import { API, graphqlOperation, Auth } from 'aws-amplify'
import { searchParcelOrderDetails, adminGetDownloadJobStatus } from '@graphql/queries'
import { LiveTvTwoTone, Visibility } from '@material-ui/icons'
import { validator, formatter } from '@utils'
import { Tooltip, makeStyles, Grid, withStyles, InputBase, FormControl, Select, MenuItem } from '@material-ui/core'
import './ParcelList.scss'
import Moment from 'react-moment'
import moment from 'moment'
import 'react-dates/initialize'
import { DateRangePicker } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'
import '../../Order/react_dates_orderlist.scss'
import { adminTriggerDownloadReport } from '@graphql/mutations'

const useStyles = makeStyles({
  underline: {
    borderRadius: 24,
    border: '1px solid #EAEAEA',
    fontFamily: 'din',
    backgroundColor: 'white',
    position: 'relative',
    top: '16px',
    paddingLeft: '20px',
    '&&&:before': {
      borderBottom: 'none'
    },
    '&&:after': {
      borderBottom: 'none'
    }
  }
})
const BootstrapInput = withStyles((theme) => ({
  root: {
    fontSize: '16px',
    fontFamily: 'din',
    color: '#000000',
    marginTop: '7px'
  },
  input: {
    borderRadius: 23,
    backgroundColor: '#FFFFFF',
    border: '1px solid #EAEAEA',
    paddingLeft: '10px',
    '&:focus': {
      borderRadius: 23
    }
  }
}))(InputBase)

export default function ParcelOrderList(props) {
  const [user, setUser] = useState(false)
  const [nextToken, setNextToken] = useState(null)
  const [total, setTotal] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isDownloading, setIsDownloading] = useState(false)
  const [isCheckingUser, setIsCheckingUser] = useState(false)
  const [isTimeOut, setIsTimeOut] = useState(true)
  const [parcelOrder, setParcelOrder] = useState([])
  const [orderDateFrom, setorderDateFrom] = useState(null)
  const [orderDateTo, setorderDateTo] = useState(null)
  const [focusedInput, setFocusedInput] = useState(null)
  const [searchFilter, setSearchFilter] = useState('')
  const [downloadUrl, setDownloadUrl] = useState('')
  const [userMatrix, setUserMatrix] = useState()
  const [store, setStore] = useState()
  const [filter, setFilter] = useState({
    status: ''
  })
  const [keyword, setKeyword] = useState({})
  const [menuList, setMenuList] = useState([])
  const defaultSort = { field: 'createdAt', direction: 'desc' }
  const [sortField, setSortField] = useState(defaultSort)
  const [clear, setClear] = useState(false)
  const [modal, setModal] = useState({
    show: false,
    severity: ''
  })

  const [currentPage, setCurrentPage] = useState(0)
  const maxRowPerPage = 50

  let columns = [
    {
      key: '',
      title: 'No.',
      render: (row, ridx) => <div>{currentPage * maxRowPerPage + ridx + 1}.</div>
    },
    {
      key: 'orderTxnId',
      title: 'Order ID',
      render: (row) => (
        <div>
         {row.orderTxnId}
        </div>
      )
    },
    {
      key: 'orderNumber',
      title: 'Order No',
      render: (row) => <div>{row.orderNumber}</div>
    },
    {
      key: 'transactionId',
      title: 'EGHL No.',
      render: (row) => <div>{row.transactionId}</div>
    },
    {
      key: 'trackerNumber',
      title: 'Tracking No',
      render: (row) => (
        <div>
          <span style={{ cursor: 'pointer' }} onClick={() => window.open('/parcel-order/' + row.parcelOrderDetailId, '_blank')}>
            {row.trackerNumber}
          </span>
        </div>
      )
    },
    {
      key: 'originalOrderNumber',
      title: 'Original Order No',
      render: (row) => <div>{row.originalOrderNumber ? row.originalOrderNumber : "-"}</div>
    },
    {
      key: 'orderDate',
      title: 'Order Time',
      render: (row) => (
        <div>
        {row.orderDate && (
          <>
            <Moment format="DD/MM/YYYY">{row.orderDate}</Moment>
            <br />
            <Timestamp>{row.orderDate}</Timestamp>
          </>
          )}
        </div>
      )
    },
    {
      key: 'dropOffDateTime',
      title: 'Drop-off Time',
      render: (row) => (
        <div>
        {row.dropOffDateTime && (
          <>
            <Moment format="DD/MM/YYYY">{row.dropOffDateTime}</Moment>
            <br />
            <Timestamp>{row.dropOffDateTime}</Timestamp>
          </>
          )}
        </div>
      )
    },
    {
      key: 'collectionDateTime',
      title: 'Collection Time',
      render: (row) => (
        <div>
        {row.collectionDateTime && (
          <>
            <Moment format="DD/MM/YYYY">{row.collectionDateTime}</Moment>
            <br />
            <Timestamp>{row.collectionDateTime}</Timestamp>
        </>
        )}
      </div>
      )
    },
    {
      key: 'dropOffStoreCode',
      title: 'Drop-off Store',
      render: (row) => <div>{row.dropOffStoreCode}</div>
    },
    {
      key: 'collectionStoreCode',
      title: 'Collection Store',
      render: (row) => <div>{row.collectionStoreCode}</div>
    },
    {
      key: 'senderAccountNo',
      title: 'Sender Account',
      render: (row) => <div>{row.senderAccountNo}</div>
    },
    {
      key: 'senderMobileNumber',
      title: 'Sender Mobile',
      render: (row) => <div>{row.senderMobileNumber}</div>
    },
    {
      key: 'receiverName',
      title: 'Receiver Name',
      render: (row) => <div>{row.receiverName}</div>
    },
    {
      key: 'receiverMobileNumber',
      title: 'Receiver Mobile',
      render: (row) => <div>{row.receiverMobileNumber}</div>
    },
    {
      key: 'discountType',
      title: 'Discount Type',
      render: (row) => <div>{row.discountType}</div>
    },
    {
      key: 'parcelAmount',
      title: 'Parcel Amt',
      render: (row) => <div>{formatter.toPrice(row.parcelAmount)}</div>
    },
    {
      key: 'addOnAmount',
      title: 'Add-Ons Amt',
      render: (row) => <div>{formatter.toPrice(row.addOnAmount)}</div>
    },
    {
      key: 'promoCodeDiscount',
      title: 'Discount',
      render: (row) => <div>{row.promoCodeDiscount ? formatter.toPrice(row.promoCodeDiscount) : 'RM 0.00'}</div>
    },
    {
      key: 'netTotal',
      title: 'Net Total',
      render: (row) => <div>{row.promoCodeDiscount ? formatter.toPrice(row.parcelAmount - row.promoCodeDiscount) : formatter.toPrice(row.parcelAmount)}</div>
    },
    {
      key: 'refundAmount',
      title: 'Refund Amt',
      render: (row) => <div>{row.refundAmount ? formatter.toPrice(row.refundAmount) : '-'}</div>
    },
    {
      key: 'parcelType',
      title: 'Order Type',
      render: (row) => <div>{row.parcelType}</div>
    },
    {
      key: 'paymentMethod',
      title: 'Payment Method',
      render: (row) => <div>{row.paymentMethod}</div>
    },
    {
      key: 'status',
      title: 'Status',
      render: (row) => <div>{row.transactionId===null && row.status===null ? "" : row.transactionId!==null && row.status===null? "Pending Drop-Off": row.status}</div>
    },
    {
      key: 'view',
      title: '',
      render: (row) => (
        <div>
          <Tooltip arrow placement="top" title="View" style={{ color: '#1E91CF' }}>
            <Visibility className="mui-icon" onClick={() => handleView(row)} />
          </Tooltip>
          {/* {userMatrix ? (
            userMatrix.canView == true ? (
              <Tooltip arrow placement="top" title="View" style={{ color: '#1E91CF' }}>
                <Visibility className="mui-icon" onClick={() => handleView(row)} />
              </Tooltip>
            ) : null
          ) : null} */}
        </div>
      )
    }
  ]
  const [tableColumn, setTableColumn] = useState([])

  const [selectedCategory, setSelectedCategory] = useState('All')

  const [selectedDate, setSelectedDate] = useState('orderDate')

  // const getCampaignNumber = (orderId) => {
  //   API.graphql(graphqlOperation(getPromoCodeCampaignNumber, { orderId: orderId }))
  // }
  const rangeDatesChangeHandler = ({ startDate, endDate }) => {
    if (moment(startDate).isSameOrBefore(moment('2020-01-01').startOf('days'))) {
      setModal({
        ...modal,
        show: true,
        severity: 'error',
        message: 'Start Date cannot be set before year 2020',
        component: 'startDate'
      })
      return
    }

    if (moment(endDate).isSameOrAfter(moment('2100-01-01').startOf('days'))) {
      setModal({
        ...modal,
        show: true,
        severity: 'error',
        message: 'End Date cannot be set after year 2100',
        component: 'endDate'
      })
      return
    }
    if (moment(startDate).isSameOrAfter(moment('2100-01-01').startOf('days'))) {
      setModal({
        ...modal,
        show: true,
        severity: 'error',
        message: 'Start Date cannot be set after year 2100',
        component: 'startDate'
      })
      return
    }
    setorderDateFrom(startDate)
    setorderDateTo(endDate)
  }

  const onFocusChangeRangeHandler = (focusedInput) => {
    setFocusedInput(focusedInput)
  }

  const ReactDatesWrapper = (props) => {
    return (
      <DateRangePicker
        displayFormat={() => 'DD/MM/YYYY'}
        startDateId={props.startDateId}
        endDateId={props.endDateId}
        startDate={props.startDate}
        endDate={props.endDate}
        onDatesChange={props.onDatesChange}
        focusedInput={props.focusedInput}
        onFocusChange={props.onFocusChange}
        isOutsideRange={() => false}
        showDefaultInputIcon={true}
        minimumNights={0}
      />
    )
  }
  useEffect(() => {
    console.log(tableColumn)
  }, [tableColumn])

  useEffect(() => {
    if (downloadUrl !== '') {
      window.open(downloadUrl, '_blank')
      setModal({
        ...modal,
        show: true,
        severity: 'success',
        autoHide: 1000,
        message: 'Order report downloaded successfully. '
      })
    }
  }, [downloadUrl])

  useEffect(() => {
    // async function matrix() {
    //   let res = await Authentication.checkUserPermission('order')
    //   let newTableColumns = [...columns]
    //   if (res == null) {
    //     setIsCheckingUser(false)
    //     setUserMatrix({ canList: true, canView: true, canAdd: true, canDelete: true, canEdit: true })
    //     setIsCheckingUser(false)
    //     setStore(null)
    //     setTableColumn(columns)
    //   } else if (res) {
    //     if (res[0].userGroup == 'StoreManager') {
    //       Auth.currentAuthenticatedUser({
    //         bypassCache: false
    //       }).then((user) => {
    //         setUser(user.attributes.email)
    //         setIsCheckingUser(false)
    //       })
    //     } else {
    //       setIsCheckingUser(false)
    //     }
    //     setUserMatrix(res[0])
    //     if (res[0].canView) {
    //       if (res[0]['userGroup']) {
    //         if (res[0]['userGroup'] === 'CustomerService') {
    //           let removeColumns = [
    //             'orderTxnId',
    //             'scheduledDateTime',
    //             'totalOrderItems',
    //             'customerPhoneNumber',
    //             'refundAmount'
    //           ]
    //           newTableColumns = newTableColumns.filter((col) => !removeColumns.includes(col['key']))
    //         }
    //       }
    //       setTableColumn(newTableColumns)
    //     } else {
    //       newTableColumns = newTableColumns.filter((col) => col['key'] !== 'view')
    //       setTableColumn(newTableColumns)
    //     }
    //   }
    // }
    // matrix()

    if (
      user &&
      user.substring(0, 3) == 'mfm' &&
      validator.isDigit(user.substring(3, 7)) &&
      user.substring(7, 8) == '@'
    ) {
      setStore(user.substring(3, 7))
      console.log(user.substring(3, 7))
    } else if (
      user &&
      user.substring(0, 2) == 'sm' &&
      validator.isDigit(user.substring(2, 6)) &&
      user.substring(6, 7) == '@'
    ) {
      setStore(user.substring(2, 6))
    }
  }, [user])

  useEffect(() => {
    if (isCheckingUser == false) {
      fetchData(null, maxRowPerPage, 0)
    }
  }, [searchFilter, store, isCheckingUser])

  async function fetchData(currentNextToken, limit = maxRowPerPage, page = 0, orderBy = null, orderDirection = null) {
    setIsLoading(true)

    try {
      let skipRow = parseInt(page) * parseInt(limit)
      let res = null
      let sort = sortField

      if (orderBy && orderDirection) {
        sort = { field: orderBy, direction: orderDirection }
        setSortField(sort)
      }

      if (store && validator.isEmpty(searchFilter)) {
        res = await API.graphql(
          graphqlOperation(searchParcelOrderDetails, {
            filter: { storeCode: { eq: store } },
            limit: limit,
            nextToken: skipRow,
            sort: sort
          })
        )
      } else if (!validator.isEmpty(searchFilter)) {
        res = await API.graphql(
          graphqlOperation(searchParcelOrderDetails, {
            filter: {and: [{paymentSuccess: {eq: true}}], ...searchFilter},
            limit: limit,
            nextToken: skipRow,
            sort: sort
          })
        )
      } else {
        res = await API.graphql(graphqlOperation(searchParcelOrderDetails, { filter:{paymentSuccess: { eq: true }},  limit: limit, nextToken: skipRow, sort: sort }))
      }
      console.log(res)
      setTotal(res.data.searchParcelOrderDetails.total)
      if (res.data.searchParcelOrderDetails.total > 0) {
        let currentOrderList = res.data.searchParcelOrderDetails.items
        setParcelOrder(currentOrderList)
        if (res.data.searchParcelOrderDetails.total > skipRow && res.data.searchParcelOrderDetails.items.length === limit) {
          setNextToken(res.data.searchParcelOrderDetails.items.length)
        } else {
          setNextToken(null)
        }
      } else {
        setParcelOrder([])
        setNextToken(null)
      }

      setIsTimeOut(false)
      setIsLoading(false)
    } catch (err) {
      console.log(err)
      if (err.data.searchParcelOrderDetails.items) {
        let skipRow = parseInt(page) * parseInt(limit)
        setTotal(err.data.searchParcelOrderDetails.total)
        let currentOrderList = err.data.searchParcelOrderDetails.items
        setParcelOrder(currentOrderList)
        if (err.data.searchParcelOrderDetails.total > skipRow && err.data.searchParcelOrderDetails.items.length === limit) {
          setNextToken(err.data.searchParcelOrderDetails.items.length)
        } else {
          setNextToken(null)
        }
        setIsTimeOut(false)
      } else {
        setIsTimeOut(true)
      }
      setIsLoading(false)
    }
    setCurrentPage(page)
  }

  const handleView = (item) => {
    props.history.push({
      pathname: `/parcel-order/${item.parcelOrderDetailId}`,
      state: { item }
    })
  }

  const elasticSearch = async (item, category) => {
    let currentSearchFilter = {}
    let keywordToSearch = ''

    if (item) {
      keywordToSearch = item
      setKeyword(item)
    } else {
      setKeyword('')
    }

    if (keywordToSearch && keywordToSearch.length > 0) {
      currentSearchFilter = { or: [] }
      // let TransactionNumberFilter = { and: [] }
      let OrderNumberFilter = { and: [] }
      let OriginalOrderNumberFilter = { and: [] }
      let TrackingNumberFilter = { and: [] }
      let DropOffStoreCode = { and: [] }
      let CollectionStoreCode = { and: [] }
      let StatusFilter = { and: [] }
      let splitKeywords = keywordToSearch.toLowerCase().split(' ')
      splitKeywords.map((keyword) => {
        OrderNumberFilter['and'].push({ orderNumber: { regexp: '.*' + keyword + '.*' } })
        OriginalOrderNumberFilter['and'].push({ originalOrderNumber: { regexp: '.*' + keyword + '.*' } })
        StatusFilter['and'].push({ status: { regexp: '.*' + keyword + '.*' } })
        // TransactionNumberFilter['and'].push({ orderTxnId: { eq: keyword } })
        TrackingNumberFilter['and'].push({ trackerNumber: { regexp: '.*' + keyword + '.*' }})
        DropOffStoreCode['and'].push({ dropOffStoreCode: { regexp: '.*' + keyword + '.*' }})
        CollectionStoreCode['and'].push({ collectionStoreCode: { regexp: '.*' + keyword + '.*' }})
      })
      if (category === 'orderNumber') {
        currentSearchFilter['or'].push(OrderNumberFilter)
      } else if (category === 'originalOrderNumber') {
        currentSearchFilter['or'].push(OriginalOrderNumberFilter)
      } else if (category === 'status') {
        currentSearchFilter['or'].push(StatusFilter)
      } else if (category === 'trackerNumber') {
        currentSearchFilter['or'].push(TrackingNumberFilter)
      } else if (category === 'dropOffStoreCode') {
        currentSearchFilter['or'].push(DropOffStoreCode)
      } else if (category === 'collectionStoreCode') {
        currentSearchFilter['or'].push(CollectionStoreCode)
      } else if (category === 'All') {
        currentSearchFilter['or'].push(OrderNumberFilter)
        currentSearchFilter['or'].push(OriginalOrderNumberFilter)
        currentSearchFilter['or'].push(StatusFilter)
        currentSearchFilter['or'].push(TrackingNumberFilter)
        currentSearchFilter['or'].push(DropOffStoreCode)
        currentSearchFilter['or'].push(CollectionStoreCode)
      }
      // if (category === 'All' && validator.isDigit(keywordToSearch) && parseInt(keywordToSearch) < Math.pow(2, 31)) {
      //   currentSearchFilter['or'].push(TransactionNumberFilter)
      // }
    }
    if (store) {
      currentSearchFilter['storeCode'] = { eq: store }
    }
    console.log('currentSearchFilter: ', currentSearchFilter)
    if (orderDateTo || orderDateFrom) {
      setSearchFilter({ ...searchFilter, or: currentSearchFilter })
    } else {
      setSearchFilter(currentSearchFilter)
    }
  }

  const elastic = async (item) => {
    if (orderDateFrom == null) {
      setModal({ ...modal, show: true, severity: 'error', message: 'Start Date is required to filter' })
    } else if (orderDateTo == null) {
      setModal({ ...modal, show: true, severity: 'error', message: 'End Date is required to filter' })
    } else if (orderDateFrom > orderDateTo) {
      setModal({ ...modal, show: true, severity: 'error', message: 'Start Date must be later than End Date' })
    } else {
      setSearchFilter({
        ...searchFilter,
        orderDate: { gte: moment(orderDateFrom).startOf('days'), lte: moment(orderDateTo).endOf('days') }
      })
    }
  }

  // function handleDateFromChange(date){
  //   setorderDateFrom(date)
  // }

  // const handleDateToChange = (date) => {
  //   setorderDateTo(date)
  // }

  const handleClear = () => {
    setorderDateFrom(null)
    setorderDateTo(null)
    setKeyword('')
    setSelectedCategory('All')
    setSelectedDate('OrderDate')
    setSearchFilter([])
    setClear(true)
    setTimeout(() => {
      setClear(false)
    }, 100)
  }

  const download = async () => {
    setIsDownloading(true)
    let numberOfMonths = moment(orderDateTo).diff(moment(orderDateFrom), 'months', false) + 1
    console.log(numberOfMonths)

    if (numberOfMonths > 1) {
      setModal({
        ...modal,
        show: true,
        severity: 'error',
        message: 'Please try again with the start date and end date within ONE month duration'
      })
      setIsDownloading(false)
      return
    }
    setSearchFilter({
      ...searchFilter,
      orderDate: { gte: moment(orderDateFrom).startOf('days'), lte: moment(orderDateTo).endOf('days') }
    })
    try {
      let triggerDownloadReportRes = await API.graphql(
        graphqlOperation(adminTriggerDownloadReport, {
          searchField: selectedCategory,
          searchKeyword: keyword && keyword.length > 0 ? keyword : '',
          dateField: selectedDate,
          dateFrom: moment(orderDateFrom).startOf('days'),
          dateTo: moment(orderDateTo).endOf('days'),
          sort: sortField,
          module: 'parcel'
        })
      )
      if (triggerDownloadReportRes) {
        const interval = setInterval(async () => {
          let getDownloadJobStatusRes = await API.graphql(
            graphqlOperation(adminGetDownloadJobStatus, {
              fileDownloadStatusId: triggerDownloadReportRes.data.adminTriggerDownloadReport.fileDownloadStatusId
            })
          )
          if (getDownloadJobStatusRes.data.adminGetDownloadJobStatus.status === 'FAILED') {
            clearInterval(interval)
            setModal({
              ...modal,
              show: true,
              severity: 'error',
              autoHide: 1000,
              message: getDownloadJobStatusRes.data.adminGetDownloadJobStatus.message
            })
            setIsDownloading(false)
          }
          if (getDownloadJobStatusRes.data.adminGetDownloadJobStatus.status === 'COMPLETED') {
            clearInterval(interval)
            setDownloadUrl(getDownloadJobStatusRes.data.adminGetDownloadJobStatus.objectPresignedUrl)
            setIsDownloading(false)
          }
        }, 2000)
      }
    } catch (error) {
      console.log(error)
      setModal({
        ...modal,
        show: true,
        severity: 'error',
        message: 'Please try again with the filters.'
      })
      setIsDownloading(false)
    }
  }
  
  const handleDropDownFilter = async (e) => {
    setClear(true)
    setTimeout(() => {
      setClear(false)
    }, 100)
    setSelectedCategory(e)
    elasticSearch(keyword)
  }
  const handleDateDropDownFilter = async (e) => {
    setClear(true)
    setTimeout(() => {
      setClear(false)
    }, 100)
    setSelectedDate(e)
    //elasticSearch(keyword)
  }

  const classes = useStyles()
  const filterOption = () => {
    return (
      <div className="search-container_filter">
        <FormControl className="timeInput-box-select">
          Search Category
          <Select
            input={<BootstrapInput />}
            value={selectedCategory}
            onChange={(e) => handleDropDownFilter(e.target.value)}
            displayEmpty>
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="orderNumber">Order Number</MenuItem>
            <MenuItem value="originalOrderNumber">Original Order Number</MenuItem>
            <MenuItem value="status">Status</MenuItem>
            <MenuItem value="trackerNumber">Tracking Number</MenuItem>
            <MenuItem value="dropOffStoreCode">Drop-off Store Code</MenuItem>
            <MenuItem value="collectionStoreCode">Collection Store Code</MenuItem>
          </Select>
        </FormControl>
        {/* <FormControl className="timeInput-box-select">
          Search Date
          <Select
            input={<BootstrapInput />}
            value={selectedDate}
            onChange={(e) => handleDateDropDownFilter(e.target.value)}
            displayEmpty>
            <MenuItem value="orderDate">Order Date</MenuItem>
            <MenuItem value="orderCompleteDateTime">Order Completed Date</MenuItem>
          </Select>
        </FormControl> */}
        <Grid container justify="center" spacing={2}>
          <Grid item>
            <Grid container>
              <Grid item>
                <div className="date-picker">
                  <ReactDatesWrapper
                    startDateId="start-date"
                    endDateId="end-date"
                    onDatesChange={rangeDatesChangeHandler}
                    onFocusChange={onFocusChangeRangeHandler}
                    focusedInput={focusedInput}
                    startDate={orderDateFrom}
                    endDate={orderDateTo}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <div className="search-button-container">
              <div style={{ marginRight: '5px' }}>
                <Button.PrimaryButton title="FILTER" handleClick={elastic} />
              </div>
              <div style={{ marginRight: '5px' }}>
                <Button.SecondaryButton title="CLEAR" handleClick={handleClear} />
              </div>
              {/* {userMatrix ? (
                userMatrix.canAdd == true ? (
                  <div style={{ marginRight: '5px' }}>
                    <Button.DownloadButton
                      title="DOWNLOAD"
                      handleClick={''}
                      inProgress={isDownloading}
                      disabled={total == 0 || total == null}
                    />
                  </div>
                ) : null
              ) : null} */}
               <div style={{ marginRight: '5px' }}>
                  <Button.DownloadButton
                    title="DOWNLOAD"
                    handleClick={download}
                    inProgress={isDownloading}
                    disabled={total === 0 || total == null}
                  />
                </div>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }

  return (
    <Layout.Body>
      <Layout.LeftPanel>
        <SideNav {...props} />
      </Layout.LeftPanel>
      <Layout.Content title="Parcels" description="Display and manage all parcels." total={total} state={props}>
        {isLoading ? (
          <LoadingSpinner />
        ) : isTimeOut ? (
          <div className="button-container">
            <Button.PrimaryButton className="refresh-button" title="retry" handleClick={() => fetchData()} />
          </div>
        ) : null}

        {!isTimeOut ? (
          <div className="order-container">
            <CustomTable
              maxRow={maxRowPerPage}
              rows={parcelOrder}
              cols={userMatrix && userMatrix['userGroup'] === 'CustomerService' ? tableColumn : columns}
              state={props}
              total={total}
              filter={filterOption}
              onSearch={elasticSearch}
              searchCategory={selectedCategory}
              nextToken={nextToken}
              fetchData={fetchData}
              currentPage={currentPage}
              clear={clear}
              disabledPagination={isLoading}
              customStyle={{
                hasStyle: userMatrix && userMatrix['userGroup'] === 'CustomerService',
                customStyleName: 'paddingLeft',
                styleChildIndex: [1, 9]
              }}
            />
          </div>
        ) : null}
      </Layout.Content>
      <Notification
        message={modal.message}
        onClose={() => {
          setModal({ ...modal, show: false, severity: modal.severity })
          if (modal.component) setFocusedInput(modal.component)
        }}
        show={modal.show}
        severity={modal.severity}
      />
    </Layout.Body>
  )
}

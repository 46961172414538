import React from 'react'
import './CustomSection.scss'
import { Grid, TextField } from '@material-ui/core'

export default function CustomSection(props) {
  const { columns, inlineTitle = true, gridStyle } = props
 

  return (
    <>
      <Grid container spacing={1} className="custom-section-container_paper-row" style={gridStyle ? gridStyle : null} alignItems="flex-end">
        {columns.map((col) => (
             <Grid item md={col.size.m} xs={col.size.xs} className="custom-section-container_paper-store">
                <div className={inlineTitle ? 'nowrap' : null}>{col.title}</div>
                <div className="title-input">
                    <TextField
                      value={col.value}
                      variant="outlined"
                      className="title-input-box"
                      disabled={true}
                      fullWidth={true}
                      size="small"
                      style={col.inputStyle ? col.inputStyle : null}
                      inputProps={{
                        style: {
                          height: '6px',
                          // height: '10px',
                          paddingLeft: '10px'
                        },
                      }}
                    />
                </div>
           </Grid>
        ))}
      </Grid>
    </>
  )
}

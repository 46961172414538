import React, { useEffect, useState } from 'react'
import { PersonOutline } from '@material-ui/icons'
import { Drawer } from '@material-ui/core'
import { SideNav, Button } from '../index'
import { Auth, API, graphqlOperation } from 'aws-amplify'
// import { invalidateRefreshToken } from '@graphql/mutations'
import './Layout.scss'
import UserIcon from './UserIcon.svg'
import { ClearCache } from '@utils/cache'
import { Grid } from '@material-ui/core'

const Body = (props) => {
  const { children } = props
  return <div className="layout-container">{children}</div>
}

const LeftPanel = (props) => {
  const { children } = props

  return <div className="layout-left-panel">{children}</div>
}

const Content = (props) => {
  const {
    children,
    title,
    description,
    breadcrumbs,
    total,
    dashboard,
    state,
    showDeliveryTab,
    showDriveThroughTab,
    canDelete = false,
    onClick = false,
    goBack = false,
    onDelete = false,
    userMatrix = false,
    disableOnClick = false,
    isSaving = false,
    canCancel = false,
    onCancel = false,
    canPatchOrder = false,
    onPatchOrder = false,
    canRefund = false,
    canDisable = false,
    canEnable = false,
    onRefund = false,
    canClone = false,
    onClone = false,
    onDetails = false,
    onProduct = false,
    onDelivery = false,
    onDriveThrough = false,
    canDetails = false,
    isDetails = false,
    isCustomerDetails = false,
    onCustomerDetails = false,
    onEcommerceOrder = false,
    onTransactionHeader = false,
    onTransactionSalesEntry = false,
    onCustomerManagement = false,
    canViewManagement = false,
    setPopup,
    onDisable = false,
    status,
    handleSaveCustomerStatus,
    handleDisableConfirmation
  } = props

  const [user, setUser] = useState(null)
  const [showDrawer, setDrawer] = useState(false)

  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: false
    })
      .then((user) => setUser(user.attributes.email))
      .catch((err) => console.log(err))
  }, [])

  async function handleSignOut() {
    try {
      let user = await Auth.currentUserInfo()
      // await API.graphql(graphqlOperation(invalidateRefreshToken, { username: user.attributes.email }))
      await Auth.signOut()
      ClearCache()
      window.location = '/login'
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div className="layout-content">
      <div className="apptop-bar">
        <div className="hamburger-icon" onClick={() => setDrawer(true)}>
          ☰
        </div>
        <div className="apptop-bar_account">
          <img src={UserIcon} alt="UserIcon"></img>
          {/* <PersonOutline /> */}
          <a href="/authentication" style={{ marginTop: '10px', paddingLeft: '5px' }}>
            {user}
          </a>
          <a href="#" style={{ color: '#09A74C' }} className="apptop-bar_signout-btn" onClick={handleSignOut}>
            Sign Out
          </a>
        </div>
      </div>
      <div className="layout-content_description with-padding-20px">
        {breadcrumbs ? (
          <div className="layout-breadcrumbs">
            {breadcrumbs.map((item, index) => {
              return (
                <div className="layout-breadcrumbs__item" key={index}>
                  <span>
                    <a href={item.link}>
                      {item.title ? item.title.charAt(0).toUpperCase() + item.title.slice(1).toLowerCase() : ''}
                    </a>
                  </span>
                  {index + 1 < breadcrumbs.length ? <span>&gt;</span> : ''}
                </div>
              )
            })}
          </div>
        ) : null}

        <div className="layout-content-desc">
          <div>
            <div className="layout-content-title">
              {title} {total && !dashboard ? <span>({total})</span> : null}
            </div>
            {!dashboard ? <span className="layout-content-description-text">{description}</span> : null}
            <div className="layout-content-desc_btn">
              {canDetails ? (
                <span className={isDetails == 'detail' ? 'store-details' : 'store-details-disable'}>
                  <Button.CloneButton
                    title={'Store Details'}
                    handleClick={onDetails}
                    disabled={isDetails == 'detail'}
                  />{' '}
                </span>
              ) : null}
              {canDetails ? (
                <span className={isDetails == 'product' ? 'store-products' : 'store-products-disable'}>
                  <Button.CloneButton
                    title={'Store Products'}
                    handleClick={onProduct}
                    disabled={isDetails == 'product'}
                  />{' '}
                </span>
              ) : null}
              {canDetails && showDeliveryTab ? (
                <span className={isDetails == 'delivery' ? 'store-delivery' : 'store-delivery-disable'}>
                  <Button.CloneButton title={'Delivery'} handleClick={onDelivery} disabled={isDetails == 'delivery'} />{' '}
                </span>
              ) : null}
              {canDetails && showDriveThroughTab ? (
                <span className={isDetails == 'drivethrough' ? 'drive-through' : 'drive-through-disable'}>
                  <Button.CloneButton
                    title={'Drive-Through'}
                    handleClick={onDriveThrough}
                    disabled={isDetails == 'drivethrough'}
                  />{' '}
                </span>
              ) : null}
              <Grid container justify="space-around" style={{ marginBottom: 3 }}>
                <Grid item lg={2} md={3} sm={12} xs={12}>
                  {isCustomerDetails ? (
                    <span className={isDetails == 'customer-details' ? 'drive-through' : 'drive-through-disable'}>
                      <Button.CloneButton
                        title={'Customer Details'}
                        handleClick={onCustomerDetails}
                        disabled={isDetails == 'customer-details'}
                      />{' '}
                    </span>
                  ) : null}
                </Grid>
                <Grid item lg={2} md={3} sm={12} xs={12}>
                  {isCustomerDetails ? (
                    <span className={isDetails == 'ecommerce-order' ? 'drive-through' : 'drive-through-disable'}>
                      <Button.CloneButton
                        title={'E-Commerce Order'}
                        handleClick={onEcommerceOrder}
                        disabled={isDetails == 'ecommerce-order'}
                      />{' '}
                    </span>
                  ) : null}
                </Grid>
                <Grid item lg={2} md={3} sm={12} xs={12}>
                  {isCustomerDetails ? (
                    <span className={isDetails == 'transaction-header' ? 'drive-through' : 'drive-through-disable'}>
                      <Button.CloneButton
                        title={'Transaction Header'}
                        handleClick={onTransactionHeader}
                        disabled={isDetails == 'transaction-header'}
                      />{' '}
                    </span>
                  ) : null}
                </Grid>
                <Grid item lg={2} md={3} sm={12} xs={12}>
                  {isCustomerDetails ? (
                    <span
                      className={isDetails == 'transaction-sales-entry' ? 'drive-through' : 'drive-through-disable'}>
                      <Button.CloneButton
                        title={'Transaction Sales Entry'}
                        handleClick={onTransactionSalesEntry}
                        disabled={isDetails == 'transaction-sales-entry'}
                      />{' '}
                    </span>
                  ) : null}
                </Grid>
                {isCustomerDetails && canViewManagement ? (
                  <Grid item lg={2} md={3} sm={12} xs={12}>
                    <span className={isDetails == 'customer-management' ? 'drive-through' : 'drive-through-disable'}>
                      <Button.CloneButton
                        title={'Customer Management'}
                        handleClick={onCustomerManagement}
                        disabled={isDetails == 'customer-management'}
                      />{' '}
                    </span>
                  </Grid>
                ) : null}
              </Grid>
            </div>
          </div>
          {onClick || goBack ? (
            <div className="layout-content-desc_btn">
              {onClick && !disableOnClick && (
                <Button.PrimaryButton
                  title={userMatrix ? 'SAVE' : onClick && goBack ? 'SAVE' : 'ADD'}
                  handleClick={onClick}
                  disabled={isSaving}
                />
              )}
              {canDelete && (
                <span style={{ marginLeft: '5px' }}>
                  <Button.DeleteButton title="DELETE" handleClick={onDelete} disabled={isSaving} />
                </span>
              )}
              {canDisable && isDetails == 'customer-details' ? (
                <>
                  {status == true ? (
                    <span style={{ marginLeft: '5px' }}>
                      <Button.DisableButton
                        title="DISABLE CUSTOMER"
                        //handleClick={handleDisableConfirmation}
                        handleClick={onDisable}
                        disabled={isSaving}
                      />
                    </span>
                  ) : (
                    <span style={{ marginLeft: '5px' }}>
                      <Button.EnableButton
                        title="ENABLE CUSTOMER"
                        handleClick={handleSaveCustomerStatus}
                        disabled={isSaving}
                      />
                    </span>
                  )}
                </>
              ) : null}
              {/* {canDisable && isDetails == 'customer-management' ? (
                <>
                  <Button.PrimaryButton title={'SAVE'} handleClick={onClick} disabled={isSaving} />
                </>
              ) : null} */}
              {canPatchOrder && (
                <span className="cancel">
                  <Button.EnableButton title="COMPLETE ORDER" handleClick={onPatchOrder} disabled={isSaving} />
                </span>
              )}
              {canCancel && (
                <span className="cancel">
                  <Button.WarnButton title="CANCEL ORDER" handleClick={onCancel} disabled={isSaving} />
                </span>
              )}
              {canRefund && (
                <span className="cancel">
                  <Button.WarnButton title="REFUND ORDER" handleClick={onRefund} disabled={isSaving} />
                </span>
              )}
              {canClone && (
                <span style={{ marginLeft: '5px' }}>
                  <Button.PrimaryButton title="CLONE" handleClick={onClone} />
                </span>
              )}
              {goBack && (
                <span style={{ marginLeft: '5px' }}>
                  <Button.SecondaryButton title="BACK" handleClick={goBack} disabled={isSaving} />
                </span>
              )}
            </div>
          ) : null}
        </div>
      </div>
      <div className="with-MarginRight-190px with-padding-20px">{children}</div>
      <Drawer open={showDrawer} onClose={() => setDrawer(false)} className="layout-content_drawer">
        <SideNav {...state} />
      </Drawer>
    </div>
  )
}

export default { Body, LeftPanel, Content }

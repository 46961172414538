import React from 'react'
import CustomSection from '@components/CustomSection/CustomSection'
// import { formatter } from '@utils'
// import moment from 'moment'

export default function ReceiverDetails(props) {
  const { parcelOrderDetail } = props
 
  let receiverColumns = [
    {
        title: 'Account No',
        value: parcelOrderDetail.receiverAccountNumber ? parcelOrderDetail.receiverAccountNumber : "-",
        size: {
          m: 3,
          xs: 12
        }
    },
    {
        title: 'Name',
        value: parcelOrderDetail.receiverName,
        size: {
          m: 3,
          xs: 12
        }
    },
    {
        title: 'Mobile Number',
        value: parcelOrderDetail.receiverMobileNumber,
        size: {
          m: 3,
          xs: 12
        }
    },
    {
        title: 'Primary Email',
        value: parcelOrderDetail.receiverEmail,
        size: {
          m: 3,
          xs: 12
        }
    }
    ]

  return (
    <>
     <CustomSection columns={receiverColumns} />
    </>
  )
}

import React from 'react'
import CustomSection from '@components/CustomSection/CustomSection'
// import { formatter } from '@utils'
// import moment from 'moment'

export default function SenderDetails(props) {
  const { parcelOrder } = props
 
  let senderColumns = [
    {
        title: 'Account No',
        value: parcelOrder.senderAccountNo ? parcelOrder.senderAccountNo : "-",
        size: {
          m: 3,
          xs: 12
        }
    },
    {
        title: 'Name',
        value: parcelOrder.senderName,
        size: {
          m: 3,
          xs: 12
        }
    },
    {
        title: 'Mobile Number',
        value: parcelOrder.senderMobileNumber,
        size: {
          m: 3,
          xs: 12
        }
    },
    {
        title: 'Primary Email',
        value: parcelOrder.senderEmail,
        size: {
          m: 3,
          xs: 12
        }
    }
]

  return (
    <>
     <CustomSection columns={senderColumns} />
    </>
  )
}

import React, { useState, useEffect } from 'react'
import { Layout, SideNav, Panel, CustomTable, Notification, Timestamp } from '@components'
import OrderList from '@components/List/OrderList'
import { API, graphqlOperation } from 'aws-amplify'
import {
  adminGetCustomerDetail,
  searchParcelOrders
} from '@graphql/queries'
// import { updatePointAdjustment, updateCustomerStatus } from '@graphql/mutations'
// import { getCustomer } from '@infrastructure/customQueries'
import { Grid, TextField, Modal, Tooltip } from '@material-ui/core'
import { Visibility } from '@material-ui/icons'
import { formatter } from '@utils'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { validator, Authentication } from '@utils'
import Moment from 'moment'
import uuid from 'uuid'
import _ from 'lodash'
import './CustomerDetails.scss'
// import { getTotalPoint } from '@graphql/queries'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import CustomerDetailData from '../listing/tempCustomerList.json'

export default function CustomerDetails(props) {

  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [popup, setPopup] = useState(false)

  const [data, setData] = useState(null)
  const [totalPoints, setTotalPoints] = useState(0)
  const [total, setTotal] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const maxRowPerPage = 50
  const [customerOrders, setCustomerOrders] = useState([])
  const [nextToken, setNextToken] = useState(null)
  const [isTimeOut, setIsTimeOut] = useState(false)
  const defaultSort = { field: 'orderDate', direction: 'desc' }
  const [sortField, setSortField] = useState(defaultSort)
  
  const [canViewManagement, setCanViewManagement] = useState(false)
  const [canEditCustomer, setCanEditCustomer] = useState(false)

  const handleEnableCustomerStatus = async () => {
    setIsSaving(false)
    // const id = props.match.params.id
    // try {
    //   const res = await API.graphql(
    //     graphqlOperation(updateCustomerStatus, {
    //       customerId: data.accountNo,
    //       operation: 'unblock',
    //       reasonCode: '',
    //       reasonDesc: ''
    //     })
    //   )
    //   console.log(res)
    //   if (res.data.updateCustomerStatus) {
    //     if (res.data.updateCustomerStatus.messageOutput == 'success') {
    //       setIsPoint(false)
    //       setMessage(res.data.updateCustomerStatus.messageOutput)
    //       setModal({ ...modal, show: true, severity: 'success' })
    //       resetEmptyModal()
    //       setTimeout(function () {
    //         window.location.reload()
    //         setIsSaving(false)
    //       }, 2000)
    //       setPopup(false)
    //     } else {
    //       setIsPoint(false)
    //       setMessage(res.data.updateCustomerStatus.messageOutput)
    //       setModal({ ...modal, show: true, severity: 'error' })
    //       resetEmptyModal()
    //       setTimeout(function () {
    //         setIsSaving(false)
    //       }, 2000)
    //       setPopup(false)
    //     }
    //   }
    // } catch (err) {
    //   console.log(err)
    //   setIsSaving(false)
    //   setModal({ ...modal, show: true, severity: 'error' })
    // }
  }


  async function getCustomerDetail(){
    const id = props.match.params.id
    try{
      const res = await API.graphql(graphqlOperation(adminGetCustomerDetail, { customerId: id }))
      if (res && res.data.adminGetCustomerDetail.status){
        let result = res.data.adminGetCustomerDetail
        setData(result.customer)
        setCustomerOrders(result.orders)
      }
    } catch(error){
      console.log(error)
    }
    setIsLoading(false)
  }
  
  async function fetchOrderData(currentNextToken, limit = maxRowPerPage, page = 0, orderBy = null, orderDirection = null) {
    setIsLoading(true)

    try {
      let skipRow = parseInt(page) * parseInt(limit)
      let res = null
      let sort = sortField

      if (orderBy && orderDirection) {
        sort = { field: orderBy, direction: orderDirection }
        setSortField(sort)
      }

      res = await API.graphql(graphqlOperation(searchParcelOrders, { limit: limit, nextToken: skipRow, sort: sort }))

      setTotal(res.data.searchParcelOrders.total)
      if (res.data.searchParcelOrders.total > 0) {
        let currentOrderList = res.data.searchParcelOrders.items
        setCustomerOrders(currentOrderList)
        if (res.data.searchParcelOrders.total > skipRow && res.data.searchParcelOrders.items.length === limit) {
          setNextToken(res.data.searchParcelOrders.items.length)
        } else {
          setNextToken(null)
        }
      } else {
        setCustomerOrders([])
        setNextToken(null)
      }

      setIsTimeOut(false)
      // setIsLoading(false)
    } catch (err) {
      console.log(err)
      if (err.data.searchParcelOrders.items) {
        let skipRow = parseInt(page) * parseInt(limit)
        setTotal(err.data.searchParcelOrders.total)
        let currentOrderList = err.data.searchParcelOrders.items
        setCustomerOrders(currentOrderList)
        if (err.data.searchParcelOrders.total > skipRow && err.data.searchParcelOrders.items.length === limit) {
          setNextToken(err.data.searchParcelOrders.items.length)
        } else {
          setNextToken(null)
        }
        setIsTimeOut(false)
      } else {
        setIsTimeOut(true)
      }
      // setIsLoading(false)
    }
    setCurrentPage(page)
  }


  useEffect(() => {
    // async function user() {
    //   let res = await Authentication.checkUserPermission('customer-management')

    //   if (res == null) {
    //     setCanViewManagement(true)
    //     setCanEditManagement(true)
    //   } else {
    //     if (res[0].canEdit == true) {
    //       setCanEditManagement(true)
    //     }
    //     if (res[0].canView == true) {
    //       setCanViewManagement(true)
    //     }
    //   }
    //   let res2 = await Authentication.checkUserPermission('customer')

    //   if (res2 == null) {
    //     setCanEditCustomer(true)
    //   } else {
    //     if (res2[0].canEdit == true) {
    //       setCanEditCustomer(true)
    //     }
    //   }
    // }
    
    // user()
    getCustomerDetail()
    // setCustomerOrders(CustomerDetailData.customers[0].orders)
    // fetchOrderData()
  }, [])
  
  return (
    <Layout.Body>
      <Layout.LeftPanel>
        <SideNav {...props} />
      </Layout.LeftPanel>
      <Layout.Content
        title="View Customer"
        description=""
        state={props}
        isSaving={isSaving}
        disableOnClick={isDisabled}
        canDisable={canEditCustomer}
        isCustomerDetails={false}
        canViewManagement={canViewManagement}
        // handleSaveCustomerStatus={handleEnableCustomerStatus}
        onDisable={() => setPopup(true)}
        goBack={() => props.history.push('/customer')}
      >
        {!isLoading ? (
         <Grid>
            <Panel elevation={3} title="Customer Details" modified={data.updatedBy} updatedAt={data.updatedAt}>
                <Grid container spacing={1} className="customer-details-container_paper-row">
                  <Grid item xs={4} className="customer-details-container_paper-store">
                    Account No.
                    <div className="title-input">
                      <TextField
                        size="small"
                        value={data.accountNo}
                        disabled
                        variant="outlined"
                        className="title-input-box"
                        inputProps={{
                          style: {
                            height: '6px',
                          },
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={3} className="customer-details-container_paper-store">
                    Phone No.
                    <div className="title-input">
                      <TextField
                        size="small"
                        value={data.mobileNumber}
                        disabled
                        variant="outlined"
                        className="title-input-box"
                        inputProps={{
                          style: {
                            height: '6px',
                          },
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={2} className="customer-details-container_paper-store">
                    Club Code
                    <div className="title-input">
                      <TextField
                        size="small"
                        value={data.custClubCode}
                        disabled
                        variant="outlined"
                        className="title-input-box"
                        inputProps={{
                          style: {
                            height: '6px',
                          },
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={3} className="customer-details-container_paper-store">
                    Personal Income Level
                    <div className="title-input">
                      <TextField
                        size="small"
                        value={data.personalIncomeLevel}
                        disabled
                        variant="outlined"
                        className="title-input-box"
                        inputProps={{
                          style: {
                            height: '6px',
                          },
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid container spacing={1} style={{ margin: '0px 1px' }}>
                     <Grid item xs={4} className="customer-details-container_paper-store">
                      First Name
                      <div className="title-input">
                        <TextField
                          size="small"
                          value={data.firstName}
                          disabled
                          variant="outlined"
                          className="title-input-box"
                          inputProps={{
                            style: {
                              height: '6px',
                            },
                          }}
                        />
                      </div>
                    </Grid>
                     <Grid item xs={4} className="customer-details-container_paper-store">
                      Last Name
                      <div className="title-input">
                        <TextField
                          size="small"
                          value={data.lastName}
                          disabled
                          variant="outlined"
                          className="title-input-box"
                          inputProps={{
                            style: {
                              height: '6px',
                            },
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={2} className="customer-details-container_paper-store">
                      Date of Birth
                      <div className="title-input">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            format="dd/MM/yyyy"
                            disabled
                            // error={emptyField.title}
                            // helperText={emptyField.title ? 'Title field is required' : null}
                            value={data.dateOfBirth === '' ? null : data.dateOfBirth}
                            //minDate={Moment()}
                            // onChange={onBirthdayDateChange}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </Grid>
                    <Grid item xs={2} className="customer-details-container_paper-store">
                      Nationality
                      <div className="title-input">
                        <TextField
                          size="small"
                          value={data.nationality}
                          disabled
                          variant="outlined"
                          className="title-input-box"
                          inputProps={{
                            style: {
                              height: '6px',
                            },
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={3} className="customer-details-container_paper-store">
                      Primary Email
                      <div className="title-input">
                        <TextField
                          size="small"
                          value={data.email}
                          disabled
                          variant="outlined"
                          className="title-input-box"
                          inputProps={{
                            style: {
                              height: '6px',
                            },
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={3} className="customer-details-container_paper-store">
                      Secondary Email
                      <div className="title-input">
                        <TextField
                          size="small"
                          value={data.secondaryEmail}
                          disabled
                          variant="outlined"
                          className="title-input-box"
                          inputProps={{
                            style: {
                              height: '6px',
                            },
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={3} className="customer-details-container_paper-store">
                      Race
                      <div className="title-input">
                        <TextField
                          size="small"
                          value={data.race}
                          disabled
                          variant="outlined"
                          className="title-input-box"
                          inputProps={{
                            style: {
                              height: '6px',
                            },
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={3} className="customer-details-container_paper-store">
                      Employment Status
                      <div className="title-input">
                        <TextField
                          size="small"
                          value={data.employmentStatus}
                          disabled
                          variant="outlined"
                          className="title-input-box"
                          inputProps={{
                            style: {
                              height: '6px',
                            },
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Panel>
              <Panel elevation={3} title="Customer Address" marginTop="10px">
                  <Grid container spacing={1} className="customer-details-container_paper-row">
                   <Grid item xs={4} className="customer-details-container_paper-store">
                    Address
                    <div className="title-input">
                      <TextField
                        size="small"
                        value={data.address}
                        disabled
                        variant="outlined"
                        className="title-input-box"
                        inputProps={{
                          style: {
                            height: '6px',
                          },
                        }}
                      />
                    </div>
                  </Grid>
                   <Grid item xs={4} className="customer-details-container_paper-store">
                    Address 2
                    <div className="title-input">
                      <TextField
                        size="small"
                        value={data.address2}
                        disabled
                        variant="outlined"
                        className="title-input-box"
                        inputProps={{
                          style: {
                            height: '6px',
                          },
                        }}
                      />
                    </div>
                  </Grid>
                   <Grid item xs={4} className="customer-details-container_paper-store">
                    Postal Code
                    <div className="title-input">
                      <TextField
                        size="small"
                        value={data.postal}
                        disabled
                        variant="outlined"
                        className="title-input-box"
                        inputProps={{
                          style: {
                            height: '6px',
                          },
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={3} className="customer-details-container_paper-store">
                    City
                    <div className="title-input">
                      <TextField
                        size="small"
                        value={data.city}
                        disabled
                        variant="outlined"
                        className="title-input-box"
                        inputProps={{
                          style: {
                            height: '6px',
                          },
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={3} className="customer-details-container_paper-store">
                    State
                    <div className="title-input">
                      <TextField
                        size="small"
                        value={data.state}
                        disabled
                        variant="outlined"
                        className="title-input-box"
                        inputProps={{
                          style: {
                            height: '6px',
                          },
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={3} className="customer-details-container_paper-store">
                    Country
                    <div className="title-input">
                      <TextField
                        size="small"
                        value={data.country}
                        disabled
                        variant="outlined"
                        className="title-input-box"
                        inputProps={{
                          style: {
                            height: '6px',
                          },
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Panel>
              <Panel elevation={3} title="Customer Points and Membership" marginTop="10px">
                <Grid container direction="row" className="customer-details-container_paper-row">
                  <Grid item lg={3} className="customer-details-container_paper-store">
                    <div>Current Points</div>
                    <TextField
                      size="small"
                      variant="outlined"
                      className="title-input-box"
                      style={{ width: '90%', marginTop: '10px' }}
                      disabled
                      value={totalPoints}
                      inputProps={{
                        style: {
                          height: '6px',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item lg={3} className="customer-details-container_paper-store">
                    <div>Current Membership Tier</div>
                    <TextField
                      size="small"
                      variant="outlined"
                      className="title-input-box"
                      disabled
                      style={{ width: '90%', marginTop: '10px' }}
                      inputProps={{
                        style: {
                          height: '6px',
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Panel>
              <Panel elevation={3} title="Customer Order" marginTop="10px">
                <div className="landing-container">
                  <OrderList
                    accountNo={data.accountNo}
                    total={total}
                    currentPage={currentPage}
                    maxRowPerPage={maxRowPerPage}
                    order={customerOrders}
                    nextToken={nextToken}
                    fetchOrderData={fetchOrderData}
                    {...props}
                  />
              </div>
              </Panel>
            </Grid>
          ) : null}
      </Layout.Content>
    
    </Layout.Body>
  )
}

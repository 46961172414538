import React, { useState, useEffect } from 'react'
import QRCode from 'qrcode.react'
import { Typography, Button, Tooltip } from '@material-ui/core'
import { FileCopy } from '@material-ui/icons'
export default function QRCodeMFA(props) {
  const { qrMFA, setMFASetup, setShowGuide, secretCode, handleCancel } = props
  const [showCode, setShowCode] = useState(false)
  const [hiddenCode, setHiddenCode] = useState('')
  const [isCopy, setIsCopy] = useState(false)
  useEffect(() => {
    setHiddenCode(secretCode.replaceAll(/./g, '*'))
  }, [secretCode])
  const handleCopy = () => {
    setIsCopy(true)
    navigator.clipboard.writeText(secretCode)
    setTimeout(() => {
      setIsCopy(false)
    }, 3000)
  }
  return (
    <>
      <Typography color="textSecondary" gutterBottom>
        <small>
          Please scan the following QR Code using Google Authenticator app. Click continue once you have scanned <br />
          Click{' '}
          <a href="#" onClick={() => setShowGuide(true)}>
            here
          </a>{' '}
          to learn how to setup MFA
        </small>
      </Typography>
      <div className="login-form-content">
        <QRCode value={qrMFA} />
        <small>
          <br />
          Or
          <br />
          Copy this code in Google Authenticator: {showCode ? secretCode : hiddenCode}
          <a href="#" onClick={() => setShowCode(!showCode)}>
            {showCode ? ' Hide' : ' Show'}
          </a>
          <br />
          {showCode ? (
            <Tooltip title="Copied" open={isCopy} arrow placement="right">
              <Button variant="outlined" onClick={handleCopy}>
                Copy Code
                <FileCopy style={{ fontSize: '16px' }} />
              </Button>
            </Tooltip>
          ) : null}
        </small>
        <br />
        Note: After you setup the MFA, you can no longer see the page. Please save the code for backup purpose.
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }} className="login_button_container">
        <Button variant="contained" className="login_button" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" className="login_button" onClick={setMFASetup}>
          Continue
        </Button>
      </div>
    </>
  )
}

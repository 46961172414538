import { Divider, Box } from '@material-ui/core'
import { NavigateNext } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
// import { Menu, getMenuList } from '@constant/menu'
// import { getMenuList } from '@graphql/queries'
// import { invalidateRefreshToken } from '@graphql/mutations'
import cx from 'classnames'
import './SideNav.scss'
import { API, Auth, graphqlOperation } from 'aws-amplify'
import fmIcon from './fm-icon.svg'
import {
  StoreOutlined,
  Notifications,
  Redeem,
  Image,
  FastfoodOutlined,
  CategoryOutlined,
  ListAlt,
  PeopleAlt,
  LocalOfferOutlined,
  TrendingUp,
  ConfirmationNumberOutlined,
  AssignmentOutlined,
  GetApp,
  Feedback,
  MenuBook,
  MenuOpen,
  Payment,
  Settings,
  VpnKey,
  ContactPhone,
  Motorcycle,
  RotateLeft,
  Event
} from '@material-ui/icons'
import SvgIcon from '@material-ui/core/SvgIcon'
import ListIcon from '@assets/svgs/list.svg'
import { ReactComponent as PartnershipHandshake } from '@assets/svgs/partnership-handshake.svg'
import ScissorsIcon from '@assets/svgs/scissorsIcon.js'
import { ReactComponent as Database } from '@assets/svgs/database.svg'

export default function SideNav(props) {
  const { match, history } = props
  const tempMenuList = [
    {
      icon: <ListAlt />,
      name: 'Parcels',
      path: '/parcel-order'
    },
    {
      icon: <ListAlt />,
      name: 'Order Summary',
      path: '/order'
    },
    {
      icon: <Payment />,
      name: 'Refund',
      path: '/refund'
    },
    {
      icon: <AssignmentOutlined />,
      name: 'Flyer Bag History',
      path: '/flyer-bag-history'
    },
    {
      icon: <PeopleAlt />,
      name: 'Customer',
      path: '/customer'
    }

  ]
  const [menuList, setMenuList] = useState(tempMenuList)
  const IconMapping = [
    {
      icon: <StoreOutlined />,
      module: 'Store'
    },
    {
      icon: <Motorcycle />,
      module: 'Store Delivery Partner'
    },
    {
      icon: <FastfoodOutlined />,
      module: 'Product'
    },
    {
      icon: <MenuBook />,
      module: 'Landing Menu'
    },
    {
      icon: <MenuOpen />,
      module: 'Landing Page Product'
    },
    {
      icon: <CategoryOutlined />,
      module: 'Departments'
    },
    {
      icon: <LocalOfferOutlined />,
      module: 'Product Taggings'
    },
    {
      icon: <Redeem />,
      module: 'Benefit Trigger'
    },
    {
      icon: <Redeem />,
      module: 'Voucher'
    },
    {
      icon: <TrendingUp />,
      module: 'Trending'
    },
    {
      icon: <SvgIcon component={PartnershipHandshake} />,
      module: 'Promotion Partner'
    },
    {
      icon: (
        <SvgIcon>
          <ScissorsIcon />
        </SvgIcon>
      ),
      module: 'Promo Code'
    },
    {
      icon: <Image />,
      module: 'Homepage Banner'
    },
    {
      icon: <Event />,
      module: 'Validation Period'
    },
    // {
    //   icon: <SvgIcon component={Database} />,
    //   module: 'Membership Point'
    // },
    // {
    //   icon: <Event />,
    //   module: 'Stamping'
    // },
    {
      icon: <CategoryOutlined />,
      module: 'Homepage Landing'
    },
    {
      icon: <ConfirmationNumberOutlined />,
      module: 'App Popup'
    },
    {
      icon: <Image />,
      module: 'Banner'
    },
    {
      icon: <FastfoodOutlined />,
      module: 'Item Exclusion'
    },
    {
      icon: <Notifications />,
      module: 'Notification'
    },
    {
      icon: <Notifications />,
      module: 'Event Notification'
    },
    {
      icon: <Notifications />,
      module: 'Announcement'
    },
    {
      icon: <Notifications />,
      module: 'News'
    },
    {
      icon: <PeopleAlt />,
      module: 'Customer'
    },
    {
      icon: <PeopleAlt />,
      module: 'Customer Tag'
    },
    {
      icon: <StoreOutlined />,
      module: 'Store Group'
    },
    {
      icon: <ListAlt />,
      module: 'Order'
    },
    {
      icon: <ListAlt />,
      module: 'Voucher Order'
    },
    // {
    //   icon: <GetApp />,
    //   module: 'Import',
    //   path: '/import'
    // },
    {
      icon: <Payment />,
      module: 'Refund'
    },
    {
      icon: <ContactPhone />,
      module: 'CS Refund'
    },
    {
      icon: <Feedback />,
      module: 'Feedback'
    },
    {
      icon: <ListAlt />,
      module: 'Voucher History'
    },
    {
      icon: <ListAlt />,
      module: 'Point History'
    },
    {
      icon: <ListAlt />,
      module: 'E-Commerce Order'
    },
    {
      icon: <ListAlt />,
      module: 'In Store Order'
    },
    {
      icon: <ListAlt />,
      module: 'In Store Transaction'
    },
    // {
    //   icon: <Settings />,
    //   module: 'KDS Config',
    //   path: '/kds-config'
    // },
    // {
    //   icon: <VpnKey />,
    //   module: 'User Matrix',
    //   path: '/user-matrix'
    // },
    {
      icon: <AssignmentOutlined />,
      module: 'Report'
    },
    // {
    //   icon: <Feedback />,
    //   module: 'Post Transaction Survey'
    // },
    {
      icon: <ListAlt />,
      module: 'Upload Job Status'
    },
    {
      icon: <RotateLeft />,
      module: 'User Account Management'
    }
  ]
  useEffect(() => {
    async function retrieveMenuList() {
      try {
        if (!menuList.length) {
          let cacheMenuList = localStorage.getItem('menuList')
          let res = JSON.parse(cacheMenuList)
          let sideMenuList = res.map((menu) => {
            let icon = IconMapping.find((icon) => icon.module === menu.name)
            if (!icon) console.log(menu)
            menu = { ...menu, icon: icon.icon }
            return menu
          })
          setMenuList(sideMenuList)
        }
      } catch (e) {
        console.log(e)
        if (e.errors && e.errors[0].errorType == 'Unauthorized') {
          window.location = '/redirecting'
        }
      }
    }
    // retrieveMenuList()
  }, [])
  return (
    <Box className="navigationmenu">
      <img src={fmIcon} alt="fm-logo" />
      <Divider variant="middle" />
      <Box marginTop={1.25}>
        {menuList.map((item, idx) => {
          const { icon, name, path } = item
          return (
            <Box key={idx}>
              {/* {name == 'Product' || name == 'App Popup' || name == 'Customer' || name == 'Report' ? (
                <Divider variant="middle" />
              ) : null} */}
              <Box
                key={idx}
                onClick={() => history.push(path)}
                className={cx('navigationmenu__item', {
                  'navigationmenu__item--selected': '/' + match.path.split('/')[1] == path
                })}>
                {icon}
                <span>{name}</span>
                {item.submenu ? <NavigateNext className="navigationmenu__item--next" /> : null}
              </Box>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

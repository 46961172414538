import React from 'react'
import CustomSection from '@components/CustomSection/CustomSection'
import { formatter } from '@utils'
import moment from 'moment'

export default function OrderDetails(props) {
  const { parcelOrderDetail, parcelOrder } = props
 
  let orderDetailsColumn = [
    {
        title: 'Order Date Time',
        value: moment(parcelOrderDetail.orderDate).format('DD/MM/YYYY HH:mm:ss'),
        size: {
            m: 3,
            xs: 12
        }
    },
    {
        title: 'Order Number',
        value: parcelOrderDetail.orderNumber,
        size: {
            m: 3,
            xs: 12
        }
    },
    {
        title: 'Order Txn ID',
        value: parcelOrder.orderTxnId,
        size: {
            m: 2,
            xs: 12
        }
    },
    {
        title: 'Tracking Number',
        value: parcelOrderDetail.trackerNumber,
        size: {
            m: 3,
            xs: 12
        }
    },
    {
        title: 'Order Type',
        value: parcelOrder.parcelType,
        size: {
            m: 2,
            xs: 12
        }
    },
    {
        title: 'Status',
        value: parcelOrderDetail.status ? parcelOrderDetail.status : 'Pending Drop-Off',
        size: {
            m: 3,
            xs: 12
        }
    },
    {
        title: 'Promo Code',
        value: parcelOrder.promoCode ? parcelOrder.promoCode : "-",
        size: {
            m: 2,
            xs: 12
        }
    },
    {
        title: 'Total',
        value: formatter.toPrice(parcelOrder.grandTotal),
        size: {
            m: 2,
            xs: 12
        }
    },
    {
        title: 'Payment Method',
        value: parcelOrderDetail.paymentMethod,
        size: {
            m: 2,
            xs: 12
        }
    },
    {
        title: 'Payment Card/e-Wallet Type',
        value: parcelOrderDetail.paymentType ? parcelOrderDetail.paymentType : "-",
        size: {
            m: 2,
            xs: 12
        }
    },
    ]

    let dropOffColumns = [
        {
            title: 'Drop-off Date Time',
            value: !parcelOrderDetail.dropOffDateTime || parcelOrderDetail.dropOffDateTime === '' ? '-' : moment(parcelOrderDetail.dropOffDateTime).format('DD/MM/YYYY HH:mm:ss'),
            size: {
                m: 2,
                xs: 12
            }
        },
        {
            title: 'Drop-off Store Code',
            value: parcelOrderDetail.dropOffStoreCode,
            size: {
                m: 2,
                xs: 12
            }
        },
        {
            title: 'Drop-off Store Name',
            value: parcelOrderDetail.dropOffStoreName,
            size: {
                m: 4,
                xs: 12
            }
        },
    ]

    let collectionColumns = [
        {
            title: 'Collection Date Time',
            value: !parcelOrderDetail.collectionDateTime || parcelOrderDetail.collectionDateTime === '' ? '-' : moment(parcelOrderDetail.collectionDateTime).format('DD/MM/YYYY HH:mm:ss'),
            size: {
                m: 2,
                xs: 12
            }
        },
        {
            title: 'Collection Store Code',
            value: parcelOrderDetail.collectionStoreCode,
            size: {
                m: 2,
                xs: 12
            }
        },
        {
            title: 'Collection Store Name',
            value: parcelOrderDetail.collectionStoreName,
            size: {
                m: 4,
                xs: 12
            }
        },
    ]

  return (
    <>
     <CustomSection columns={orderDetailsColumn} />
     <CustomSection columns={dropOffColumns} />
     <CustomSection columns={collectionColumns} />
    </>
  )
}

import React, { useState, useEffect } from 'react'
import Amplify, { API, graphqlOperation, Auth } from 'aws-amplify'
import { Card, CardContent, InputBase, Button, Paper } from '@material-ui/core'
import { Email, Lock, LockOpen } from '@material-ui/icons'
import { validator } from '@utils'
import { ClearCache } from '@utils/cache'
import './LoginPage.scss'

export default function Login(props) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [loginValidation, setLoginValidation] = useState(false)
  const [message, setMessage] = useState('')
  const [errors, setErrors] = useState({ cognito: null, blankfield: false })

  useEffect(() => {
    const additionalConfig = {
      aws_appsync_authenticationType: 'API_KEY'
    }
    Amplify.configure({ ...additionalConfig })
    async function fetchUser() {
      Auth.currentAuthenticatedUser({
        bypassCache: false
      })
        .then((user) => setEmail(user.attributes.email))
        .catch((err) => console.log(err))
    }
    fetchUser()
  }, [])

  function handleEmailChange(event) {
    setEmail(event.target.value)
  }

  function handlePasswordChange(event) {
    setPassword(event.target.value)
  }

  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleLogin()
    }
  }
  async function handleSignOut() {
    await Auth.signOut({ global: true })
    ClearCache()
    window.location = '/login'
  }
  async function handleLogin() {
    setLoginValidation(false)
    setMessage(false)
    try {
      if (
        password.replace(/\s/g, '').length > 7 &&
        confirmPassword.replace(/\s/g, '').length > 7 &&
        newPassword.replace(/\s/g, '').length > 7
      ) {
        if (confirmPassword == newPassword) {
          let params = {
            username: email.toLowerCase(),
            password: newPassword
          }
          Auth.currentAuthenticatedUser()
            .then((user) => {
              return Auth.changePassword(user, password, newPassword)
            })
            .then((data) => {
              console.log(data)
              setLoginValidation(true)
              setMessage('Success! Change password successful.')
              setErrors({ ...errors, blankfield: true })
              setTimeout(() => {
                handleSignOut()
              }, 1500)
            })
            .catch((error) => {
              console.log(error)
              setLoginValidation(true)
              if (error.message) {
                setMessage(error.message)
              } else {
                setMessage(error.errors[0].errorType)
              }

              let err = null
              !error.message ? (err = { message: error }) : (err = error)
              setErrors({ ...errors, cognito: err })
            })
        } else {
          setLoginValidation(true)
          setMessage('Passwords do not match.')
        }
      } else {
        setLoginValidation(true)
        setMessage('Please confirm all password have at least 8 characters.')
      }
    } catch (error) {
      console.log(error)
      setLoginValidation(true)
      if (error.message) {
        setMessage(error.message)
      } else {
        setMessage(error.errors[0].errorType)
      }

      let err = null
      !error.message ? (err = { message: error }) : (err = error)
      setErrors({ ...errors, cognito: err })
    }
  }

  return (
    <React.Fragment>
      <div className="login_container">
        <div className="login_title_container">
          <div className="login_title">
            <h1 className="login_text">Change Password</h1>
          </div>
        </div>
        <div className="login_container login_form">
          <Card className="login_form_card">
            <CardContent>
              <div className="change-form-content">
                {loginValidation === true ? (
                  <div className="login-alert-body">
                    <div className={errors.blankfield == true ? 'login-success-box' : 'login-alert-box'}>{message}</div>
                  </div>
                ) : (
                  ''
                )}
                <Paper component="form" className="login_paper">
                  <Email />
                  <InputBase
                    className="login_input"
                    placeholder={'Email'}
                    value={email}
                    disabled={true}
                    onChange={handleEmailChange}
                    autoComplete="one-time-code"
                  />
                </Paper>
                <Paper component="form" className="login_paper">
                  <LockOpen />
                  <InputBase
                    className={password ? 'login_input_password' : 'login_input'}
                    placeholder="Current Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyPress={handleKeyPress}
                    type="text"
                    autoComplete="new-password"
                  />
                </Paper>
                <Paper component="form" className="login_paper">
                  <Lock />
                  <InputBase
                    className={newPassword ? 'login_input_password' : 'login_input'}
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    onKeyPress={handleKeyPress}
                    type="text"
                    autoComplete="new-password"
                  />
                </Paper>
                <Paper component="form" className="login_paper">
                  <Lock />
                  <InputBase
                    className={confirmPassword ? 'login_input_password' : 'login_input'}
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    onKeyPress={handleKeyPress}
                    type="text"
                    autoComplete="new-password"
                  />
                </Paper>
              </div>
              <div className="login_button_container">
                <Button
                  style={{ marginRight: '5px', color: 'grey' }}
                  onClick={() => {
                    props.history.push('/redirecting')
                  }}>
                  Cancel
                </Button>
                <Button variant="contained" className="login_button" onClick={handleLogin}>
                  Confirm
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

import { Auth, API, graphqlOperation } from 'aws-amplify'
// import { listUserMatrixs } from '../graphql/queries'
import { Cache, ClearCache } from '@utils/cache'

const Authentication = {
  isAuthenticated: false,
  async authenticate(email, password) {
    try {
      await Auth.signIn(email, password)
      this.isAuthenticated = true
    } catch (err) {
      console.log(err)
    }
  },
  async checkUserSession() {
    try {
      await Auth.currentSession()
      this.isAuthenticated = true
    } catch (err) {
      console.log(err)
      this.isAuthenticated = false
    }
  },
  async getUserGroup(){
    let userGroup = ''

    // let response = await Auth.currentAuthenticatedUser()
    let cache = Cache.getItem('currentAuthenticatedUser')
    let response
    if (!cache) {
      response = await Auth.currentAuthenticatedUser()
      Cache.setItem('currentAuthenticatedUser', response)
    } else {
      response = cache
    }

    if ('cognito:groups' in response.signInUserSession.accessToken.payload) {
      userGroup = response.signInUserSession.accessToken.payload['cognito:groups']
    }

    return userGroup

  },
  async checkUserPermission(module) {
    let userGroup = ''

    // let response = await Auth.currentAuthenticatedUser()
    let cache = Cache.getItem('currentAuthenticatedUser')
    let response
    if (!cache) {
      response = await Auth.currentAuthenticatedUser()
      Cache.setItem('currentAuthenticatedUser', response)
    } else {
      response = cache
    }

    if ('cognito:groups' in response.signInUserSession.accessToken.payload) {
      userGroup = response.signInUserSession.accessToken.payload['cognito:groups']
    }

    // if (userGroup.includes('Admin')) {
    //   return null
    // } else {
    //   let res = ''
    //   let actionList = []
    //   let permissionCache
    //   for (let i = 0; i < userGroup.length; i++) {
    //     if (!module) {
    //       permissionCache = Cache.getItem('userGroupModules')
    //       if (!permissionCache) {
    //         res = await API.graphql(
    //           graphqlOperation(listUserMatrixs, {
    //             userGroup: userGroup[i]
    //           })
    //         )
    //         Cache.setItem('userGroupModules', res)
    //       } else {
    //         res = permissionCache
    //       }

    //       for (let i = 0; i < res.data.listUserMatrixs.items.length; i++) {
    //         actionList.push(res.data.listUserMatrixs.items[i])
    //       }
    //     } else {
    //       permissionCache = Cache.getItem(`userModule_${module}`)
    //       if (!permissionCache) {
    //         res = await API.graphql(
    //           graphqlOperation(listUserMatrixs, {
    //             userGroup: userGroup[i],
    //             module: module
    //           })
    //         )
    //         Cache.setItem(`userModule_${module}`, res)
    //       } else {
    //         res = permissionCache
    //       }

    //       for (let i = 0; i < res.data.listUserMatrixs.items.length; i++) {
    //         for (let i = 0; i < actionList.length; i++) {
    //           if (actionList[i].module == res.data.listUserMatrixs.items[i].module) {
    //             if (actionList[i].canAdd == true) {
    //               res.data.listUserMatrixs.items[i].canAdd = true
    //             }
    //             if (actionList[i].canDelete == true) {
    //               res.data.listUserMatrixs.items[i].canDelete = true
    //             }
    //             if (actionList[i].canEdit == true) {
    //               res.data.listUserMatrixs.items[i].canEdit = true
    //             }
    //             if (actionList[i].canList == true) {
    //               res.data.listUserMatrixs.items[i].canList = true
    //             }
    //             if (actionList[i].canView == true) {
    //               res.data.listUserMatrixs.items[i].canView = true
    //             }
    //             actionList = actionList.filter((p) => p.module != actionList[i].module)
    //           }
    //         }
    //         actionList.push(res.data.listUserMatrixs.items[i])
    //       }
    //     }
    //   }
    //   return actionList
    // }
  },
  signOut() {
    this.authenticate = false
    try {
      Auth.signOut()
      ClearCache()
      this.isAuthenticated = false
    } catch (err) {
      console.log(err)
    }
  },
  getAuth() {
    return this.isAuthenticated
  },

  // async getUserGroupList() {
  //   const userGroupList = []
  //   let res = await API.graphql(graphqlOperation(listUserMatrixs, {}))
  //   if (res && res.data.listUserMatrixs.items) {
  //     let items = res.data.listUserMatrixs.items
  //     userGroupList.push('Admin')
  //     for (let i = 0; i < items.length; i++) {
  //       let userGroup = items[i].userGroup

  //       if (userGroup != 'ITSupport') {
  //         userGroup = userGroup.split(/(?=[A-Z])/).join(' ')
  //       } else {
  //         userGroup = 'IT Support'
  //       }
  //       userGroupList.push(userGroup)
  //     }
  //   }
  //   return userGroupList
  // }
}
export default Authentication
